iços<template>
  <div class="modal-w position-fixed start-0 top-0 d-grid" v-if="guarantee.show">
    <div class="modal-p position-relative p-4 pt-5" v-if="guarantee.show">
      <div @click="guarantee.show = false" role="button" class="btn-close"></div>

      <form>
        <row-component>
          <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
            <label>Produto:</label>
            <input-component v-model="guarantee.formData.product" type-input="text"/>
          </row-col-component>

          <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
            <label>Serviço:</label>
            <input-component v-model="guarantee.formData.service" type-input="text"/>
          </row-col-component>

          <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
            <label>Data de início:</label>
            <input-component v-model="guarantee.formData.date_start" v-mask="'##/##/####'"
                             type-input="text"/>
          </row-col-component>
        </row-component>

        <row-component>
          <row-col-component
              class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <button-submit v-if="!formData.guarantees" class="w-100" @submit="submitGuarantee"
                           :items="buttonGuaranteeOne"/>
            <button-submit v-else class="w-100" @submit="updateGuarantee" :items="buttonSubmit"/>
          </row-col-component>
        </row-component>
      </form>
    </div>
  </div>

  <div class="modal-search-budget-template" v-if="modalBudgetTemplate">
    <div class="btn-close" @click="modalBudgetTemplate = !modalBudgetTemplate"></div>
    <row-component>
      <row-col-component
          :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
        <label>Modelo de Orçamento:</label>
        <Multiselect v-model="budget_template_id" :options="selectBudgetTemplateList" :searchable="true"
                     @select="loadBudgetTemplateGetMultiSelect(budget_template_id)"
                     :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
      </row-col-component>
    </row-component>

    <button-submit @click="modalSearchBudgetTemplate" :items="buttonConfirm"/>
  </div>

  <div class="modal-search-phone" v-if="modalPhone">
    <div class="btn-close" @click="modalPhone = !modalPhone"></div>
    <row-component>
      <row-col-component
          :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
        <label>Telefone:</label>
        <Multiselect v-model="formData.phone" :options="selectClientPhoneList" :searchable="true"
                     :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
      </row-col-component>
    </row-component>

    <button-submit @click="modalSearchClientPhone" :items="buttonConfirm"/>
  </div>

  <div class="send-sector" v-if="sector_modal">
    <div role="button" class="btn-close" @click="sectorModal"></div>

    <div class="mt-3">
      <row-component>
        <template v-for="(data, index) in sectors" :key="index">
          <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                             v-if="parseInt(formData.status) !== data.id">
            <div class="d-flex align-items-center bg-body-secondary p-3 position-relative">
              <div class="ms-3 w-75">
                <p class="mb-1 text-truncate">{{ data.name }}</p>
              </div>
              <div class="position-absolute end-0 me-1 me-lg-3">
                <button-submit @click="submitStatus(data.id)" :items="buttonActions[0]"/>
              </div>
            </div>
          </row-col-component>
        </template>
      </row-component>
    </div>
  </div>

  <painel-component :itens="itensOne">
    <error-component :errors="errors" v-if="showError"/>

    <div class="tabs effect-3" v-if="showView">
      <input checked type="radio" id="tab-1" name="tab-effect-3">
      <span>
                <i class="ti ti-info-square-rounded"></i>
                <p>Informações</p>
            </span>

      <input @click="eventClick" type="radio" id="tab-2" name="tab-effect-3">
      <span>
                <i class="ti ti-truck-delivery"></i>
                <p>Entrega</p>
            </span>

      <input type="radio" id="tab-3" name="tab-effect-3">
      <span>
                <i class="ti ti ti-report-money"></i>
                <p>Garantia</p>
            </span>

      <input type="radio" id="tab-4" name="tab-effect-3">
      <span>
                <i class="ti ti ti-brand-line"></i>
                <p>Observações</p>
            </span>

      <input type="radio" id="tab-5" name="tab-effect-3">
      <span>
                <i class="ti ti-clock-hour-9"></i>
                <p>Histórico de interações</p>
            </span>

      <div class="line ease"></div>
      <div class="tab-content">
        <section id="tab-item-1">
          <div class="clearfix mb-4">
                        <span class="badge text-bg-primary me-2" style="background-color: #36c1bd !important;">
                            Status: {{ statusHistoric(formData.status) }}
                        </span>

            <!--<div class="btn-group" role="group">
                <button-submit @click="submitAction(1)" tooltip="Enviar para cliente"
                               :items="buttonActions.one"/>

                <button-submit @click="submitAction(2)" tooltip="Aprovar" :items="buttonActions.two"
                               v-if="formData.status !== '3' || formData.status !== '4' && buttonCheck.approved"/>

                <button-submit @click="submitAction(3)" tooltip="Cancelar" :items="buttonActions.three"
                               v-if="formData.status === '1' && buttonCheck.cancel"/>

                <button-submit @click="submitAction(4)" tooltip="Finalizar" :items="buttonActions.four"
                               v-if="formData.status < '6' && buttonCheck.end"/>
            </div>-->


            <div class="btn-group mt-4 mt-lg-0 float-none float-lg-end" role="group">

              <button-submit v-if="parseInt(formData.status)  !== 5 && parseInt(formData.status)  !== 7"
                             @click="sectorModal" :items="buttonActions[1]" tooltip="Atualizar Status"/>

              <button-submit tooltip="Buscar modelo de orçamento" @click="modalSearchBudgetTemplate"
                             class="fs-5" :items="buttonSearch"/>

              <button-submit tooltip="Visualizar PDF" @click="printPdf" class="fs-5"
                             :items="buttonPrint"/>

              <button-submit @click="cloneFunction" class="fs-5"
                   :items="{icon: 'ti ti-copy'}" tooltip="Clonar orçamento"/>

              <button-route v-if="buttonRouteAdd" tooltip="Adicionar Novo" class="fs-5"
                            :items="buttonAddRoute"/>
            </div>
          </div>

          <form>
            <row-component>
              <row-col-component
                  :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                <label>URL:</label>
                <div class="input-group" @click="copyUrl(formData.link_budget)">
                  <input v-model="formData.link_budget" @focus="$event.target.select()" ref="select"
                         readonly
                         type="text" class="form-control">
                  <span @click="copyUrl(formData.link_budget)" class="buttonSubmit input-group-text">
                                        <i :class="[copy ? 'ti ti-clipboard-copy' : 'ti ti-clipboard-check']"></i>
                                    </span>
                </div>
              </row-col-component>

              <row-col-component
                  :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                <label>Status:</label>
                <input-component disabled :type-input="'text'" v-model="status"/>
              </row-col-component>
            </row-component>

            <row-component>
              <row-col-component
                  :class-row-col="'col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12'"
                  :class="{'formError' : errors.name}">

                <label>Nome orçamento: </label>
                <Multiselect v-model="formData.name_budget_id" :options="selectNameBudgetList" :searchable="true"
                             :noOptionsText="noOptionsText" :noResultsText="noResultsText" v-if="formData.name_budget_id"/>
                <input-component :type-input="'text'" v-model="formData.name" v-else/>

              </row-col-component>

              <row-col-component
                  :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mt-md-4'"
                  :class="{'formError' : errors.show_value}">
                <label>Exibir Valores?</label>

                <input checked v-model="formData.show_value" type="radio" name="docs" id="value-no"
                       value="1">
                <label for="value-no">Não</label>

                <input v-model="formData.show_value" type="radio" name="docs" id="value-yes" value="2">
                <label for="value-yes">Sim</label>
              </row-col-component>
            </row-component>

            <row-component>
              <row-col-component
                  :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                  :class="{'formError' : errors.client_name}">
                <label>Cliente:</label>
                <input-component disabled :type-input="'text'" v-model="formData.client_name"/>
              </row-col-component>

              <row-col-component
                  :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                  :class="{'formError' : errors.valid}">
                <label>Dias Válido:</label>
                <input-component min="15" :type-input="'number'" v-model="formData.valid"/>
              </row-col-component>

              <row-col-component
                  :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                  :class="{'formError' : errors.phone}">
                <label>Telefone:</label>
                <div class="input-group">
                  <input v-mask="['(##) ####-####', '(##) #####-####']" v-model="formData.phone"
                         type="text"
                         class="form-control">
                  <button :disabled="formData.client_id === '' || formData.client_name === ''"
                          type="button"
                          :tooltip="formData.client_id === '' ? 'Selecione um cliente cadastrado' : '' || formData.client_name === '' ? 'Selecione um cliente cadastrado' : ''"
                          @click="modalSearchClientPhone" class="buttonSubmit input-group-text">
                    <i class="ti ti-search"></i>
                  </button>
                </div>
              </row-col-component>
            </row-component>

            <div class="clearfix">
              <button-submit @click="addProduct" class="fs-5 float-end" :items="buttonAdd"/>
            </div>
            
            <div class="card p-3 bg-light my-3" v-for="(data, index) in productAdd" :key="index">
                <row-component>
                  <row-col-component
                      :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                    <label>Marca:</label>
                    <Multiselect v-model="data.brand_id" :options="selectBrandList" :searchable="true"
                                @select="loadCategoryBrandList(data.brand_id, index)"
                                :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                  </row-col-component>

                  <row-col-component
                      :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                    <label>Categoria:</label>
                    <input-component v-if="data.brand_id === '' || data.brand_id === null" disabled
                                    :type-input="'text'"/>
                    <Multiselect v-else v-model="data.categorie_id" :options="selectCategoryBrandList"
                                :searchable="true" @clear="clearBrand(index)"
                                @select="loadProductBrandMultiSelect(data.brand_id, data.categorie_id, index)"
                                :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                  </row-col-component>

                  <row-col-component
                      :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                    <label>Produto:</label>
                    <input-component v-if="data.categorie_id === '' || data.categorie_id === null" disabled
                                    :type-input="'text'"/>
                    <Multiselect v-else label="name" v-model="data.product_id" :options="selectProductBrandList"
                                @select="loadProductPriceMultiSelect(data.product_id, index)"
                                @clear="clearProduct(index)"
                                :searchable="true"
                                :noOptionsText="'Selecione uma categoria'" :noResultsText="noResultsText"/>
                  </row-col-component>

                  <row-col-component
                      :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                    <label>Quantidade:</label>
                    <input-component @keyup="calcProduct(index)" min="1" :type-input="'number'"
                                    v-model="data.amount"/>
                  </row-col-component>

                  <row-col-component
                      :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                    <label>Preço UN:</label>
                    <input-component v-money="money" @keyup="calcProduct(index)" :type-input="'text'"
                                    v-model="data.unit_price"/>
                  </row-col-component>

                  <row-col-component
                      :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                    <label>Preço Total:</label>
                    <input-component disabled :type-input="'text'" v-model="data.total_price"/>
                  </row-col-component>

                  <div class="clearfix" v-if="formData.products.length >= 2">
                    <button-delete @click="productRemove(index)" class="fs-5 float-end"/>
                  </div>
                </row-component>
              </div>

            <div class="clearfix">
              <button-submit @click="addService" class="fs-5 float-end" :items="buttonAdd"/>
            </div>

            <div class="card p-3 bg-light my-3" v-for="(data, index) in serviceAdd" :key="index">
              <row-component>
                <row-col-component
                    :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                  <label>Serviço:</label>
                  <Multiselect v-model="data.service_id" :options="selectServiceList"
                              @select="loadServicePriceMultiSelect(data.service_id, index)"
                              @clear="clearService(index)"
                              :searchable="true"
                              :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component
                    :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                  <label>Quantidade:</label>
                  <input-component @keyup="calcService(index)" min="1" :type-input="'number'"
                                  v-model="data.amount"/>
                </row-col-component>

                <row-col-component
                    :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                  <label>Preço UN:</label>
                  <input-component v-money="money" @keyup="calcService(index)" :type-input="'text'"
                                  v-model="data.unit_price"/>
                </row-col-component>

                <row-col-component
                    :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                  <label>Preço Total:</label>
                  <input-component disabled :type-input="'text'" v-model="data.total_price"/>
                </row-col-component>
              </row-component>

              <div class="clearfix" v-if="formData.services.length >= 2">
                <button-delete @click="serviceRemove(index)" class="fs-5 float-end"/>
              </div>
            </div>

            <div style="background: #c9c9c9" class="p-2 my-3 text-end fw-bold">
              Total: R$ {{ this.totalResult }}
            </div>

            <div class="clearfix mt-3">
              <button-submit @click="addPaymentForms" class="fs-5 float-end" :items="buttonAdd"/>
            </div>

            <div class="card p-3 bg-light my-3" v-for="(data, index) in paymentFormsAdd" :key="index">
              <row-component>
                <row-col-component
                    class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                  <label>Forma de pagamento:</label>
                  <Multiselect :searchable="true" :noOptionsText="noOptionsText"
                               v-model="data.form_of_payment"
                               @select="calcDiscount(data.form_of_payment, index)"
                               :options="selectBudgetPaymentList.paymentForms"
                               :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component
                    class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                  <label>Quantidade:</label>
                  <Multiselect :searchable="true" :noOptionsText="noOptionsText"
                               v-model="data.portion"
                               @select="calcDiscount(data.form_of_payment, index)"
                               :disabled="data.form_of_payment<5"
                               :options="selectBudgetPaymentList.portion"
                               :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component
                    class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                  <label>Total:</label>
                  <input-component disabled :type-input="'text'" v-model="data.price"
                                   v-money="money"/>
                </row-col-component>

                <div class="clearfix" v-if="formData.payment_methods.length >= 2">
                  <button-delete @click="paymentFormsRemove(index)" class="fs-5 float-end"/>
                </div>
              </row-component>
            </div>
          </form>

          <row-component class="mt-5">
            <row-col-component
                :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
              <button-submit :disaabled="sendForm" @submit="submit" :items="buttonSubmit"
                             style="width: 100%;"/>
            </row-col-component>
            <row-col-component
                :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
              <button-route :items="buttonRoute" style="width: 100%;"/>
            </row-col-component>
          </row-component>
        </section>

        <div class="special" id="tab-item-2">
          <div class="clearfix mb-3">
            <button-submit v-if="!formData.budget_delivery" class="float-end" @click="routeAddGuarantees"
                           :items="buttonGuarantee"/>
            <button-submit v-else class="float-end" @click="routeAddGuaranteesEdit" :items="buttonGuaranteeE"/>
            <button-submit class="float-end me-2" v-if="formData.budget_delivery" @click="printDelivery"
                           :items="itensTwo"/>
          </div>

          <div class="alert alert-secondary alert-dismissible fade show"
               v-if="formData.events.length === 0">
            Não há nenhuma entrega cadastrada
          </div>

          <form v-else>
            <row-component>
              <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <input disabled v-model="delivery.delivery_type" type="radio"
                       name="docs" id="current-address" value="1">
                <label for="current-address">Endereço de Cadastro</label>

                <input disabled v-model="delivery.delivery_type" type="radio"
                       name="docs" id="new-address" value="2">
                <label for="new-address">Endereço Novo</label>
              </row-col-component>
            </row-component>

            <row-component>
              <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                <label>CEP:</label>
                <input-component disabled v-mask="'#####-###'" v-model="delivery.cep"
                                 type-input="text"/>
              </row-col-component>

              <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                <label>Cidade:</label>
                <input-component disabled v-model="delivery.city" type-input="text"/>
              </row-col-component>

              <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                <label>Estado:</label>
                <input-component disabled v-model="delivery.state" type-input="text"/>
              </row-col-component>

              <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                <label>Bairro:</label>
                <input-component disabled v-model="delivery.district"
                                 type-input="text"/>
              </row-col-component>
            </row-component>

            <row-component>
              <row-col-component class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                <label>Endereço:</label>
                <input-component disabled v-model="delivery.address" type-input="text"/>
              </row-col-component>

              <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                <label>País:</label>
                <input-component disabled v-model="delivery.country" type-input="text"/>
              </row-col-component>
            </row-component>

            <row-component>
              <row-col-component
                  class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <label>Referência:</label>
                <input-text-area disabled v-model="delivery.complement" :rows="4"/>
              </row-col-component>
            </row-component>

            <row-component>
              <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <label>Agenda:</label>
                <input-component disabled type-input="text" v-model="delivery.schedule_name"/>
              </row-col-component>

              <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <label>Cor:</label>
                <Multiselect disabled v-model="delivery.color"
                             :options="selectScheduleColor" :searchable="true"
                             :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
              </row-col-component>
            </row-component>

            <row-component>
              <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                <label>Data Início:</label>
                <input-component disabled v-mask="'##/##/####'"
                                 v-model="delivery.date_start" type-input="text"/>
              </row-col-component>

              <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                <label>Hora Início:</label>
                <input-component disabled v-mask="'##:##'" v-model="delivery.time_start"
                                 type-input="text"/>
              </row-col-component>

              <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                <label>Data Final:</label>
                <input-component disabled v-mask="'##/##/####'"
                                 v-model="delivery.date_end" type-input="text"/>
              </row-col-component>

              <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                <label>Hora Final:</label>
                <input-component disabled v-mask="'##:##'" v-model="delivery.time_end"
                                 type-input="text"/>
              </row-col-component>
            </row-component>

            <row-component>
              <row-col-component
                  class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <label>Descrição:</label>
                <input-text-area disabled v-model="delivery.desc" :rows="6"/>
              </row-col-component>
            </row-component>
          </form>

          <div v-if="formData.events.length > 0">
            <FullCalendar ref="fullCalendar" :options="calendarOptions"/>
          </div>
        </div>

        <section id="tab-item-3">
          <div class="clearfix mb-3">
            <button-submit @click="printGuarantees" :items="itensTwo" class="float-end"/>
            <button-submit class="float-end me-2" v-if="!formData.guarantees" :items="buttonGuarantee"
                           @click="guarantee.show = true"/>
            <button-submit class="float-end me-2" v-else :items="buttonGuaranteeE"
                           @click="guarantee.show = true"/>
          </div>

          <div class="alert alert-secondary alert-dismissible fade show"
               v-if="!formData.guarantees">
            Não há nada cadastrado
          </div>

          <form v-else>
            <row-component>
              <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                <label>Produto:</label>
                <input-component disabled v-model="guarantee.formData.product" type-input="text"/>
              </row-col-component>

              <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                <label>Serviço:</label>
                <input-component disabled v-model="guarantee.formData.service" type-input="text"/>
              </row-col-component>

              <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                <label>Data de início:</label>
                <input-component disabled v-model="guarantee.formData.date_start" v-mask="'##/##/####'"
                                 type-input="text"/>
              </row-col-component>
            </row-component>
          </form>
        </section>

        <section id="tab-item-4">
          <form>
            <row-component>
              <row-col-component
                  :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                <label>Observações internas:</label>
                <input-text-area :rows="6" v-model="formDataObs.obs"/>
              </row-col-component>
            </row-component>
          </form>

          <row-component>
            <row-col-component
                :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
              <button-submit :disaabled="sendForm" @submit="submitObs" :items="buttonObservation"
                             style="width: 100%;"/>
            </row-col-component>
          </row-component>

          <div class="line-division mt-4">
            <h1 class="h1-text"><i class="ti ti-brand-line"></i> Observações Internas</h1>
          </div>

          <div class="alert alert-secondary alert-dismissible fade show"
               v-if="formData.obsPrivate.length === 0">
            Não há nada cadastrado
          </div>

          <div v-else>
            <div class="card p-3 bg-light my-3 overflow-auto" v-for="(data, index) in formData.obsPrivate"
                 :key="index">
              <div>
                <img v-if="data.user_photo" class="avatar__image mx-1" :src="data.user_photo" alt="">
                <img v-else class="avatar__image mx-1" src="@/assets/images/avatar.png" alt="">
                {{ data.user_name }}

                <p class="d-inline-block mx-3"><i class="ti ti-calendar-due"></i> {{ data.date_create }}
                </p>
                <p class="d-inline-block mx-3"><i class="ti ti-clock-filled"></i> {{ data.hour_create }}
                </p>
              </div>
              <p>{{ data.obs }}</p>
            </div>
          </div>

          <label>Observações:</label>
          <QuillEditor theme="snow" :contentType="'html'" ref="myEditor"
                       v-model:content="formData.obs" style="min-height: 250px"/>

          <row-component class="mt-5">
            <row-col-component
                :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
              <button-submit :disaabled="sendForm" @submit="submit" :items="buttonSubmit"
                             style="width: 100%;"/>
            </row-col-component>
            <row-col-component
                :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
              <button-route :items="buttonRoute" style="width: 100%;"/>
            </row-col-component>
          </row-component>
        </section>

        <section id="tab-item-5">
          <div class="line-division mt-4">
            <h1 class="h1-text"><i class="ti ti-clock"></i> Histórico de interações</h1>
          </div>

          <div class="alert alert-secondary alert-dismissible fade show"
               v-if="formData.historic.length === 0">
            Não há nenhum histórico
          </div>

          <div v-else>
            <div class="card p-3 bg-light my-3 overflow-auto" v-for="(data, index) in formData.historic"
                 :key="index">
              <div>
                <img v-if="data.user_photo" class="avatar__image mx-1" :src="data.user_photo" alt="">
                <img v-else class="avatar__image mx-1" src="@/assets/images/avatar.png" alt="">
                {{ data.name_user }}

                <p class="d-inline-block mx-3"><i class="ti ti-calendar-due"></i> {{ data.date_create }}
                </p>
                <p class="d-inline-block mx-3"><i class="ti ti-clock-filled"></i> {{ data.hour_create }}
                </p>
              </div>
              <p>{{ statusHistoric(data.status) }},
                Plataforma: {{ data.platform }},
                Navegador: {{ data.browser }},
                Endereço IP: {{ data.ip }}</p>
            </div>
          </div>
        </section>
      </div>
    </div>
  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from '@/components/row/RowColComponent';
import InputComponent from '@/components/form/InputComponent';
import Multiselect from '@vueform/multiselect';
import ButtonSubmit from '@/components/buttons/ButtonSubmit';
import ButtonRoute from '@/components/buttons/ButtonRoute';
import InputTextArea from '@/components/form/InputTextArea';
import ButtonDelete from "@/components/buttons/ButtonDelete";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import {mask} from "vue-the-mask";
import {MONEY} from "@/configs/constants";
import {VMoney} from "v-money";
import {useToast} from "vue-toastification";
import {QuillEditor} from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import ptLocale from "@fullcalendar/core/locales/pt";

const toast = useToast()
export default {
  name: 'BudgetUpdate',

  props: {
    id: {
      required: true
    }
  },

  created() {
    this.$store.dispatch('updateNamePage', 'Orçamento')
    this.$store.dispatch('loadNameBudgetList')
    this.$store.dispatch('loadProductMultiSelect')
    this.$store.dispatch('loadServiceMultiSelect')
    this.$store.dispatch('loadClientMultiSelect')
    this.$store.dispatch('loadBrandMultiSelect')
    this.$store.dispatch('loadBudgetTemplateMultiSelect')
    this.$store.dispatch('loadBudgetListPayment')
    this.$store.dispatch('loadCategoryMultiSelect')
    this.checkACL()
    this.loadBudget()
  },

  components: {
    FullCalendar,
    QuillEditor,
    ErrorComponent,
    ButtonDelete,
    PainelComponent,
    RowComponent,
    RowColComponent,
    InputComponent,
    Multiselect,
    ButtonSubmit,
    ButtonRoute,
    InputTextArea,
  },

  directives: {
    money: VMoney,
    mask
  },

  data() {
    return {
      itensOne: {
        title: "Editar",
        icon: "ti-edit"
      },

      itensTwo: {
        icon: 'ti-printer',
      },

      buttonSubmit: {
        icon: 'ti-device-floppy',
        nameButton: 'Salvar',
        typeButton: 'button'
      },

      buttonRoute: {
        icon: 'ti-arrow-back-up',
        route: '/budget/search',
        name: 'Voltar'
      },

      buttonAdd: {
        icon: 'ti-circle-plus',
        typeButton: 'button'
      },

      buttonAddRoute: {
        icon: 'ti-circle-plus',
        route: '/budget/create',
      },

      buttonRouteAdd: false,

      buttonSearch: {
        icon: 'ti-search',
        typeButton: 'button'
      },

      buttonPrint: {
        icon: 'ti-printer',
        typeButton: 'button'
      },

      buttonClone: {
        icon: 'ti-copy',
        typeButton: 'button'
      },

      buttonConfirm: {
        icon: 'ti-circle-plus',
        typeButton: 'button',
        nameButton: 'Confirmar'
      },

      buttonObservation: {
        icon: 'ti-circle-plus',
        typeButton: 'button',
        nameButton: 'Adicionar Observações'
      },

      buttonGuarantee: {
        icon: 'ti-circle-plus',
        typeButton: 'button',
      },

      buttonGuaranteeE: {
        icon: 'ti-edit',
        typeButton: 'button',
      },

      buttonGuaranteeOne: {
        icon: 'ti-circle-plus',
        typeButton: 'button',
        nameButton: 'Adicionar',
      },

      buttonActions: [
        {icon: 'ti-send'},
        {icon: 'ti-circle-check'},
        {icon: 'ti-ban'},
        {icon: 'ti-square-x'},
      ],

      buttonCheck: {
        approved: false,
        cancel: false,
        end: false
      },

      modalClient: false,
      modalEmail: false,
      modalPhone: false,
      modalBudgetTemplate: false,
      budget_template_id: '',
      disabledField: true,

      sector_modal: false,
      sectors: [
        {name: "Cadastrado", id: 1},
        {name: "Enviado para cliente", id: 2},
        {name: "Aprovado Bene Piscinas", id: 3},
        {name: "Aprovado Pelo Cliente", id: 4},
        {name: "Cancelado", id: 5},
        {name: "Enviado para entrega", id: 6},
        {name: "Finalizado", id: 7},
      ],

      formDataObs: {
        id: this.id,
        obs: '',
      },

      status: '',

      copy: true,

      formData: {
        name: '',
        status: '',
        client_id: '',
        client_name: '',
        responsible: '',
        email: '',
        phone: '',
        obs: '',
        valid: 15,
        show_value: 1,
        link_budget: '',
        link_budget_pdf: '',
        obsPrivate: '',
        historic: [],
        products: [],
        services: [],
        guarantees: '',
        name_budget_id: '',
        events: [],
        budget_delivery: '',
        link_delivery: '',
        link_guarantees: '',
        payment_methods: [],
      },

      guarantee: {
        show: false,
        formData: {
          budget_id: this.id,
          product: "",
          service: "",
          date_start: ""
        }
      },

      delivery: {
        budget_id: "",
        schedule_id: "",
        date_start: "",
        time_start: "",
        date_end: "",
        time_end: "",
        color: "",
        delivery_type: "",
        schedule_name: "",
        cep: "",
        city: "",
        state: "",
        district: "",
        address: "",
        complement: "",
        country: ""
      },

      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        dateClick: this.handleDateClick,
        eventClick: this.eventClick,
        locale: ptLocale,
        themeSystem: 'standard',
        selectable: true,
        eventDisplay: 'block',
        events: [],
        height: 'auto',
        headerToolbar: {
          left: 'title',
          right: 'dayGridMonth,dayGridWeek,listDay, prev,next'
        },
        weekends: true,
        customButtons: {
          prev: {
            click: () => {
              let calendarApi = this.$refs.fullCalendar.getApi();
              calendarApi.prev();
              this.loadEventsMonth(calendarApi.getDate().getMonth() + 1)
            }
          },
          next: {
            click: () => {
              let calendarApi = this.$refs.fullCalendar.getApi();
              calendarApi.next();
              this.loadEventsMonth(calendarApi.getDate().getMonth() + 1)
            }
          }
        },
      },

      money: MONEY,
      totalResult: 0,

      errors: [],
      showView: false,
      sendForm: false,
      showError: false,

      searchSubmit: false
    }
  },

  methods: {
    loadBudget() {
      this.$store.dispatch('loadBudget', this.id)
          .then((r) => {
            this.formData = r
            this.loadFakesFields(r.products)
            this.statusString()
            this.calcTotal()
            this.nullInputArray()
            this.calendarOptions.events = r.events
            this.delivery = this.formData.budget_delivery
            if (this.formData.guarantees) {
              this.guarantee.formData = this.formData.guarantees
            }
          }).catch(() => {
        toast.error('Erro desconhecido', {timeout: 2000})
      })
    },

    submitStatus(status) {
      let params = {
        id: this.id,
        status: status
      }

      this.$store.dispatch('budgetUpdateStatus', params)
          .then(() => {
            this.showError = false
            this.errors = []
            this.loadBudget()
            this.sector_modal = false
            if (parseInt(params.status) === 5) {
              toast.warning('Orçamento cancelado, não é possível alterar o status', {timeout: 1500})
            } else if (parseInt(params.status) === 7) {
              toast.warning('Orçamento finalizado, não é possível alterar o status', {timeout: 1500})
            } else {
              toast.success('Status atualizado com sucesso', {timeout: 1500})
            }
          }).catch((e) => {
        this.showError = true
        this.errors = e.response.data.errors
      })
    },

    cloneFunction() {
      // Clonando produtos
      const clonedProducts = this.productAdd.map(product => ({
        brand_id: product.brand_id,
        categorie_id: product.categorie_id,
        product_id: product.product_id,
        amount: product.amount,
        unit_price: product.unit_price,
        total_price: product.total_price
      }));

      // Clonando serviços
      const clonedServices = this.serviceAdd.map(service => ({
        service_id: service.service_id,
        amount: service.amount,
        unit_price: service.unit_price,
        total_price: service.total_price
      }));

      // Clonando formas de pagamento
      const clonedPaymentForms = this.paymentFormsAdd.map(paymentForm => ({
        form_of_payment: paymentForm.form_of_payment,
        portion: paymentForm.portion,
        price: paymentForm.price
      }));

      this.storeState.push({
        name_budget_id: this.formData.name_budget_id,
        client_name: this.formData.client_name,
        valid: this.formData.valid,
        phone: this.formData.phone,
        products: clonedProducts,     // Envia a lista de produtos clonados
        services: clonedServices,     // Envia a lista de serviços clonados
        payment_methods: clonedPaymentForms // Envia a lista de formas de pagamento clonadas
      });

      // Redireciona para a página de criação de orçamento
      this.$router.push({name: 'budget-create'});
    },

    sectorModal() {
      this.sector_modal = !this.sector_modal
    },

    submitObs() {
      this.$store.dispatch('storeBudgetObs', this.formDataObs)
          .then(() => {
            toast.success("Observação adicionada com sucesso", {timeout: 2000});
            this.loadBudget()
          }).catch((r) => {
        this.showError = true;
        this.sendForm = false
        this.errors = r.response.data.errors;
        this.scrollToTop()
        toast.error("Ocorreu um erro ao adicionar observação !", {timeout: 2000});
      })
    },

    submit() {
      if (!this.sendForm) {
        this.sendForm = true;
        this.$store.dispatch('updateBudget', this.formData)
            .then(() => {
              this.showError = false
              this.sendForm = false
              this.errors = []
              toast.success("Cadastro atualizado com sucesso", {timeout: 2000});
            }).catch((r) => {
          this.showError = true;
          this.sendForm = false
          this.errors = r.response.data.errors;
          this.scrollToTop()
          toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
        })
      }
    },

    submitGuarantee() {
      this.$store.dispatch('storeBudgetGuarantee', this.guarantee.formData)
          .then(() => {
            this.errors = []
            this.showError = false
            this.guarantee.show = false
            this.loadBudget()
            toast.success("Cadastro adicionado com sucesso", {timeout: 2000});
          }).catch((r) => {
        this.scrollToTop()
        this.showError = true;
        this.errors = r.response.data.errors;
        toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
      })
    },

    updateGuarantee() {
      this.$store.dispatch('updateBudgetGuarantee', this.guarantee.formData)
          .then(() => {
            this.errors = []
            this.showError = false
            this.guarantee.show = false
            this.loadBudget()
            toast.success("Cadastro atualizado com sucesso", {timeout: 2000});
          }).catch((r) => {
        this.scrollToTop()
        this.showError = true;
        this.errors = r.response.data.errors;
        toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
      })
    },

    async checkACL() {
      this.$store.dispatch('ACLitens', 13)
          .then((r) => {
            if (r.data.indexOf('budget-edit') !== -1) {
              this.showView = true

              if (r.data.indexOf('budget-create') !== -1) {
                this.buttonRouteAdd = true
              }

              if (r.data.indexOf('budget-approved') !== -1) {
                this.buttonCheck.approved = true
              }

              if (r.data.indexOf('budget-cancel') !== -1) {
                this.buttonCheck.cancel = true
              }

              if (r.data.indexOf('budget-end') !== -1) {
                this.buttonCheck.end = true
              }

            } else {
              this.$router.push({name: 'home'})
            }
          }).catch(() => {
        this.$router.push({name: 'home'})
      })
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },

    printGuarantees() {
      window.open(this.formData.link_guarantees, "_blank")
    },

    printDelivery() {
      window.open(this.formData.link_delivery, "_blank")
    },

    routeAddGuarantees() {
      this.$router.push({name: "budget-delivery-create", params: {id: this.id, client: this.formData.client_id}})
    },

    routeAddGuaranteesEdit() {
      this.$router.push({
        name: 'budget-delivery-edit',
        params: {id: this.id, client: this.formData.client_id, delivery: this.formData.budget_delivery.id}
      })
    },

    eventClick: function () {
      // let publicId = dateObject.event._def.publicId
      this.loadBudget()
    },

    loadEventsMonth(month) {
      let params = {id: this.formData.client_id, month: month}
      this.$store.dispatch('loadBudgetScheduleEventsMonth', params)
          .then((r) => {
            this.calendarOptions.events = r
          });
    },

    copyUrl(url) {
      navigator.clipboard.writeText(url)
      this.$refs.select.focus()
      this.copy = false
      setTimeout(() => {
        this.copy = true
      }, 2000)
    },

    statusHistoric(value) {
      if (parseInt(value) === 1)
        return 'Cadastrado'

      if (parseInt(value) === 2)
        return 'Enviado para cliente'

      if (parseInt(value) === 3)
        return 'Aprovado Bene Piscinas'

      if (parseInt(value) === 4)
        return 'Aprovado Pelo Cliente'

      if (parseInt(value) === 5)
        return 'Cancelado'

      if (parseInt(value) === 6)
        return 'Enviado para entrega'

      if (parseInt(value) === 7)
        return 'Finalizado'

      if (parseInt(value) === 8)
        return 'Adicionado garantia'

      if (parseInt(value) === 9)
        return 'Atualizou a garantia'
    },

    statusString() {
      if (parseInt(this.formData.status) === 1)
        this.status = 'Cadastro'

      if (parseInt(this.formData.status) === 2)
        this.status = 'Enviado para cliente'

      if (parseInt(this.formData.status) === 3)
        this.status = 'Aprovado pelo cliente'

      if (parseInt(this.formData.status) === 5)
        this.status = 'Cancelado'

      if (parseInt(this.formData.status) === 6)
        this.status = 'Finalizado'
    },

    printPdf() {
      window.open(this.formData.link_budget_pdf, '_blank')
    },

    modalSearchClientPhone() {
      this.modalPhone = !this.modalPhone
      this.$store.dispatch('loadClientPhoneMultiSelect', this.formData.client_id)
    },

    modalSearchBudgetTemplate() {
      this.modalBudgetTemplate = !this.modalBudgetTemplate
    },

    loadBudgetTemplateGetMultiSelect(id) {
      this.$store.dispatch('loadBudgetTemplateGetMultiSelect', id)
          .then((r) => {
            this.formData.name = r.name
            this.formData.obs = r.obs
            this.formData.services = r.services
            this.formData.products = r.products
            this.nullInputArray()
          })
    },

    // #######Funções para Produtos e Serviços#######
    loadCategoryBrandList(id, index) {
      this.$store.dispatch('loadCategoryBrandList', id)
      this.clearProduct(index)
    },

    loadProductBrandMultiSelect(brand, category, index) {
      let formData = {
        brand: brand,
        category: category
      }
      this.$store.dispatch('loadProductBrandMultiSelect', formData)
      this.clearProduct(index)
    },

    loadFakesFields(products) {
      products.forEach((element) => {
        let formData = {
          brand: element.brand_id,
          category: element.categorie_id
        }
        this.$store.dispatch('loadProductBrandMultiSelect', formData)
        this.$store.dispatch('loadCategoryBrandList', formData.brand)
      })
    },

    loadProductPriceMultiSelect(id, index) {
      this.$store.dispatch('loadProductPriceMultiSelect', id)
          .then((r) => {
            this.productAdd[index].unit_price = r
            this.calcProduct(index)
          })
    },

    clearProduct(index) {
      this.productAdd[index].amount = 1
      this.productAdd[index].unit_price = ''
      this.productAdd[index].total_price = ''
      this.calcProduct(index)
    },

    clearBrand(index) {
      this.productAdd[index].product_id = ''
      this.productAdd[index].brand_id = '';
      this.productAdd[index].amount = 1
      this.productAdd[index].unit_price = ''
      this.productAdd[index].total_price = ''
      this.calcProduct(index)
    },

    loadServicePriceMultiSelect(id, index) {
      this.$store.dispatch('loadServicePriceMultiSelect', id)
          .then((r) => {
            this.serviceAdd[index].unit_price = r
            this.calcService(index)
          })
    },

    clearService(index) {
      this.serviceAdd[index].amount = 1
      this.serviceAdd[index].unit_price = ''
      this.serviceAdd[index].total_price = ''
      this.calcService()
    },

    nullInputArray() {
      if (this.formData.products.length === 0)
        this.addProduct()
      if (this.formData.services.length === 0)
        this.addService()
      if (this.formData.payment_methods.length === 0)
        this.addPaymentForms()
    },

    addProduct() {
      this.formData.products.push({
        product_id: '',
        brand_id: '',
        categorie_id: '',
        amount: 1,
        unit_price: '',
        total_price: ''
      })
    },

    addService() {
      this.formData.services.push({
        service_id: '',
        amount: 1,
        unit_price: '',
        total_price: ''
      })
    },

    addPaymentForms() {
      this.formData.payment_methods.push({
        form_of_payment: '',
        portion: '1',
        price: ''
      })
    },

    productRemove(index) {
      this.formData.products.splice(index, 1)
      this.calcTotal()
    },

    serviceRemove(index) {
      this.formData.services.splice(index, 1)
      this.calcTotal()
    },

    paymentFormsRemove(index) {
      this.formData.payment_methods.splice(index, 1)
    },

    replaceStringOne(label) {
      if (label.length < 8) {
        return parseFloat(label.replace(',', '.'))
      } else {
        return parseFloat(label.replace(',', ''))
      }
    },

    calcProduct(index) {
      let budget = this.formData.products[index];
      budget.unit_price.toLocaleString('pt-br', {minimumFractionDigits: 2});
      let value = budget.unit_price.replace(/[.,]/g, '')
      let amount = budget.amount
      let result = (value * amount / 100)
      budget.total_price = result.toLocaleString('pt-br', {minimumFractionDigits: 2})
      this.calcTotal()
    },

    calcService(index) {
      if (this.formData.services[index]) {
        let budget = this.formData.services[index];

        if (budget.unit_price && budget.amount) {
          // Remove caracteres não numéricos, exceto ponto e vírgula
          let value = parseFloat(budget.unit_price.replace(/[.,]/g, ''));
          let amount = parseFloat(budget.amount);

          // Valida se ambos são números válidos
          if (!isNaN(value) && !isNaN(amount)) {
            let result = (value * amount) / 100;
            budget.total_price = result.toLocaleString('pt-br', { minimumFractionDigits: 2 });
          } else {
            budget.total_price = '0,00';
          }

          this.calcTotal();
        }
      }
    },

    calcTotal() {
      let sum = 0;
      let price_service = 0;
      let price_product = 0;
      let products = this.formData.products;
      let services = this.formData.services;

      if (this.totalResult > 0)
        sum = this.replaceStringOne(this.totalResult)

      products.forEach((item) => {
        let productPrice = parseFloat(item.total_price.replace(/[.,]/g, '')) / 100;
        if (!isNaN(productPrice)) {
          price_product += productPrice;
        }
      });

      services.forEach((item) => {
        let servicePrice = parseFloat(item.total_price.replace(/[.,]/g, '')) / 100;
        if (!isNaN(servicePrice)) {
          price_service += servicePrice;
        }
      });

      sum = price_service + price_product;
      this.totalResult = sum.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2});
    },

    calcDiscount(type, index) {
      let a = 0

      if (this.totalResult !== 0)
        a = this.replaceStringOne(this.totalResult.replace(/[.,]/g, '')) / 100
      if (type < 5) {
        let discount = a * 0.05
        let sum = a - discount
        this.formData.payment_methods[index].price = sum.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
      }

      if (type > 4) {
        let installments = this.formData.payment_methods[index].portion
        let sum = a / installments
        this.formData.payment_methods[index].price = sum.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
      }
    },
  },

  computed: {
    me() {
      return this.$store.state.auth.me
    },

    selectActiveInative() {
      return this.$store.state.var.selectActiveInative
    },

    noResultsText() {
      return this.$store.state.var.noResultsText
    },

    noOptionsText() {
      return this.$store.state.var.noOptionsText
    },

    selectNameBudgetList() {
      return this.$store.state.var.selectNameBudgetList
    },

    selectProductBrandList() {
      return this.$store.state.var.selectProductBrandList
    },

    selectBrandList() {
      return this.$store.state.var.selectBrandList
    },

    selectCategoryBrandList() {
      return this.$store.state.var.selectCategoryBrandList
    },

    selectServiceList() {
      return this.$store.state.var.selectServiceList
    },

    selectClientList() {
      return this.$store.state.var.selectClientList
    },

    selectClientPhoneList() {
      return this.$store.state.var.selectClientPhoneList
    },

    selectBudgetTemplateList() {
      return this.$store.state.var.selectBudgetTemplateList
    },

    selectBudgetPaymentList() {
      return this.$store.state.var.selectBudgetPaymentList
    },

    selectScheduleColor() {
      return this.$store.state.var.selectScheduleColor
    },

    storeState() {
      return this.$store.state.var.storeState
    },

    productAdd() {
      return this.formData.products;
    },

    serviceAdd() {
      return this.formData.services;
    },

    paymentFormsAdd() {
      return this.formData.payment_methods;
    },
  }
}
</script>

<style scoped>
.buttonSubmit {
  background-color: #36c1bd;
  color: white;
  border-color: #36c1bd;
}

.color-main {
  color: #36c1bd;
}

.btn-group {
  height: 46px;
}

.avatar__image {
  width: 25px;
}

.line-division {
  width: 100%;
  border-top: 1px solid #c4c4c4;
}

.h1-text {
  margin-top: 1px;
  width: fit-content;
  padding: 10px;
  background-color: #36c1bd;
  color: white;
  font-size: 16px;
}

.modal-search-budget-template .btn-close,
.modal-search-client .btn-close,
.modal-search-email .btn-close,
.modal-search-phone .btn-close {
  top: 0;
  right: 0;
  margin: 1%;
  cursor: pointer;
  position: absolute;
}

.modal-search-budget-template,
.modal-search-phone,
.send-sector {
  top: 50%;
  left: 50%;
  width: 40%;
  z-index: 9999;
  position: fixed;
  min-height: 20%;
  margin-right: -50%;
  padding: 40px 20px;
  overflow-y: auto;
  background: #FFFFFF;
  transform: translate(-50%, -50%);
  box-shadow: 10px 10px 23px 0 rgb(204 204 204);
}

.send-sector .btn-close {
  top: 3%;
  right: 1%;
  position: absolute;
}

.modal-w {
  z-index: 100;
  width: 100vw;
  height: 100vh;
  place-items: center;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal-p {
  width: 60%;
  height: auto;
  overflow-y: auto;
  background-color: #FFFFFF;
}

.modal-p .btn-close {
  top: 10px;
  right: 10px;
  position: absolute;
}

@media (max-width: 991px) {
  .modal-search-budget-template,
  .modal-search-phone,
  .modal-p {
    width: 95%;
    height: 80%;
  }

  .send-sector {
    width: 95%;
  }
}
</style>
