<template>
    <div class="modal-entrance-exit-show" v-if="modal_show">
        <EntranceExitShow :id="id_show" @close-modal="modal_show = !modal_show"/>
    </div>

    <painel-component :itens="itensOne">
        <row-component>
            <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                <label>Código:</label>
                <input-component :type-input="'text'" v-model="formData.code"/>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                <label>Mês:</label>
                <Multiselect v-model="formData.month" :options="selectMonthList" :searchable="true"
                             :noOptionsText="noOptionsText"
                             :noResultsText="noResultsText"/>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                <label>Ano:</label>
                <Multiselect v-model="formData.year" :options="getYearSelect()" :searchable="true"
                             :noOptionsText="noOptionsText"
                             :noResultsText="noResultsText"/>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                <label>Conta Bancária:</label>
                <Multiselect label="name" v-model="formData.bank_accounts_id" :options="selectAccountBankList"
                             :searchable="true"
                             :noOptionsText="noOptionsText"
                             :noResultsText="noResultsText"/>
            </row-col-component>
        </row-component>

        <row-component>
            <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                <label>Tipo Entrada/Saída:</label>
                <Multiselect v-model="formData.type" :options="selectEntranceExitList" :searchable="true"
                             :noOptionsText="noOptionsText"
                             :noResultsText="noResultsText"/>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                <label>Tipo de Conta:</label>
                <Multiselect v-model="formData.account_types_id" :options="selectAccountList" :searchable="true"
                             :noOptionsText="noOptionsText"
                             :noResultsText="noResultsText"/>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                <label>Antecipado:</label>
                <Multiselect v-model="formData.attended" :options="selectYesNoList" :searchable="true"
                             :noOptionsText="noOptionsText"
                             :noResultsText="noResultsText"/>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mt-4'">
                <button-submit @submit="submit" :items="buttonSubmit" class="me-2"/>
                <button-route v-if="buttonCheck.create" :items="buttonRoute"/>
            </row-col-component>
        </row-component>

        <table class="table table-striped mt-4">
            <thead>
            <tr>
                <th scope="col" style="width: 10%">Código</th>
                <th scope="col" style="width: 15%">Conta Bancária</th>
                <th scope="col" style="width: 15%">Tipo de Conta <br> Tipo de Sub Conta</th>
                <th scope="col" style="width: 10%">Preço</th>
                <th scope="col" style="width: 10%">Entrada/Saída</th>
                <th scope="col" style="width: 10%">Data de Vencimento <br> Data de Efetivação</th>
                <th scope="col" style="width: 5%">Ações</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(entrance_exit, index) in this.object" :key="index">
                <td data-label="Código">{{ entrance_exit.code }}</td>
                <td data-label="Conta Bancária">
                    <img v-if="entrance_exit.bank_accounts_photo" class="avatar__image mx-1"
                         :src="entrance_exit.bank_accounts_photo" alt="">
                    <img v-else class="avatar__image mx-1" src="@/assets/images/avatar.png" alt="">
                    {{ entrance_exit.bank_accounts_name }}
                </td>
                <td data-label="Tipo de Conta/Tipo de Sub Conta">
                    {{ entrance_exit.account_types_name }} <br> {{ entrance_exit.account_types_sub_name }}
                </td>
                <td data-label="Preço">{{ entrance_exit.price_paid }}</td>
                <td data-label="Entrada/Saída">{{ statusString(entrance_exit.type) }}</td>
                <td data-label="Data de Vencimento/Data de Efetivação">
                    {{ entrance_exit.due_date }} <br> {{ entrance_exit.effective_date }}
                </td>
                <td data-label="Ações">
                    <button class="btn btn-secondary mx-1" @click="modalShow(entrance_exit.id)">
                        <i class="ti ti-eye"></i>
                    </button>
                </td>
            </tr>
            </tbody>
        </table>

        <button-pagination :pagination="entrance_exit.itens" :offset="3" @paginate="loadEntriesDepartures"/>
    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from '@/components/row/RowColComponent';
import InputComponent from '@/components/form/InputComponent';
import Multiselect from '@vueform/multiselect';
import ButtonSubmit from '@/components/buttons/ButtonSubmit';
import ButtonRoute from '@/components/buttons/ButtonRoute';
import {useToast} from "vue-toastification";
import ButtonPagination from "@/components/buttons/ButtonPagination.vue";

const toast = useToast()

import EntranceExitShow from "@/views/show/EntranceExitShow";

export default {
    name: 'EntranceExitSearch',

    created() {
        this.$store.dispatch('updateNamePage', 'Entrada/Saída')
        this.$store.dispatch('loadAccountBankMultiSelect')
        this.$store.dispatch('loadAccountTypeMultiSelect')
        this.checkACL()
        this.loadEntriesDepartures(1)
    },

    components: {
        ButtonPagination,
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        Multiselect,
        ButtonSubmit,
        ButtonRoute,
        EntranceExitShow
    },

    data() {
        return {
            itensOne: {
                title: "Pesquisa",
                icon: "ti-list"
            },

            buttonSubmit: {
                icon: 'ti-search',
            },

            buttonRoute: {
                icon: 'ti-plus',
                route: '/entrance-exit/create'
            },

            buttonCheck: {
                create: false,
            },

            object: {},

            modal_show: false,
            id_show: '',

            formData: {
                month: '',
                year: '',
                code: '',
                bank_accounts_id: '',
                account_types_id: '',
                type: '',
                attended: ''
            },

            searchSubmit: false,
        }
    },

    methods: {

        submit() {
            this.loadEntriesDepartures(1)
            this.searchSubmit = true
        },

        modalShow(id) {
            this.modal_show = !this.modal_show
            if (id)
                this.id_show = id
        },

        statusString(value) {
            if (value === '1')
                return 'Entrada'
            return 'Saída'
        },

        getYearSelect() {
            let dataActual = new Date();
            let year = dataActual.getFullYear() + 2;
            let years = [];
            for (let i = 2021; i <= year; i++) {
                years.push(i);
            }
            return years
        },

        loadEntriesDepartures(page) {
            if (!this.searchSubmit) {
                this.$store.dispatch('loadEntriesDepartures', {...this.params, page})
                    .then((r) => {
                        this.object = r.itens.data
                        if (this.searchSubmit) {
                            this.searchSubmit = false;
                            if (r.itens.data == '') {
                                toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 2000});
                            }
                        }
                    }).catch(() => {
                    this.searchSubmit = false;
                });
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 17)
                .then((r) => {
                    if (r.data.indexOf('entrance-exit-search') !== -1) {

                        if (r.data.indexOf('entrance-exit-create') !== -1) {
                            this.buttonCheck.create = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

    },

    computed: {
        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        selectMonthList() {
            return this.$store.state.var.selectMonthList
        },

        selectEntranceExitList() {
            return this.$store.state.var.selectEntranceExitList
        },

        selectAccountBankList() {
            return this.$store.state.var.selectAccountBankList
        },

        selectAccountList() {
            return this.$store.state.var.selectAccountList
        },

        selectYesNoList() {
            return this.$store.state.var.selectYesNoList
        },

        entrance_exit() {
            return this.$store.state.entrance_exit.items
        },

        params() {
            return {
                page: this.entrance_exit.current_page,
                month: this.formData.month,
                year: this.formData.year,
                code: this.formData.code,
                bank_accounts_id: this.formData.bank_accounts_id,
                account_types_id: this.formData.account_types_id,
                type: this.formData.type,
                attended: this.formData.attended
            }
        },

        me() {
            return this.$store.state.auth.me
        },
    }
}
</script>

<style src="@/assets/style/table.css">

</style>

<style scoped>
.avatar__image {
    width: 35px;
}

.modal-entrance-exit-show {
    top: 50%;
    left: 50%;
    width: 50%;
    z-index: 15;
    position: fixed;
    min-height: 40%;
    margin-right: -50%;
    padding: 40px 20px;
    overflow-y: auto;
    background: #FFFFFF;
    transform: translate(-50%, -50%);
    box-shadow: 10px 10px 23px 0 rgb(204 204 204);
}


@media (max-width: 991px) {
    .modal-entrance-exit-show{
        width: 96%;
        height: 90%;
        overflow-y: auto;
    }
}
</style>