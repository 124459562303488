import state from "@/store/modules/client/state";
import actions from "@/store/modules/client/actions";
import mutations from "@/store/modules/client/mutations";
import getters from "@/store/modules/client/getters";

export default {
    state,
    actions,
    mutations,
    getters
}