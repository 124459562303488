import axios from "axios";
import {URL_BASE} from "@/configs/constants";

const RESOURCE = 'inventorie';
export default {

    loadInventories(context, params) {
        context.commit('LOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}`, {params})
                .then(response => {
                    context.commit('LOAD_INVENTORY', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                return reject(errors)
            })
                .finally(() => {
                    context.commit('LOADER', false)
                })
        })
    },

    storeInvetory(context, formData) {
        context.commit('LOADER', true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}${RESOURCE}`, formData)
                .then((r) => resolve(r))
                .catch(errors => reject(errors))
                .finally(() => {
                    context.commit('LOADER', false)
                })
        })
    },

}