import menuAgenda from "@/configs/linksMenu/modules/menuAgenda";
import menuCadastro from "@/configs/linksMenu/modules/menuCadastro";
import menuEstoque from "@/configs/linksMenu/modules/menuEstoque";
import menuFinanceiro from "@/configs/linksMenu/modules/menuFinanceiro";
import menuRelatorio from "@/configs/linksMenu/modules/menuRelatorio";

export default {
    menuAgenda,
    menuCadastro,
    menuEstoque,
    menuFinanceiro,
    menuRelatorio
}