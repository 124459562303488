import AdminComponent from '@/components/dashboard/AdminComponent'
import AccountBankSearch from "@/views/search/AccountBankSearch";
import AccountBankCreate from "@/views/create/AccountBankCreate";
import AccountBankUpdate from "@/views/update/AccountBankUpdate";
import AccountBankShow from "@/views/show/AccountBankShow";
import AccountBankUpdatePhoto from "@/views/update/AccountBankUpdatePhoto";

export default {
    path: '/account-bank',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'search', name: 'account-bank-search', component: AccountBankSearch},
        {path: 'create', name: 'account-bank-create', component: AccountBankCreate},
        {path: 'edit/:id', name: 'account-bank-edit', component: AccountBankUpdate, props: true},
        {path: 'show/:id', name: 'account-bank-show', component: AccountBankShow, props: true},
        {path: 'photo/:id', name: 'account-bank-edit-photo', component: AccountBankUpdatePhoto, props: true},
    ]
}