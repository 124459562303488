import AdminComponent from "@/components/dashboard/AdminComponent";

import GuestScheduleSearch from "@/views/search/GuestScheduleSearch";
import GuestScheduleCreate from "@/views/create/GuestScheduleCreate";
import GuestScheduleUpdate from "@/views/update/GuestScheduleUpdate";
import GuestScheduleShow from "@/views/show/GuestScheduleShow";
import GuestScheduleUpdatePhoto from "@/views/update/GuestScheduleUpdatePhoto";

export default {
    path: "/guest-schedule",
    meta: {auth: true},
    component: AdminComponent,
    children: [
        {path: "search", name: "guest-schedule-search", component: GuestScheduleSearch},
        {path: "create", name: "guest-schedule-create", component: GuestScheduleCreate},
        {path: "edit/:id", name: "guest-schedule-edit", component: GuestScheduleUpdate, props: true},
        {path: "show/:id", name: "guest-schedule-show", component: GuestScheduleShow, props: true},
        {path: "photo/:id", name: "guest-edit-photo", component: GuestScheduleUpdatePhoto, props: true},
    ]
}