import state from "@/store/modules/account-type/state";
import actions from "@/store/modules/account-type/actions";
import mutations from "@/store/modules/account-type/mutations";
import getters from "@/store/modules/account-type/getters";

export default {
    state,
    actions,
    mutations,
    getters
}