export default {
    icon: 'ti ti-address-book',
    name: 'Agenda',
    subLinks: [

        {
            name: 'Agendas',
            route: '/schedule/search',
            pagePermissions: [
                'schedule-search',
                'schedule-create',
                'schedule-edit',
                'schedule-delete'
            ],
        },

        {
            name: 'Convidado Agenda',
            route: '/guest-schedule/search',
            pagePermissions: [
                'guest-schedule-search',
                'guest-schedule-create',
                'guest-schedule-edit',
                'guest-schedule-delete'
            ],
        },

        {
            name: 'Minhas agendas',
            route: '/my-schedule/search',
            pagePermissions: [
                'my-schedule-search',
                'my-schedule-edit'
            ],
        },
    ],

    pageLinksNavCheck: [
        'schedule-search',
        'schedule-create',
        'schedule-edit',
        'schedule-delete',
        'my-schedule-search',
        'my-schedule-edit'
    ],
}