<template>
    <painel-component :itens="itensOne">

        <div class="tabs effect-3" v-if="showView">
            <input checked type="radio" id="tab-1" name="tab-effect-3">
            <span>
                <i class="ti ti-info-square-rounded"></i>
                <p>Informações</p>
            </span>

            <input @click="eventClick" type="radio" id="tab-2" name="tab-effect-3">
            <span>
                <i class="ti ti-truck-delivery"></i>
                <p>Entrega</p>
            </span>

            <input type="radio" id="tab-3" name="tab-effect-3">
            <span>
                <i class="ti ti ti-report-money"></i>
                <p>Garantia</p>
            </span>

            <input type="radio" id="tab-4" name="tab-effect-3">
            <span>
                <i class="ti ti ti-brand-line"></i>
                <p>Observações</p>
            </span>

            <input type="radio" id="tab-5" name="tab-effect-3">
            <span>
                <i class="ti ti-clock-hour-9"></i>
                <p>Histórico de interações</p>
            </span>

            <div class="line ease"></div>
            <div class="tab-content">
                <section id="tab-item-1">
                    <div class="clearfix mb-4">
                        <span class="badge text-bg-primary me-2" style="background-color: #36c1bd !important;">
                            Status: {{ statusHistoric(formData.status) }}
                        </span>
                    </div>

                    <form>
                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                                <label>URL:</label>
                                <div class="input-group" @click="copyUrl(formData.link_budget)">
                                    <input v-model="formData.link_budget" @focus="$event.target.select()" ref="select"
                                           readonly
                                           type="text" class="form-control">
                                    <span @click="copyUrl(formData.link_budget)" class="buttonSubmit input-group-text">
                                        <i :class="[copy ? 'ti ti-clipboard-copy' : 'ti ti-clipboard-check']"></i>
                                    </span>
                                </div>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                <label>Status:</label>
                                <input-component disabled :type-input="'text'" v-model="status"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12'">
                                <label>Nome:</label>
                                <input-component disabled :type-input="'text'" v-model="formData.name"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mt-md-4'">
                                <label>Exibir Valores?</label>

                                <input disabled v-model="formData.show_value" type="radio" name="docs" id="value-no"
                                       value="1">
                                <label for="value-no">Não</label>

                                <input disabled v-model="formData.show_value" type="radio" name="docs" id="value-yes"
                                       value="2">
                                <label for="value-yes">Sim</label>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                <label>Cliente:</label>
                                <input-component disabled :type-input="'text'" v-model="formData.client_name"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                <label>Dias Válido:</label>
                                <input-component disabled min="15" :type-input="'number'" v-model="formData.valid"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                <label>Telefone:</label>
                                <input-component disabled type-input="text" v-model="formData.phone"/>
                            </row-col-component>
                        </row-component>

                        <div class="card p-3 bg-light my-3" v-for="(data, index) in formData.products" :key="index">
                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                    <label>Marca:</label>
                                    <Multiselect disabled v-model="data.brand_id" :options="selectBrandList"
                                                 :searchable="true" :noOptionsText="noOptionsText"
                                                 :noResultsText="noResultsText"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                    <label>Categoria:</label>
                                    <Multiselect disabled v-model="data.categorie_id" :options="selectCategoryBrandList"
                                                 :searchable="true" :noOptionsText="noOptionsText"
                                                 :noResultsText="noResultsText"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                    <label>Produto:</label>
                                    <Multiselect disabled label="name" v-model="data.product_id"
                                                 :options="selectProductBrandList" :searchable="true"
                                                 :noOptionsText="'Selecione uma marca'" :noResultsText="noResultsText"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                    <label>Quantidade:</label>
                                    <input-component disabled :type-input="'text'" v-model="data.amount"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                    <label>Preço UN:</label>
                                    <input-component disabled v-money="money" :type-input="'text'"
                                                     v-model="data.unit_price"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                    <label>Preço Total:</label>
                                    <input-component disabled :type-input="'text'" v-model="data.total_price"/>
                                </row-col-component>
                            </row-component>
                        </div>

                        <div class="card p-3 bg-light my-3" v-for="(data, index) in formData.services" :key="index">
                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                    <label>Serviço:</label>
                                    <Multiselect disabled v-model="data.service_id" :options="selectServiceList"
                                                 :searchable="true" :noOptionsText="noOptionsText"
                                                 :noResultsText="noResultsText"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                    <label>Quantidade:</label>
                                    <input-component disabled :type-input="'text'" v-model="data.amount"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                    <label>Preço UN:</label>
                                    <input-component disabled v-money="money" :type-input="'text'"
                                                     v-model="data.unit_price"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                    <label>Preço Total:</label>
                                    <input-component disabled :type-input="'text'" v-model="data.total_price"/>
                                </row-col-component>
                            </row-component>
                        </div>

                        <div style="background: #c9c9c9" class="p-2 my-3 text-end fw-bold">
                            Total: R$ {{ this.totalResult }}
                        </div>

                        <div class="card p-3 bg-light my-3" v-for="(data, index) in formData.payment_methods"
                             :key="index">
                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Forma de pagamento:</label>
                                    <Multiselect disabled v-model="data.form_of_payment"
                                                 :options="selectBudgetPaymentList.paymentForms" :searchable="true"
                                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Quantidade:</label>
                                    <Multiselect disabled v-model="data.portion"
                                                 :options="selectBudgetPaymentList.portion" :searchable="true"
                                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Total:</label>
                                    <input-component disabled :type-input="'text'" v-model="data.price"
                                                     v-money="money"/>
                                </row-col-component>
                            </row-component>
                        </div>
                    </form>

                    <row-component class="mt-5">
                        <row-col-component
                                :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                            <button-route :items="buttonRoute" style="width: 100%;"/>
                        </row-col-component>
                    </row-component>
                </section>

                <div class="special" id="tab-item-2">
                    <div class="alert alert-secondary alert-dismissible fade show"
                         v-if="formData.events.length === 0">
                        Não há nenhuma entrega cadastrada
                    </div>

                    <form v-else>
                        <row-component>
                            <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <input disabled v-model="delivery.delivery_type" type="radio"
                                       name="docs" id="current-address" value="1">
                                <label for="current-address">Endereço de Cadastro</label>

                                <input disabled v-model="delivery.delivery_type" type="radio"
                                       name="docs" id="new-address" value="2">
                                <label for="new-address">Endereço Novo</label>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                <label>CEP:</label>
                                <input-component disabled v-mask="'#####-###'" v-model="delivery.cep"
                                                 type-input="text"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                <label>Cidade:</label>
                                <input-component disabled v-model="delivery.city" type-input="text"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                <label>Estado:</label>
                                <input-component disabled v-model="delivery.state" type-input="text"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                <label>Bairro:</label>
                                <input-component disabled v-model="delivery.district"
                                                 type-input="text"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                                <label>Endereço:</label>
                                <input-component disabled v-model="delivery.address" type-input="text"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                <label>País:</label>
                                <input-component disabled v-model="delivery.country" type-input="text"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <label>Referência:</label>
                                <input-text-area disabled v-model="delivery.complement" :rows="4"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <label>Agenda:</label>
                                <input-component disabled type-input="text" v-model="delivery.schedule_name"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <label>Cor:</label>
                                <Multiselect disabled v-model="delivery.color"
                                             :options="selectScheduleColor" :searchable="true"
                                             :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                <label>Data Início:</label>
                                <input-component disabled v-mask="'##/##/####'"
                                                 v-model="delivery.date_start" type-input="text"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                <label>Hora Início:</label>
                                <input-component disabled v-mask="'##:##'" v-model="delivery.time_start"
                                                 type-input="text"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                <label>Data Final:</label>
                                <input-component disabled v-mask="'##/##/####'"
                                                 v-model="delivery.date_end" type-input="text"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                <label>Hora Final:</label>
                                <input-component disabled v-mask="'##:##'" v-model="delivery.time_end"
                                                 type-input="text"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <label>Descrição:</label>
                                <input-text-area disabled v-model="delivery.desc" :rows="6"/>
                            </row-col-component>
                        </row-component>
                    </form>

                    <div v-if="formData.events.length > 0">
                        <FullCalendar ref="fullCalendar" :options="calendarOptions"/>
                    </div>
                </div>

                <section id="tab-item-3">
                    <div class="alert alert-secondary alert-dismissible fade show"
                         v-if="!formData.guarantees">
                        Não há nada cadastrado
                    </div>

                    <form v-else>
                        <row-component>
                            <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                <label>Produto:</label>
                                <input-component disabled v-model="guarantee.formData.product" type-input="text"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                <label>Serviço:</label>
                                <input-component disabled v-model="guarantee.formData.service" type-input="text"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                <label>Data de início:</label>
                                <input-component disabled v-model="guarantee.formData.date_start" v-mask="'##/##/####'"
                                                 type-input="text"/>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>

                <section id="tab-item-4">
                    <div class="line-division mt-4">
                        <h1 class="h1-text"><i class="ti ti-brand-line"></i> Observações Internas</h1>
                    </div>

                    <div class="alert alert-secondary alert-dismissible fade show"
                         v-if="formData.obsPrivate.length === 0">
                        Não há nada cadastrado
                    </div>

                    <div v-else>
                        <div class="card p-3 bg-light my-3 overflow-auto" v-for="(data, index) in formData.obsPrivate"
                             :key="index">
                            <div>
                                <img v-if="data.user_photo" class="avatar__image mx-1" :src="data.user_photo" alt="">
                                <img v-else class="avatar__image mx-1" src="@/assets/images/avatar.png" alt="">
                                {{ data.user_name }}

                                <p class="d-inline-block mx-3">
                                    <i class="ti ti-calendar-due"></i> {{ data.date_create }}
                                </p>
                                <p class="d-inline-block mx-3">
                                    <i class="ti ti-clock-filled"></i> {{ data.hour_create }}
                                </p>
                            </div>
                            <p>{{ data.obs }}</p>
                        </div>
                    </div>

                    <label>Observações:</label>
                    <QuillEditor theme="bubble" read-only :contentType="'html'" ref="myEditor"
                                 v-model:content="formData.obs" style="min-height: 250px; border: 1px solid #dddddd"/>
                </section>

                <section id="tab-item-5">
                    <div class="line-division mt-4">
                        <h1 class="h1-text"><i class="ti ti-clock"></i> Histórico de interações</h1>
                    </div>

                    <div class="alert alert-secondary alert-dismissible fade show"
                         v-if="formData.historic.length === 0">
                        Não há nenhum histórico
                    </div>

                    <div v-else>
                        <div class="card p-3 bg-light my-3 overflow-auto" v-for="(data, index) in formData.historic"
                             :key="index">
                            <div>
                                <img v-if="data.user_photo" class="avatar__image mx-1" :src="data.user_photo" alt="">
                                <img v-else class="avatar__image mx-1" src="@/assets/images/avatar.png" alt="">
                                {{ data.name_user }}

                                <p class="d-inline-block mx-3">
                                    <i class="ti ti-calendar-due"></i> {{ data.date_create }}
                                </p>
                                <p class="d-inline-block mx-3">
                                    <i class="ti ti-clock-filled"></i> {{ data.hour_create }}
                                </p>
                            </div>
                            <p>{{ statusHistoric(data.status) }},
                                Plataforma: {{ data.platform }},
                                Navegador: {{ data.browser }},
                                Endereço IP: {{ data.ip }}</p>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from '@/components/row/RowColComponent';
import InputComponent from '@/components/form/InputComponent';
import Multiselect from '@vueform/multiselect';
import ButtonRoute from '@/components/buttons/ButtonRoute';
import InputTextArea from '@/components/form/InputTextArea';
import {mask} from "vue-the-mask";
import {MONEY} from "@/configs/constants";
import {VMoney} from "v-money";
import {useToast} from "vue-toastification";
import {QuillEditor} from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.bubble.css';
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import ptLocale from "@fullcalendar/core/locales/pt";

const toast = useToast()
export default {
    name: 'BudgetShow',

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Orçamento')
        this.$store.dispatch('loadProductMultiSelect')
        this.$store.dispatch('loadServiceMultiSelect')
        this.$store.dispatch('loadClientMultiSelect')
        this.$store.dispatch('loadBrandMultiSelect')
        this.$store.dispatch('loadBudgetTemplateMultiSelect')
        this.$store.dispatch('loadBudgetListPayment')
        this.$store.dispatch('loadCategoryMultiSelect')
        this.checkACL()
        this.loadBudget()
    },

    components: {
        FullCalendar,
        QuillEditor,
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        Multiselect,
        ButtonRoute,
        InputTextArea,
    },

    directives: {
        money: VMoney,
        mask
    },

    data() {
        return {
            itensOne: {
                title: "Ver",
                icon: "ti-eye"
            },

            buttonRoute: {
                icon: 'ti-arrow-back-up',
                route: '/budget/search',
                name: 'Voltar'
            },

            status: '',

            copy: true,

            formData: {
                name: '',
                status: '',
                client_id: '',
                client_name: '',
                responsible: '',
                email: '',
                phone: '',
                obs: '',
                valid: 15,
                show_value: 1,
                link_budget: '',
                link_budget_pdf: '',
                obsPrivate: '',
                historic: [],
                products: [],
                services: [],
                guarantees: '',
                events: [],
                budget_delivery: '',
                link_guarantees: '',
                payment_methods: [],
            },

            guarantee: {
                show: false,
                formData: {
                    budget_id: this.id,
                    product: "",
                    service: "",
                    date_start: ""
                }
            },

            delivery: {
                budget_id: "",
                schedule_id: "",
                date_start: "",
                time_start: "",
                date_end: "",
                time_end: "",
                color: "",
                delivery_type: "",
                schedule_name: "",
                cep: "",
                city: "",
                state: "",
                district: "",
                address: "",
                complement: "",
                country: ""
            },

            calendarOptions: {
                plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
                initialView: 'dayGridMonth',
                dateClick: this.handleDateClick,
                eventClick: this.eventClick,
                locale: ptLocale,
                themeSystem: 'standard',
                selectable: true,
                eventDisplay: 'block',
                events: [],
                height: 'auto',
                headerToolbar: {
                    left: 'title',
                    right: 'dayGridMonth,dayGridWeek,listDay, prev,next'
                },
                weekends: true,
                customButtons: {
                    prev: {
                        click: () => {
                            let calendarApi = this.$refs.fullCalendar.getApi();
                            calendarApi.prev();
                            this.loadEventsMonth(calendarApi.getDate().getMonth() + 1)
                        }
                    },
                    next: {
                        click: () => {
                            let calendarApi = this.$refs.fullCalendar.getApi();
                            calendarApi.next();
                            this.loadEventsMonth(calendarApi.getDate().getMonth() + 1)
                        }
                    }
                },
            },

            money: MONEY,
            totalResult: 0,

            showView: false,
        }
    },

    methods: {
        loadBudget() {
            this.$store.dispatch('loadBudget', this.id)
                .then((r) => {
                    this.formData = r
                    this.loadFakesFields(r.products)
                    this.statusString()
                    this.calcTotal()
                    this.calendarOptions.events = r.events
                    this.delivery = this.formData.budget_delivery
                    if (this.formData.guarantees) {
                        this.guarantee.formData = this.formData.guarantees
                    }
                }).catch(() => {
                toast.error('Erro desconhecido', {timeout: 2000})
            })
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 13)
                .then((r) => {
                    if (r.data.indexOf('budget-search') !== -1) {
                        this.showView = true

                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        scrollToTop() {
            window.scrollTo(0, 0);
        },

        eventClick: function () {
            this.loadBudget()
        },

        loadEventsMonth(month) {
            let params = {id: this.formData.client_id, month: month}
            this.$store.dispatch('loadBudgetScheduleEventsMonth', params)
                .then(r => this.calendarOptions.events = r);
        },

        copyUrl(url) {
            navigator.clipboard.writeText(url)
            this.$refs.select.focus()
            this.copy = false
            setTimeout(() => {
                this.copy = true
            }, 2000)
        },

        statusHistoric(value) {
            if (parseInt(value) === 1)
                return 'Cadastrado'

            if (parseInt(value) === 2)
                return 'Enviado para cliente'

            if (parseInt(value) === 3)
                return 'Aprovado Bene Piscinas'

            if (parseInt(value) === 4)
                return 'Aprovado Pelo Cliente'

            if (parseInt(value) === 5)
                return 'Cancelado'

            if (parseInt(value) === 6)
                return 'Enviado para entrega'

            if (parseInt(value) === 7)
                return 'Finalizado'

            if (parseInt(value) === 8)
                return 'Adicionado garantia'

            if (parseInt(value) === 9)
                return 'Atualizou a garantia'
        },

        statusString() {
            if (parseInt(this.formData.status) === 1)
                this.status = 'Cadastro'

            if (parseInt(this.formData.status) === 2)
                this.status = 'Enviado para cliente'

            if (parseInt(this.formData.status) === 3)
                this.status = 'Aprovado pelo cliente'

            if (parseInt(this.formData.status) === 5)
                this.status = 'Cancelado'

            if (parseInt(this.formData.status) === 6)
                this.status = 'Finalizado'
        },

        loadFakesFields(products) {
            products.forEach((element) => {
                let formData = {
                    brand: element.brand_id,
                    category: element.categorie_id
                }
                this.$store.dispatch('loadProductBrandMultiSelect', formData)
                this.$store.dispatch('loadCategoryBrandList', formData.brand)
            })
        },

        replaceStringOne(label) {
            if (label.length < 8) {
                return parseFloat(label.replace(',', '.'))
            } else {
                return parseFloat(label.replace(',', ''))
            }
        },

        calcTotal() {
            let sum = 0
            let price_service = 0
            let price_product = 0
            let products = this.formData.products
            let services = this.formData.services
            if (this.totalResult > 0)
                sum = this.replaceStringOne(this.totalResult)

            products.forEach((item) => {
                price_product += this.replaceStringOne(item.total_price.replace(/[.,]/g, '')) / 100
            })
            services.forEach((item) => {
                price_service += this.replaceStringOne(item.total_price.replace(/[.,]/g, '')) / 100
            })
            if (price_service > 0 && price_product > 0) {
                sum = price_service + price_product
            } else if (price_service > 0 && price_product < 0) {
                sum = price_service
            } else {
                sum = price_product
            }

            this.totalResult = sum.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2});
        },
    },

    computed: {
        me() {
            return this.$store.state.auth.me
        },

        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        selectProductBrandList() {
            return this.$store.state.var.selectProductBrandList
        },

        selectBrandList() {
            return this.$store.state.var.selectBrandList
        },

        selectCategoryBrandList() {
            return this.$store.state.var.selectCategoryBrandList
        },

        selectServiceList() {
            return this.$store.state.var.selectServiceList
        },

        selectBudgetPaymentList() {
            return this.$store.state.var.selectBudgetPaymentList
        },

        selectScheduleColor() {
            return this.$store.state.var.selectScheduleColor
        },
    }
}
</script>

<style scoped>
.avatar__image {
    width: 25px;
}

.line-division {
    width: 100%;
    border-top: 1px solid #c4c4c4;
}

.h1-text {
    margin-top: 1px;
    width: fit-content;
    padding: 10px;
    background-color: #36c1bd;
    color: white;
    font-size: 16px;
}
</style>
