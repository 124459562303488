import AdminComponent from "@/components/dashboard/AdminComponent";

import ReportBudget from "@/views/create/ReportBudget";

export default {
    path: "/report-budget",
    meta: {auth: true},
    component: AdminComponent,
    children: [
        {path: "budget", name: "report-budget", component: ReportBudget},
    ]
}