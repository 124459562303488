<template>
    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>

        <form v-if="showView">
            <row-component>
                <row-col-component :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError' : errors.name}">
                    <label>Nome:</label>
                    <input-component :type-input="'text'" v-model="formData.name"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError' : errors.balance}">
                    <label>Saldo:</label>
                    <input-component v-money="money" :type-input="'text'" v-model="formData.balance"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError' : errors.status}">
                    <label>Status:</label>
                    <Multiselect v-model="formData.status" :options="selectActiveInative" :searchable="true"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError' : errors.bank}">
                    <label>Banco:</label>
                    <Multiselect v-model="formData.bank" :options="selectBankList" :searchable="true"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError' : errors.account_type}">
                    <label>Tipo de Conta:</label>
                    <Multiselect v-model="formData.account_type" :options="selectAccountTypeList" :searchable="true"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError' : errors.agency}">
                    <label>Agência:</label>
                    <input-component :type-input="'text'" v-model="formData.agency"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError' : errors.digit_account}">
                    <label>Conta/Dígito:</label>
                    <input-component :type-input="'text'" v-model="formData.digit_account"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component
                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                        :class="{'formError' : errors.pix_type}">
                    <label>Tipo de PIX:</label>
                    <Multiselect v-model="formData.pix_type" :options="selectPIXList"
                                 :searchable="true"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component v-if="formData.pix_type === '1'"
                                   :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError' : errors.key_pix}">
                    <label>Chave:</label>
                    <input-component v-mask="['###.###.###-##','##.###.###/####-##']" :type-input="'text'"
                                     v-model="formData.key_pix"/>
                </row-col-component>

                <row-col-component v-else-if="formData.pix_type === '2'"
                                   :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError' : errors.key_pix}">
                    <label>Chave:</label>
                    <input-component v-mask="['(##) ####-####','(##) #####-####']" :type-input="'text'"
                                     v-model="formData.key_pix"/>
                </row-col-component>

                <row-col-component v-else-if="formData.pix_type === '3'"
                                   :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError' : errors.key_pix}">
                    <label>Chave:</label>
                    <input-component :type-input="'email'" v-model="formData.key_pix"/>
                </row-col-component>

                <row-col-component v-else-if="formData.pix_type === '4'"
                                   :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError' : errors.key_pix}">
                    <label>Chave:</label>
                    <input-component :type-input="'text'" v-model="formData.key_pix"/>
                </row-col-component>

                <row-col-component v-else
                                   :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError' : errors.key_pix}">
                    <label>Chave:</label>
                    <input-component :type-input="'text'" v-model="formData.key_pix"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError' : errors.obs}">
                    <label>Observações:</label>
                    <input-text-area :rows="4" v-model="formData.obs"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component
                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mt-4'">
                    <label>Conta principal?</label>

                    <input checked v-model="formData.main_account" type="radio" name="docs" id="main-account-no"
                           value="1">
                    <label for="main-account-no">Não</label>

                    <input v-model="formData.main_account" type="radio" name="docs" id="main-account-yes" value="2">
                    <label for="main-account-yes">Sim</label>
                </row-col-component>

                <row-col-component
                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mt-4'">
                    <label>Permitir saldo negativo?</label>

                    <input checked v-model="formData.allow_negative_balance" type="radio" name="docs-two"
                           id="allow-negative-no"
                           value="1">
                    <label for="allow-negative-no">Não</label>

                    <input v-model="formData.allow_negative_balance" type="radio" name="docs-two"
                           id="allow-negative-yes"
                           value="2">
                    <label for="allow-negative-yes">Sim</label>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-submit :disaabled="sendForm" @submit="submit" :items="buttonSubmit" style="width: 100%;"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-route :items="buttonRoute" style="width: 100%;"/>
                </row-col-component>
            </row-component>
        </form>

    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from '@/components/row/RowColComponent';
import InputComponent from '@/components/form/InputComponent';
import Multiselect from '@vueform/multiselect';
import ButtonSubmit from '@/components/buttons/ButtonSubmit';
import ButtonRoute from '@/components/buttons/ButtonRoute';
import InputTextArea from '@/components/form/InputTextArea';
import ErrorComponent from "@/components/alerts/ErrorComponent";
import {MONEY} from "@/configs/constants";
import {useToast} from "vue-toastification";
import {VMoney} from "v-money";
import {mask} from "vue-the-mask";

const toast = useToast()
export default {
    name: 'AccountBankCreate',

    created() {
        this.$store.dispatch('updateNamePage', 'Conta Bancária')
        this.checkACL()
    },

    components: {
        ErrorComponent,
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        Multiselect,
        ButtonSubmit,
        ButtonRoute,
        InputTextArea
    },

    directives: {
        mask,
        money: VMoney
    },

    data() {
        return {
            itensOne: {
                title: "Adicionar",
                icon: "ti-pencil-plus"
            },

            buttonSubmit: {
                icon: 'ti-circle-plus',
                nameButton: 'Adicionar',
                typeButton: 'button'
            },

            buttonRoute: {
                icon: 'ti-arrow-back-up',
                route: '/account-bank/search',
                name: 'Voltar'
            },

            formData: {
                name: '',
                bank: '',
                balance: '',
                account_type: '',
                agency: '',
                digit_account: '',
                pix_type: '',
                key_pix: '',
                allow_negative_balance: 1,
                main_account: 1,
                status: '',
                obs: ''
            },

            money: MONEY,

            errors: [],
            showView: false,
            sendForm: false,
            showError: false,
        }
    },

    methods: {

        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('storeAccountBank', this.formData)
                    .then((r) => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success("Cadastro efetuado com sucesso", {timeout: 2000});
                        this.$router.push({name: 'account-bank-edit', params: {id: r.data}})
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollToTop()
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        scrollToTop() {
            window.scrollTo(0, 0);
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 15)
                .then((r) => {
                    if (r.data.indexOf('account-bank-create') !== -1) {
                        this.showView = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

    },

    computed: {
        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        selectAccountTypeList() {
            return this.$store.state.var.selectAccountTypeList
        },

        selectBankList() {
            return this.$store.state.var.selectBankList
        },

        selectPIXList() {
            return this.$store.state.var.selectPIXList
        }
    }

}
</script>