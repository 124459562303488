import {createStore} from 'vuex'

import AccountBank from "@/store/modules/account-bank";
import AccountType from "@/store/modules/account-type";
import Auth from "@/store/modules/auth";
import Brand from "@/store/modules/brand";
import Budget from "@/store/modules/budget"
import BudgetDelivery from "@/store/modules/budget-delivery";
import BudgetTemplate from "@/store/modules/budget-template";
import Category from "@/store/modules/category";
import Client from "@/store/modules/client";
import Employee from "@/store/modules/employee";
import EntranceExit from "@/store/modules/entrance-exit";
import EntranceExitPreview from "@/store/modules/entrance-exit-preview";
import ExternalApi from "@/store/modules/external-api";
import Fleet from "@/store/modules/fleet";
import GuestSchedule from "@/store/modules/guest-schedule";
import Home from "@/store/modules/home"
import Inventory from "@/store/modules/inventory";
import UserGroup from "@/store/modules/user-group";
import Loader from "@/store/modules/loader";
import MySchedule from "@/store/modules/my-schedule";
import Page from "@/store/modules/page";
import Product from "@/store/modules/product";
import Provider from "@/store/modules/provider";
import Receipt from "@/store/modules/receipt";
import Schedule from "@/store/modules/schedule";
import Service from "@/store/modules/service";
import User from "@/store/modules/user";
import Var from '@/store/modules/var'
import NameBudget from "@/store/modules/name-budget";

export default createStore({
    modules: {
        account_bank: AccountBank,
        account_type: AccountType,
        auth: Auth,
        brand: Brand,
        budget: Budget,
        budget_delivery: BudgetDelivery,
        budget_template: BudgetTemplate,
        category: Category,
        client: Client,
        employee: Employee,
        entrance_exit: EntranceExit,
        entrance_exit_preview: EntranceExitPreview,
        external_api: ExternalApi,
        fleet: Fleet,
        guest_schedule: GuestSchedule,
        home: Home,
        inventory: Inventory,
        user_group: UserGroup,
        loader: Loader,
        my_schedule: MySchedule,
        page: Page,
        product: Product,
        provider: Provider,
        receipt: Receipt,
        schedule: Schedule,
        service: Service,
        user: User,
        var: Var,
        name_budget:NameBudget
    }
})
