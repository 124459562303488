<template>
    <painel-component :itens="itensOne">

        <form v-if="showView">
            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <label>Nome:</label>
                    <input-component disabled :type-input="'text'" v-model="formData.name"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <label>Status:</label>
                    <Multiselect disabled v-model="formData.status" :options="selectActiveInative" :searchable="true"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                   v-for="(permission, index) in selectPage" :key="index">
                    <div class="form-check checkBoxHeader">
                        <input-check-box disabled class="form-check-input" type="checkbox" :value="index"
                                         :id="'checkBoxMaster' + index" :name="index"/>
                        <label class="form-check-label">{{ permission.label }}</label>
                    </div>

                    <div class="form-check " v-for="(option, indexTwo) in permission.options" :key="indexTwo">
                        <input-check-box disabled type="checkbox" :value="option.value" :id="'checkOne' + option.value"
                                         :class="'form-check-input checkBoxAll' + index"/>
                        <label class="form-check-label">{{ option.label }}</label>
                    </div>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <button-route :items="buttonRoute" style="width: 100%;"/>
                </row-col-component>
            </row-component>
        </form>

    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from '@/components/row/RowColComponent';
import InputComponent from '@/components/form/InputComponent';
import Multiselect from '@vueform/multiselect';
import ButtonRoute from '@/components/buttons/ButtonRoute';
import InputCheckBox from '@/components/form/InputCheckBox';
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: 'UserGroupShow',

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Grupo de Usuário')
        this.$store.dispatch('loadPage')
        this.checkACL()
        this.loadGroupUser()
    },

    components: {
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        Multiselect,
        ButtonRoute,
        InputCheckBox
    },

    data() {
        return {
            itensOne: {
                title: "Ver",
                icon: "ti-eye"
            },

            buttonRoute: {
                icon: 'ti-arrow-back-up',
                route: '/user-group/search',
                name: 'Voltar'
            },

            formData: {
                name: '',
                status: '',
                permissions: []
            },

            showView: false

        }
    },

    methods: {

        loadGroupUser() {
            this.$store.dispatch('loadGroupUser', this.id)
                .then((r) => {
                    this.formData = r
                    setTimeout(() => {
                        this.checkBoxCheckedStart()
                    }, 1000)
                }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 2000});
            })
        },

        checkBoxCheckedStart() {
            let totalpage = 0;
            let cont = 0;
            let i = 0;
            let permissionsList = this.formData.permissions;
            this.selectPage.forEach(function (element) {
                cont = 0;
                totalpage = element.options.length;

                element.options.forEach(function (item) {
                    if (permissionsList.includes(item.value)) {
                        document.getElementById("checkOne" + item.value).checked = true
                        cont = cont + 1;
                    }
                });
                if (cont >= totalpage) {
                    document.getElementById("checkBoxMaster" + i).checked = true
                }
                i = i + 1;
            });
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 1)
                .then((r) => {
                    if (r.data.indexOf('group-user-search') !== -1) {
                        this.showView = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

    },

    computed: {
        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        selectPage() {
            return this.$store.state.page.items
        }

    }

}
</script>