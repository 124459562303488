<template>
    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>
        <div class="clearfix mb-4" v-if="buttonAddRoute">
            <button-route class="float-end" :items="buttonRouteAdd"/>
        </div>

        <div class="tabs effect-3" v-if="showView">
            <input checked type="radio" id="tab-1" name="tab-effect-3">
            <span>
                <i class="ti ti-info-square-rounded"></i>
                <p>Informações</p>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3">
            <span>
                <i class="ti ti-mail"></i>
                <p>Email</p>
            </span>

            <input type="radio" id="tab-3" name="tab-effect-3">
            <span>
                <i class="ti ti-phone-filled"></i>
                <p>Telefone</p>
            </span>

            <input type="radio" id="tab-4" name="tab-effect-3">
            <span>
                <i class="ti ti-building-bank"></i>
                <p>Conta Bancária</p>
            </span>
            <div class="line ease"></div>
            <div class="tab-content">

                <section id="tab-item-1">
                    <form>
                        <div class="aux my-4">
                            <div class="avatar-form">
                                <img v-if="formData.photo" :src="formData.photo" alt="" class="avatar__image">
                                <img v-else src="@/assets/images/avatar.png" alt="" class="avatar__image">
                            </div>
                            <p>{{ formData.name }}</p>
                            <router-link :to="{ name: 'employee-edit-photo', id: this.id }" class="figure">
                                <i class="ti ti-camera"></i>
                            </router-link>
                        </div>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                <input checked v-model="formData.type" type="radio" name="docs" id="funcionario"
                                       value="1">
                                <label for="funcionario">Funcionário</label>

                                <input v-model="formData.type" type="radio" name="docs" id="prestador-serv" value="2">
                                <label for="prestador-serv">Prestador de serviço</label>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                                    :class="{'formError' : errors.name}">
                                <label>Nome:</label>
                                <input-component :type-input="'text'" v-model="formData.name"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                    :class="{'formError' : errors.sexo}">
                                <label>Sexo:</label>
                                <Multiselect v-model="formData.sexo" :options="selectSexoList"
                                             :searchable="true"
                                             :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                    :class="{'formError' : errors.cpf}">
                                <label>CPF:</label>
                                <input-component disabled v-mask="'###.###.###-##'" :type-input="'text'"
                                                 v-model="formData.cpf"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                    :class="{'formError' : errors.rg}">
                                <label>RG:</label>
                                <input-component v-mask="['##.###.###-X', '#.###.###']" :type-input="'text'"
                                                 v-model="formData.rg"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                    :class="{'formError' : errors.birth_date}">
                                <label>Data Nasc:</label>
                                <input-component v-mask="'##/##/####'" :type-input="'text'"
                                                 v-model="formData.birth_date"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                    :class="{'formError' : errors.status}">
                                <label>Status:</label>
                                <Multiselect v-model="formData.status" :options="selectActiveInative"
                                             :searchable="true"
                                             :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                    :class="{'formError' : errors.cep}">
                                <label>CEP:</label>
                                <input-component v-mask="'#####-###'" @change="searchApiCEP" :type-input="'text'"
                                                 v-model="formData.cep"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                    :class="{'formError' : errors.city}">
                                <label>Cidade:</label>
                                <input-component :type-input="'text'" v-model="formData.city"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                    :class="{'formError' : errors.state}">
                                <label>Estado:</label>
                                <input-component :type-input="'text'" v-model="formData.state"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                    :class="{'formError' : errors.district}">
                                <label>Bairro:</label>
                                <input-component :type-input="'text'" v-model="formData.district"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12'"
                                    :class="{'formError' : errors.address}">
                                <label>Endereço:</label>
                                <input-component :type-input="'text'" v-model="formData.address"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                    :class="{'formError' : errors.country}">
                                <label>País:</label>
                                <input-component :type-input="'text'" v-model="formData.country"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                    :class="{'formError' : errors.complement}">
                                <label>Referência:</label>
                                <input-text-area :rows="4" v-model="formData.complement"/>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>

                <section id="tab-item-2">
                    <div class="clearfix">
                        <button-submit @click="addEmail" class="fs-5 float-end" :items="buttonAdd"/>
                    </div>
                    <form v-for="(data, index) in emailsAdd" :key="index" class="card p-3 bg-light my-3">
                        <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                            <label>Receber Notificação:</label>
                            <input type="radio" name="not-email" :id="'nao-email' + index" v-model="data.notification"
                                   value="1">
                            <label :for="'nao-email' + index">Não</label>

                            <input type="radio" name="not-email" :id="'sim-email' + index" v-model="data.notification"
                                   value="2">
                            <label :for="'sim-email' + index">Sim</label>
                        </row-col-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                                <label>Nome:</label>
                                <input-component :type-input="'text'" v-model="data.name"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                <label>Email:</label>
                                <input-component :type-input="'email'" v-model="data.email"/>
                            </row-col-component>
                        </row-component>

                        <div class="clearfix" v-if="formData.emails.length >= 2">
                            <button-delete @click="emailsRemove(index)" class="fs-5 float-end"/>
                        </div>
                    </form>
                </section>

                <section id="tab-item-3">
                    <div class="clearfix">
                        <button-submit @click="addPhones" class="fs-5 float-end" :items="buttonAdd"/>
                    </div>
                    <form v-for="(data, index) in phonesAdd" :key="index" class="card p-3 bg-light my-3">
                        <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                            <label>Receber Notificação:</label>
                            <input type="radio" name="not-phone" :id="'nao-phone' + index" v-model="data.notification"
                                   value="1">
                            <label :for="'nao-phone' + index">Não</label>

                            <input type="radio" name="not-phone" :id="'sim-phone' + index" v-model="data.notification"
                                   value="2">
                            <label :for="'sim-phone' + index">Sim</label>
                        </row-col-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                                <label>Nome:</label>
                                <input-component :type-input="'text'" v-model="data.name"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                <label>Telefone:</label>
                                <input-component v-mask="['(##) ####-####', '(##) #####-####']" :type-input="'text'"
                                                 v-model="data.phone"/>
                            </row-col-component>
                        </row-component>

                        <div class="clearfix" v-if="formData.phones.length >= 2">
                            <button-delete @click="phonesRemove(index)" class="fs-5 float-end"/>
                        </div>
                    </form>
                </section>

                <section id="tab-item-4">
                    <div class="clearfix">
                        <button-submit @click="addBankAccount" class="fs-5 float-end" :items="buttonAdd"/>
                    </div>
                    <form v-for="(data, index) in bankAccountAdd" :key="index" class="card p-3 bg-light my-3">
                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                <label>Banco:</label>
                                <Multiselect v-model="data.bank" :options="selectBankList"
                                             :searchable="true"
                                             :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                <label>Tipo de Conta:</label>
                                <Multiselect v-model="data.account_type" :options="selectAccountTypeList"
                                             :searchable="true"
                                             :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                <label>Agência:</label>
                                <input-component :type-input="'text'" v-model="data.agency"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                <label>Conta / Dígito:</label>
                                <input-component :type-input="'text'" v-model="data.digit_account"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                <label>Tipo de PIX:</label>
                                <Multiselect v-model="data.pix_type" :options="selectPIXList"
                                             :searchable="true"
                                             :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                            </row-col-component>

                            <row-col-component v-if="data.pix_type === '1'"
                                               :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                <label>Chave:</label>
                                <input-component v-mask="['###.###.###-##','##.###.###/####-##']" :type-input="'text'"
                                                 v-model="data.key_pix"/>
                            </row-col-component>

                            <row-col-component v-else-if="data.pix_type === '2'"
                                               :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                <label>Chave:</label>
                                <input-component v-mask="['(##) ####-####','(##) #####-####']" :type-input="'text'"
                                                 v-model="data.key_pix"/>
                            </row-col-component>

                            <row-col-component v-else-if="data.pix_type === '3'"
                                               :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                <label>Chave:</label>
                                <input-component :type-input="'email'" v-model="data.key_pix"/>
                            </row-col-component>

                            <row-col-component v-else-if="data.pix_type === '4'"
                                               :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                <label>Chave:</label>
                                <input-component :type-input="'text'" v-model="data.key_pix"/>
                            </row-col-component>

                            <row-col-component v-else
                                               :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                <label>Chave:</label>
                                <input-component :type-input="'text'" v-model="data.key_pix"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mt-4'">
                                <label>Conta principal?</label>

                                <input v-model="data.main_account" type="radio" name="docs" id="sim" value="1">
                                <label for="sim">Sim</label>

                                <input v-model="data.main_account" type="radio" name="docs" id="nao" value="2">
                                <label for="nao">Não</label>
                            </row-col-component>
                        </row-component>

                        <div class="clearfix" v-if="formData.bank_accounts.length >= 2">
                            <button-delete @click="bankAccountRemove(index)" class="fs-5 float-end"/>
                        </div>
                    </form>
                </section>
            </div>
        </div>

        <row-component>
            <row-col-component
                    :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <button-submit :disaabled="sendForm" @submit="submit" :items="buttonSubmit" style="width: 100%;"/>
            </row-col-component>
            <row-col-component
                    :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <button-route :items="buttonRoute" style="width: 100%;"/>
            </row-col-component>
        </row-component>

    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from '@/components/row/RowColComponent';
import InputComponent from '@/components/form/InputComponent';
import Multiselect from '@vueform/multiselect';
import ButtonSubmit from '@/components/buttons/ButtonSubmit';
import ButtonRoute from '@/components/buttons/ButtonRoute';
import InputTextArea from '@/components/form/InputTextArea';
import {ESTADOS} from "@/configs/constants";
import {useToast} from "vue-toastification";
import {mask} from "vue-the-mask";
import ButtonDelete from "@/components/buttons/ButtonDelete";
import ErrorComponent from "@/components/alerts/ErrorComponent";

const toast = useToast()
export default {
    name: 'EmployeeUpdate',

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Funcionário')
        this.checkACL()
        this.loadEmployee()
        this.nullInputArray()
    },

    components: {
        ErrorComponent,
        ButtonDelete,
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        Multiselect,
        ButtonSubmit,
        ButtonRoute,
        InputTextArea,
    },

    directives: {
        mask
    },

    data() {
        return {
            itensOne: {
                title: "Editar",
                icon: "ti-edit"
            },

            buttonSubmit: {
                icon: 'ti-device-floppy',
                nameButton: 'Salvar',
                typeButton: 'button'
            },

            buttonRoute: {
                icon: 'ti-arrow-back-up',
                route: '/employee/search',
                name: 'Voltar'
            },

            buttonAdd: {
                icon: 'ti-circle-plus',
            },

            buttonRouteAdd: {
                icon: 'ti-plus',
                route: '/employee/create',
            },

            buttonAddRoute: false,

            formData: {
                name: '',
                cpf: '',
                rg: '',
                birth_date: '',
                type: 1,
                status: '',
                sexo: '',
                emails: [],
                phones: [],
                bank_accounts: [],
                cep: '',
                city: '',
                state: '',
                district: '',
                address: '',
                complement: '',
                country: ''
            },

            picked: 2,

            errors: [],
            showView: false,
            sendForm: false,
            showError: false,

        }
    },

    methods: {
        loadEmployee() {
            this.$store.dispatch('loadEmployee', this.id)
                .then((r) => {
                    this.formData = r
                    this.nullInputArray()
                }).catch(() => {
                toast.error('Erro desconhecido', {timeout: 2000})
            })
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateEmployee', this.formData)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success("Cadastro atualizado com sucesso", {timeout: 2000});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollToTop()
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 8)
                .then((r) => {
                    if (r.data.indexOf('employee-edit') !== -1) {
                        this.showView = true

                        if (r.data.indexOf('employee-create') !== -1) {
                            this.buttonAddRoute = true
                        }

                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        scrollToTop() {
            window.scrollTo(0, 0);
        },

        nullInputArray() {
            if (this.formData.emails.length === 0)
                this.addEmail()
            if (this.formData.phones.length === 0)
                this.addPhones()
            if (this.formData.bank_accounts.length === 0)
                this.addBankAccount()
        },

        addEmail() {
            this.formData.emails.push({
                name: '',
                email: '',
                notification: '1'
            })
        },

        addPhones() {
            this.formData.phones.push({
                name: '',
                phone: '',
                notification: '1'
            })
        },

        addBankAccount() {
            this.formData.bank_accounts.push({
                bank: '',
                account_type: '',
                agency: '',
                digit_account: '',
                pix_type: '1',
                key_pix: '',
                main_account: '1'
            })
        },

        emailsRemove(index) {
            this.formData.emails.splice(index, 1)
        },

        phonesRemove(index) {
            this.formData.phones.splice(index, 1)
        },

        bankAccountRemove(index) {
            this.formData.secondary_activitys.splice(index, 1)
        },

        dateFormatBR(inputDate) {
            let dateArray = inputDate.split("-");
            return `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;
        },

        searchApiCEP() {
            this.resetFormAddress()
            let cep = this.formData.cep.replace(/\D/g, '');
            const dateCEP = {'cep': cep}
            this.$store.dispatch('searchApiCEP', dateCEP)
                .then((r) => {
                    this.formData.city = r.data.localidade,
                        this.formData.state = r.data.uf,
                        this.formData.stateView = ESTADOS[r.data.uf],
                        this.formData.district = r.data.bairro,
                        this.formData.address = r.data.logradouro,
                        this.formData.complement = r.data.complemento
                }).catch(() => {
            })
        },

        resetFormAddress() {
            this.formData.city = ''
            this.formData.state = ''
            this.formData.stateView = ''
            this.formData.district = ''
            this.formData.address = ''
            this.formData.complement = ''
            this.formData.country = 'Brasil'
        },

        resetFormData() {
            this.formData = {
                name: '',
                cpf: '',
                rg: '',
                birth_date: '',
                type: this.formData.type,
                status: '',
                sexo: '',
                emails: [],
                phones: [],
                bank_accounts: [],
                cep: '',
                city: '',
                state: '',
                district: '',
                address: '',
                complement: '',
                country: ''
            }
        },

    },

    computed: {
        me() {
            return this.$store.state.auth.me
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        selectSexoList() {
            return this.$store.state.var.selectSexoList
        },

        selectBankList() {
            return this.$store.state.var.selectBankList
        },

        selectPIXList() {
            return this.$store.state.var.selectPIXList
        },

        selectAccountTypeList() {
            return this.$store.state.var.selectAccountTypeList
        },

        emailsAdd() {
            return this.formData.emails;
        },

        phonesAdd() {
            return this.formData.phones;
        },

        bankAccountAdd() {
            return this.formData.bank_accounts;
        },

    }

}
</script>

<style scoped>
.effect-3 .line,
.tabs > input,
.tabs > span {
    width: 25%;
}

#tab-2,
#tab-2 + span,
#tab-2:checked ~ .line {
    left: 25%
}

#tab-3,
#tab-3 + span,
#tab-3:checked ~ .line {
    left: 50%;
}

#tab-4,
#tab-4 + span,
#tab-4:checked ~ .line {
    left: 75%;
}
</style>
