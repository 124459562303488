import AdminComponent from '@/components/dashboard/AdminComponent'
import BudgetDeliveryCreate from "@/views/create/BudgetDeliveryCreate";
import BudgetDeliveryUpdate from "@/views/update/BudgetDeliveryUpdate";

export default {
    path: '/budget-delivery',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'create/:id/:client', name: 'budget-delivery-create', component: BudgetDeliveryCreate, props: true},
        {path: 'edit/:id/:client/:delivery', name: 'budget-delivery-edit', component: BudgetDeliveryUpdate, props: true},
    ]
}