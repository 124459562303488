<template>
    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>

        <div class="tabs effect-3" v-if="showView">
            <input checked type="radio" id="tab-1" name="tab-effect-3">
            <span>
                <i class="ti ti-info-square-rounded"></i>
                <p>Informações</p>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3">
            <span>
                <i class="ti ti-box"></i>
                <p>Produtos</p>
            </span>

            <input type="radio" id="tab-3" name="tab-effect-3">
            <span>
                <i class="ti ti-settings"></i>
                <p>Serviços</p>
            </span>

            <input type="radio" id="tab-4" name="tab-effect-3">
            <span>
                <i class="ti ti-cash-banknote"></i>
                <p>Forma de Pagamento</p>
            </span>

            <div class="line ease"></div>
            <div class="tab-content">

                <section id="tab-item-1">
                    <form>
                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                    :class="{'formError' : errors.name}">
                                <label>Nome:</label>
                                <input-component disabled :type-input="'text'" v-model="formData.name"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                    :class="{'formError' : errors.status}">
                                <label>Status:</label>
                                <Multiselect disabled v-model="formData.status" :options="selectActiveInative"
                                             :searchable="true"
                                             :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                    :class="{'formError' : errors.obs}">
                                <label>Observações:</label>
                                <input-text-area disabled :rows="4" v-model="formData.obs"/>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>

                <section id="tab-item-2">
                    <div class="alert alert-secondary alert-dismissible fade show" v-if="productAdd.length === 0">
                        Não há nada cadastrado
                    </div>

                    <form v-else v-for="(data, index) in productAdd" :key="index" class="card p-3 bg-light my-3">
                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                <label>Produto:</label>
                                <Multiselect disabled label="name" v-model="data.product_id"
                                             :options="selectProductList"
                                             :searchable="true"
                                             :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                <label>Quantidade:</label>
                                <input-component disabled min="1" :type-input="'number'" v-model="data.amount"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                <label>Preço UN:</label>
                                <input-component disabled v-money="money" :type-input="'text'"
                                                 v-model="data.unit_price"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                <label>Preço Total:</label>
                                <input-component disabled :type-input="'text'" v-model="data.total_price"/>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>

                <section id="tab-item-3">
                    <div class="alert alert-secondary alert-dismissible fade show" v-if="serviceAdd.length === 0">
                        Não há nada cadastrado
                    </div>

                    <form v-else v-for="(data, index) in serviceAdd" :key="index" class="card p-3 bg-light my-3">
                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                <label>Serviço:</label>
                                <Multiselect disabled v-model="data.service_id" :options="selectServiceList"
                                             :searchable="true"
                                             :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                <label>Quantidade:</label>
                                <input-component disabled min="1" :type-input="'number'" v-model="data.amount"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                <label>Preço UN:</label>
                                <input-component disabled v-money="money" :type-input="'text'"
                                                 v-model="data.unit_price"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                <label>Preço Total:</label>
                                <input-component disabled :type-input="'text'" v-model="data.total_price"/>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>

                <section id="tab-item-4">
                    <QuillEditor :theme="'bubble'" :contentType="'html'" ref="myEditor"
                                 v-model:content="formData.form_of_payment"
                                 style="min-height: 150px; margin-bottom: 2%; border:1px solid #1b1c1d"
                                 :readOnly="true"/>
                </section>
            </div>
        </div>

        <row-component>
            <row-col-component
                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                <button-route :items="buttonRoute" style="width: 100%;"/>
            </row-col-component>
        </row-component>

    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from '@/components/row/RowColComponent';
import InputComponent from '@/components/form/InputComponent';
import Multiselect from '@vueform/multiselect';
import ButtonRoute from '@/components/buttons/ButtonRoute';
import InputTextArea from '@/components/form/InputTextArea';
import ErrorComponent from "@/components/alerts/ErrorComponent";
import {MONEY} from "@/configs/constants";
import {VMoney} from "v-money";
import {QuillEditor} from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.bubble.css';

import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: 'BudgetTemplateCreate',

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Modelo de Orçamento')
        this.$store.dispatch('loadProductMultiSelect')
        this.$store.dispatch('loadServiceMultiSelect')
        this.checkACL()
        this.loadBudgetTemplate()
    },

    components: {
        ErrorComponent,
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        Multiselect,
        ButtonRoute,
        InputTextArea,
        QuillEditor
    },

    directives: {
        money: VMoney
    },

    data() {
        return {
            itensOne: {
                title: "Ver",
                icon: "ti-eye"
            },

            buttonRoute: {
                icon: 'ti-arrow-back-up',
                route: '/budget-template/search',
                name: 'Voltar'
            },

            formData: {
                name: '',
                status: '',
                obs: '',
                form_of_payment: '',
                products: [],
                services: []
            },

            money: MONEY,

            errors: [],
            showView: false,
            sendForm: false,
            showError: false,
        }
    },

    methods: {

        loadBudgetTemplate() {
            this.$store.dispatch('loadBudgetTemplate', this.id)
                .then((r) => {
                    this.formData = r
                    this.$refs.myEditor.setHTML(r.form_of_payment)
                }).catch(() => {
                toast.error("Erro desconhecido!", {timeout: 2000});
            })
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 12)
                .then((r) => {
                    if (r.data.indexOf('budget-template-search') !== -1) {
                        this.showView = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        scrollToTop() {
            window.scrollTo(0, 0);
        },

    },

    computed: {

        me() {
            return this.$store.state.auth.me
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        selectProductList() {
            return this.$store.state.var.selectProductList
        },

        selectServiceList() {
            return this.$store.state.var.selectServiceList
        },

        productAdd() {
            return this.formData.products;
        },

        serviceAdd() {
            return this.formData.services;
        },

    }

}
</script>

<style scoped>
.tabs > input,
.tabs > span,
.effect-3 .line {
    width: calc(100% / 4);
}

#tab-2,
#tab-2 + span,
#tab-2:checked ~ .line {
    left: 25%
}

#tab-3,
#tab-3 + span,
#tab-3:checked ~ .line {
    left: 50%;
}

#tab-4,
#tab-4 + span,
#tab-4:checked ~ .line {
    left: 75%;
}
</style>