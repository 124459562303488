<template>
    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>

        <div class="tabs effect-3" v-if="showView">
            <input checked type="radio" id="tab-1" name="tab-effect-3" :class="{ 'mobileTab-client': tabMobile }">
            <span :class="{ 'mobileTab-client': tabMobile }">
                <i class="ti ti-info-square-rounded"></i>
                <p>Informações</p>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3" v-if="picked === 2">
            <span v-if="picked === 2">
                <i class="ti ti-user-circle"></i>
                <p>Sócio</p>
            </span>

            <input type="radio" id="tab-3" name="tab-effect-3" :class="{ 'mobileTab-client': tabMobile }">
            <span :class="{ 'mobileTab-client': tabMobile }">
                <i class="ti ti-mail"></i>
                <p>Email</p>
            </span>

            <input type="radio" id="tab-4" name="tab-effect-3" :class="{ 'mobileTab-client': tabMobile }">
            <span :class="{ 'mobileTab-client': tabMobile }">
                <i class="ti ti-phone-filled"></i>
                <p>Telefone</p>
            </span>

            <input type="radio" id="tab-5" name="tab-effect-3" v-if="picked === 2">
            <span v-if="picked === 2">
                <i class="ti ti-list-check"></i>
                <p>Atividades econômicas secundárias</p>
            </span>
            <div class="line ease" :class="{ 'line-3': tabMobile }"></div>
            <div class="tab-content">

                <section id="tab-item-1">
                    <div class="aux my-4">
                        <div class="avatar-form">
                            <img v-if="formData.photo" :src="formData.photo" alt="" class="avatar__image">
                            <img v-else src="@/assets/images/avatar.png" alt="" class="avatar__image">
                        </div>
                        <p>{{ formData.name }}</p>
                    </div>

                    <form>
                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                <input disabled v-model="formData.type" type="radio" name="docs"
                                       id="pessoa-fisica" value="1">
                                <label for="pessoa-fisica">Pessoa Física</label>

                                <input disabled v-model="formData.type" type="radio" name="docs"
                                       id="pessoa-juridica" value="2">
                                <label for="pessoa-juridica">Pessoa Jurídica</label>
                            </row-col-component>
                        </row-component>

                        <div v-if="picked === 2">
                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                        :class="{'formError' : errors.cnpj}">
                                    <label>CNPJ:</label>
                                    <input-component disabled :type-input="'text'"
                                                     v-model="formData.cnpj"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                        :class="{'formError' : errors.size}">
                                    <label>Porte:</label>
                                    <input-component disabled :type-input="'text'" v-model="formData.size"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                        :class="{'formError' : errors.status}">
                                    <label>Status:</label>
                                    <Multiselect disabled v-model="formData.status" :options="selectActiveInative"
                                                 :searchable="true"
                                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                        :class="{'formError' : errors.name}">
                                    <label>Nome:</label>
                                    <input-component disabled :type-input="'text'" v-model="formData.name"/>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                        :class="{'formError' : errors.name_f}">
                                    <label>Nome Fantasia:</label>
                                    <input-component disabled :type-input="'text'" v-model="formData.name_f"/>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                        :class="{'formError' : errors.surname}">
                                    <label>Apelido:</label>
                                    <input-component disabled :type-input="'text'" v-model="formData.surname"/>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                        :class="{'formError' : errors.legal_nature}">
                                    <label>Natureza Jurídica:</label>
                                    <input-component disabled :type-input="'text'" v-model="formData.legal_nature"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                        :class="{'formError' : errors.company_status}">
                                    <label>Status da Empresa:</label>
                                    <input-component disabled :type-input="'text'" v-model="formData.company_status"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                        :class="{'formError' : errors.share_capital}">
                                    <label>Capital Social:</label>
                                    <input-component disabled :type-input="'text'" v-model="formData.share_capital"/>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                                        :class="{'formError' : errors.main_activity}">
                                    <label>Atividade Econômica Principal:</label>
                                    <input-component disabled :type-input="'text'" v-model="formData.main_activity"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                                        :class="{'formError' : errors.date_open}">
                                    <label>Data de Abertura:</label>
                                    <input-component disabled :type-input="'text'" v-model="formData.date_open"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                                        :class="{'formError' : errors.headquarters}">
                                    <label>Matriz:</label>
                                    <input-component disabled :type-input="'text'" v-model="formData.headquarters"/>
                                </row-col-component>
                            </row-component>
                        </div>

                        <div v-else>
                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                        :class="{'formError' : errors.name}">
                                    <label>Nome:</label>
                                    <input-component disabled :type-input="'text'" v-model="formData.name"/>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                        :class="{'formError' : errors.surname}">
                                    <label>Apelido:</label>
                                    <input-component disabled :type-input="'text'" v-model="formData.surname"/>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                        :class="{'formError' : errors.cpf}">
                                    <label>CPF:</label>
                                    <input-component disabled :type-input="'text'" v-model="formData.cpf"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                        :class="{'formError' : errors.rg}">
                                    <label>RG:</label>
                                    <input-component disabled :type-input="'text'" v-model="formData.rg"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                        :class="{'formError' : errors.birth_date}">
                                    <label>Data Nasc:</label>
                                    <input-component disabled :type-input="'text'" v-model="formData.birth_date"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                        :class="{'formError' : errors.status}">
                                    <label>Status:</label>
                                    <Multiselect disabled v-model="formData.status" :options="selectActiveInative"
                                                 :searchable="true"
                                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                                </row-col-component>
                            </row-component>
                        </div>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                    :class="{'formError' : errors.cep}">
                                <label>CEP:</label>
                                <input-component disabled :type-input="'text'" v-model="formData.cep"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                    :class="{'formError' : errors.city}">
                                <label>Cidade:</label>
                                <input-component disabled :type-input="'text'" v-model="formData.city"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                    :class="{'formError' : errors.state}">
                                <label>Estado:</label>
                                <input-component disabled :type-input="'text'" v-model="formData.state"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                    :class="{'formError' : errors.district}">
                                <label>Bairro:</label>
                                <input-component disabled :type-input="'text'" v-model="formData.district"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12'"
                                    :class="{'formError' : errors.address}">
                                <label>Endereço:</label>
                                <input-component disabled :type-input="'text'" v-model="formData.address"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                    :class="{'formError' : errors.country}">
                                <label>País:</label>
                                <input-component disabled :type-input="'text'" v-model="formData.country"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                    :class="{'formError' : errors.complement}">
                                <label>Referência:</label>
                                <input-text-area disabled :rows="4" v-model="formData.complement"/>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>

                <section id="tab-item-2">
                    <div class="alert alert-secondary alert-dismissible fade show" v-if="membersAdd.length === 0">
                        Não há nada cadastrado
                    </div>

                    <form v-for="(data, index) in membersAdd" :key="index" class="card p-3 bg-light my-3" v-else>
                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                                <label>Nome:</label>
                                <input-component disabled :type-input="'text'" v-model="data.name"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                <label>CPF / CNPJ:</label>
                                <input-component disabled :type-input="'text'" v-model="data.cpf_cnpj"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                <label>Idade:</label>
                                <input-component disabled :type-input="'text'" v-model="data.age"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                <label>Qualificação:</label>
                                <input-component disabled :type-input="'text'" v-model="data.qualification"/>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>

                <section id="tab-item-3">
                    <div class="alert alert-secondary alert-dismissible fade show" v-if="emailsAdd.length === 0">
                        Não há nada cadastrado
                    </div>

                    <form v-for="(data, index) in emailsAdd" :key="index" class="card p-3 bg-light my-3" v-else>
                        <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                            <label>Receber Notificação:</label>
                            <input disabled type="radio" name="not-email" :id="'nao-email' + index"
                                   v-model="data.notification"
                                   value="1">
                            <label :for="'nao-email' + index">Não</label>

                            <input disabled type="radio" name="not-email" :id="'sim-email' + index"
                                   v-model="data.notification"
                                   value="2">
                            <label :for="'sim-email' + index">Sim</label>
                        </row-col-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                                <label>Nome:</label>
                                <input-component disabled :type-input="'text'" v-model="data.name"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                <label>Email:</label>
                                <input-component disabled :type-input="'email'" v-model="data.email"/>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>

                <section id="tab-item-4">
                    <div class="alert alert-secondary alert-dismissible fade show" v-if="phonesAdd.length === 0">
                        Não há nada cadastrado
                    </div>

                    <form v-for="(data, index) in phonesAdd" :key="index" class="card p-3 bg-light my-3" v-else>
                        <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                            <label>Receber Notificação:</label>
                            <input disabled type="radio" name="not-phone" :id="'nao-phone' + index"
                                   v-model="data.notification"
                                   value="1">
                            <label :for="'nao-phone' + index">Não</label>

                            <input disabled type="radio" name="not-phone" :id="'sim-phone' + index"
                                   v-model="data.notification"
                                   value="2">
                            <label :for="'sim-phone' + index">Sim</label>
                        </row-col-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                                <label>Nome:</label>
                                <input-component disabled :type-input="'text'" v-model="data.name"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                <label>Telefone:</label>
                                <input-component disabled :type-input="'text'" v-model="data.phone"/>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>

                <section id="tab-item-5">
                    <div class="alert alert-secondary alert-dismissible fade show" v-if="membersAdd.length === 0">
                        Não há nada cadastrado
                    </div>

                    <form v-for="(data, index) in activityAdd" :key="index" class="card p-3 bg-light my-3" v-else>
                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                                <label>Código:</label>
                                <input-component disabled :type-input="'text'" v-model="data.code"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                <label>Descrição:</label>
                                <input-component disabled :type-input="'text'" v-model="data.desc"/>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>
            </div>
        </div>

        <row-component>
            <row-col-component
                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                <button-route :items="buttonRoute" style="width: 100%;"/>
            </row-col-component>
        </row-component>

    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from '@/components/row/RowColComponent';
import InputComponent from '@/components/form/InputComponent';
import Multiselect from '@vueform/multiselect';
import ButtonRoute from '@/components/buttons/ButtonRoute';
import InputTextArea from '@/components/form/InputTextArea';
import {useToast} from "vue-toastification";
import ErrorComponent from "@/components/alerts/ErrorComponent";

const toast = useToast()
export default {
    name: 'ProviderShow',

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Fornecedor')
        this.checkACL()
        this.loadProvider()
    },

    components: {
        ErrorComponent,
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        Multiselect,
        ButtonRoute,
        InputTextArea,
    },

    data() {
        return {
            itensOne: {
                title: "Ver",
                icon: "ti-eye"
            },

            buttonSubmit: {
                icon: 'ti-device-floppy',
                nameButton: 'Salvar',
                typeButton: 'button'
            },

            buttonRoute: {
                icon: 'ti-arrow-back-up',
                route: '/provider/search',
                name: 'Voltar'
            },

            buttonAdd: {
                icon: 'ti-circle-plus',
            },

            formData: {
                cpf: '',
                cnpj: '',
                size: '',
                name: '',
                name_f: '',
                surname: '',
                legal_nature: '',
                share_capital: '',
                main_activity: '',
                date_open: '',
                birth_date: '',
                headquarters: '',
                rg: '',
                type: 2,
                status: 1,
                emails: [],
                phones: [],
                members: [],
                secondary_activitys: [],
                cep: '',
                city: '',
                state: '',
                district: '',
                address: '',
                complement: '',
                country: '',
                company_status: ''
            },

            picked: 2,

            errors: [],
            showView: false,
            sendForm: false,
            showError: false,

        }
    },

    methods: {

        loadProvider() {
            this.$store.dispatch('loadProvider', this.id)
                .then((r) => {
                    this.formData = r
                }).catch(() => {
                toast.error('Erro desconhecido', {timeout: 2000})
            })
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 3)
                .then((r) => {
                    if (r.data.indexOf('provider-search') !== -1) {
                        this.showView = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        scrollToTop() {
            window.scrollTo(0, 0);
        },

    },

    computed: {
        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        tabMobile() {
            return this.picked !== 2;
        },

        emailsAdd() {
            return this.formData.emails;
        },

        membersAdd() {
            return this.formData.members;
        },

        phonesAdd() {
            return this.formData.phones;
        },

        activityAdd() {
            return this.formData.secondary_activitys;
        },

    }

}
</script>
