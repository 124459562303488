export default {
    icon: 'ti ti-calculator',
    name: 'Financeiro',
    subLinks: [

        {
            name: 'Conta Bancária',
            route: '/account-bank/search',
            pagePermissions: [
                'account-bank-search',
                'account-bank-create',
                'account-bank-edit'
            ],
        },

        {
            name: 'Entrada/Saída',
            route: '/entrance-exit/search',
            pagePermissions: [
                'entrance-exit-search',
                'entrance-exit-create'
            ],
        },

        {
            name: 'Entrada/Saída (Previsão)',
            route: '/entrance-exit-preview/search',
            pagePermissions: [
                'entrance-exit-preview-search',
                'entrance-exit-preview-create',
                'entrance-exit-preview-low',
                'entrance-exit-preview-delete',
                'entrance-exit-preview-low'
            ],
        },

        {
            name: 'Nome orçamento',
            route: '/name-budget/search',
            pagePermissions: [
                'name-budget-search',
                'name-budget-create',
                'name-budget-edit'
            ],
        },


        {
            name: 'Modelo de Orçamento',
            route: '/budget-template/search',
            pagePermissions: [
                'budget-template-search',
                'budget-template-create',
                'budget-template-edit'
            ],
        },

        {
            name: 'Orçamento',
            route: '/budget/search',
            pagePermissions: [
                'budget-search',
                'budget-create',
                'budget-edit',
                'budget-delete',
                'budget-approved',
                'budget-cancel',
                'budget-end'
            ],
        },

        {
            name: 'Recibo',
            route: '/receipt/search',
            pagePermissions: [
                'receipt-search',
                'receipt-create',
                'receipt-edit',
                'receipt-delete'
            ],
        },

        {
            name: 'Tipo de Conta',
            route: '/account-type/search',
            pagePermissions: [
                'account-type-search',
                'account-type-create',
                'account-type-edit'
            ],
        },

    ],

    pageLinksNavCheck: [
        'account-bank-search',
        'account-bank-create',
        'account-bank-edit',
        'entrance-exit-search',
        'entrance-exit-create',
        'entrance-exit-preview-search',
        'entrance-exit-preview-create',
        'entrance-exit-preview-low',
        'entrance-exit-preview-delete',
        'entrance-exit-preview-low',
        'budget-template-search',
        'budget-template-create',
        'budget-template-edit',
        'budget-search',
        'budget-create',
        'budget-edit',
        'budget-delete',
        'budget-approved',
        'budget-cancel',
        'budget-end',
        'account-type-search',
        'account-type-create',
        'account-type-edit'
    ],
}