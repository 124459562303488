import AdminComponent from '@/components/dashboard/AdminComponent'
import BrandSearch from "@/views/search/BrandSearch";
import BrandCreate from "@/views/create/BrandCreate";
import BrandUpdate from "@/views/update/BrandUpdate";
import BrandShow from "@/views/show/BrandShow";

export default {
    path: '/brand',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'search', name: 'brand-search', component: BrandSearch},
        {path: 'create', name: 'brand-create', component: BrandCreate},
        {path: 'edit/:id', name: 'brand-edit', component: BrandUpdate, props: true},
        {path: 'show/:id', name: 'brand-show', component: BrandShow, props: true},
    ]
}