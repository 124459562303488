import AdminComponent from "@/components/dashboard/AdminComponent";

import ReportClient from "@/views/create/ReportClient";

export default {
    path: "/report-client",
    meta: {auth: true},
    component: AdminComponent,
    children: [
        {path: "client", name: "report-client", component: ReportClient},
    ]
}