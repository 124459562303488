<template>
    <div class="modal-entrance-exit-edit" v-if="modal_update">
        <EntranceExitPreviewUpdate :id="id_modal" @close-modal="modalUpdate"/>
    </div>

    <div class="modal-entrance-exit-low" v-if="modal_low">
        <EntranceExitPreviewLow :id="id_modal" @close-modal="modalLow"/>
    </div>

    <painel-component :itens="itensOne">
        <row-component>
            <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                <label>Código:</label>
                <input-component :type-input="'text'" v-model="formData.code"/>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                <label>Mês:</label>
                <Multiselect v-model="formData.month" :options="selectMonthList" :searchable="true"
                             :noOptionsText="noOptionsText"
                             :noResultsText="noResultsText"/>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                <label>Ano:</label>
                <Multiselect v-model="formData.year" :options="getYearSelect()" :searchable="true"
                             :noOptionsText="noOptionsText"
                             :noResultsText="noResultsText"/>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                <label>Conta Bancária:</label>
                <Multiselect label="name" v-model="formData.bank_accounts_id" :options="selectAccountBankList"
                             :searchable="true"
                             :noOptionsText="noOptionsText"
                             :noResultsText="noResultsText"/>
            </row-col-component>
        </row-component>

        <row-component>
            <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                <label>Tipo Entrada/Saída:</label>
                <Multiselect v-model="formData.type" :options="selectEntranceExitList" :searchable="true"
                             :noOptionsText="noOptionsText"
                             :noResultsText="noResultsText"/>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                <label>Tipo de Conta:</label>
                <Multiselect v-model="formData.account_types_id" :options="selectAccountList" :searchable="true"
                             :noOptionsText="noOptionsText"
                             :noResultsText="noResultsText"/>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                <label>Antecipado:</label>
                <Multiselect v-model="formData.attended" :options="selectYesNoList" :searchable="true"
                             :noOptionsText="noOptionsText"
                             :noResultsText="noResultsText"/>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mt-4'">
                <button-submit @submit="submit" :items="buttonSubmit" class="me-2"/>
                <button-route v-if="buttonCheck.create" :items="buttonRoute"/>
            </row-col-component>
        </row-component>

        <table class="table table-striped mt-4">
            <thead>
            <tr>
                <th scope="col" style="width: 5%">Código</th>
                <th scope="col" style="width: 10%">Conta Bancária</th>
                <th scope="col" style="width: 10%">Tipo de Conta <br> Tipo de Sub Conta</th>
                <th scope="col" style="width: 5%">Preço</th>
                <th scope="col" style="width: 5%">Entrada/Saída</th>
                <th scope="col" style="width: 10%">Data de Vencimento</th>
                <th scope="col" style="width: 10%" v-if="buttonCheck.delete || buttonCheck.edit || buttonCheck.low">
                    Ações
                </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(entrance_exit_preview, index) in this.object" :key="index">
                <td data-label="Código">{{ entrance_exit_preview.code }}</td>
                <td data-label="Conta Bancária">
                    <img v-if="entrance_exit_preview.bank_accounts_photo" class="avatar__image mx-1"
                         :src="entrance_exit_preview.bank_accounts_photo" alt="">
                    <img v-else class="avatar__image mx-1" src="@/assets/images/avatar.png" alt="">
                    {{ entrance_exit_preview.bank_accounts_name }}
                </td>
                <td data-label="Tipo de Conta/Tipo de Sub Conta">
                    {{ entrance_exit_preview.account_types_name }} <br>
                    {{ entrance_exit_preview.account_types_sub_name }}
                </td>
                <td data-label="Preço">{{ entrance_exit_preview.price }}</td>
                <td data-label="Entrada/Saída">{{ statusString(entrance_exit_preview.type) }}</td>
                <td data-label="Data de Vencimento">{{ entrance_exit_preview.due_date }}</td>
                <td data-label="Ações" v-if="buttonCheck.delete || buttonCheck.edit || buttonCheck.low">
                    <button v-if="buttonCheck.edit" class="btn btn-success mx-1" @click="modalUpdate(entrance_exit_preview.id)">
                        <i class="ti ti-edit"></i>
                    </button>
                    <button-delete v-if="buttonCheck.delete" @delete="destroyEntranceExitPreview(entrance_exit_preview)"/>
                    <button v-if="buttonCheck.low" class="btn btn-secondary mx-1" @click="modalLow(entrance_exit_preview.id)">
                        <i class="ti ti-arrow-narrow-down"></i>
                    </button>
                </td>
            </tr>
            </tbody>
        </table>

        <button-pagination :pagination="entrance_exit_preview.itens" :offset="3" @paginate="loadEntriesDeparturesPreview"/>
    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from '@/components/row/RowColComponent';
import InputComponent from '@/components/form/InputComponent';
import Multiselect from '@vueform/multiselect';
import ButtonSubmit from '@/components/buttons/ButtonSubmit';
import ButtonRoute from '@/components/buttons/ButtonRoute';
import {useToast} from "vue-toastification";
import ButtonPagination from "@/components/buttons/ButtonPagination";
import ButtonDelete from "@/components/buttons/ButtonDelete";
import swal from "sweetalert";

const toast = useToast()

import EntranceExitPreviewUpdate from "@/views/update/EntranceExitPreviewUpdate";
import EntranceExitPreviewLow from "@/views/update/EntranceExitPreviewLow";

export default {
    name: 'EntranceExitPreviewSearch',

    created() {
        this.$store.dispatch('updateNamePage', 'Entrada/Saída (Previsão)')
        this.$store.dispatch('loadAccountBankMultiSelect')
        this.$store.dispatch('loadAccountTypeMultiSelect')
        this.checkACL()
        this.loadEntriesDeparturesPreview(1)
    },

    components: {
        ButtonDelete,
        ButtonPagination,
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        Multiselect,
        ButtonSubmit,
        ButtonRoute,
        EntranceExitPreviewUpdate,
        EntranceExitPreviewLow
    },

    data() {
        return {
            itensOne: {
                title: "Pesquisa",
                icon: "ti-list"
            },

            buttonSubmit: {
                icon: 'ti-search',
            },

            buttonRoute: {
                icon: 'ti-plus',
                route: '/entrance-exit-preview/create'
            },

            buttonCheck: {
                create: false,
                delete: false,
                edit: false,
                low: false
            },

            object: {},

            modal_low: false,
            modal_update: false,
            id_modal: '',

            formData: {
                month: '',
                year: '',
                code: '',
                bank_accounts_id: '',
                account_types_id: '',
                type: '',
                attended: ''
            },

            searchSubmit: false,
        }
    },

    methods: {

        submit() {
            this.loadEntriesDeparturesPreview(1)
            this.searchSubmit = true
        },

        modalUpdate(id) {
            this.modal_update = !this.modal_update
            this.loadEntriesDeparturesPreview(1)
            if (id)
                this.id_modal = id
        },

        modalLow(id) {
            this.modal_low = !this.modal_low
            this.loadEntriesDeparturesPreview(1)
            if (id)
                this.id_modal = id
        },

        statusString(value) {
            if (value === '1')
                return 'Entrada'
            return 'Saída'
        },

        getYearSelect() {
            let dataActual = new Date();
            let year = dataActual.getFullYear() + 2;
            let years = [];
            for (let i = 2021; i <= year; i++) {
                years.push(i);
            }
            return years
        },

        loadEntriesDeparturesPreview(page) {
            if (!this.searchSubmit) {
                this.$store.dispatch('loadEntriesDeparturesPreview', {...this.params, page})
                    .then((r) => {
                        this.object = r.itens.data
                        if (this.searchSubmit) {
                            this.searchSubmit = false;
                            if (r.itens.data == '') {
                                toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 2000});
                            }
                        }
                    }).catch(() => {
                    this.searchSubmit = false;
                });
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 16)
                .then((r) => {
                    if (r.data.indexOf('entrance-exit-preview-search') !== -1) {

                        if (r.data.indexOf('entrance-exit-preview-create') !== -1) {
                            this.buttonCheck.create = true
                        }

                        if (r.data.indexOf('entrance-exit-preview-edit') !== -1) {
                            this.buttonCheck.edit = true
                        }

                        if (r.data.indexOf('entrance-exit-preview-low') !== -1) {
                            this.buttonCheck.low = true
                        }

                        if (r.data.indexOf('entrance-exit-preview-delete') !== -1) {
                            this.buttonCheck.delete = true
                        }

                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        destroyEntranceExitPreview(item) {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar ${item.name}, ${this.me.name} ?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    this.$store.dispatch('destroyEntranceExitPreview', item.id).then(() => {
                        this.loadUsers(1)
                        toast.success('Cadastro deletado com sucesso', {timeout: 2000});
                    })
                        .catch(() => {
                            toast.error(`Não foi possivel deletar  ${item.name} `, {timeout: 2000});
                        })
                }
            });
        },

    },

    computed: {
        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        selectMonthList() {
            return this.$store.state.var.selectMonthList
        },

        selectEntranceExitList() {
            return this.$store.state.var.selectEntranceExitList
        },

        selectAccountBankList() {
            return this.$store.state.var.selectAccountBankList
        },

        selectAccountList() {
            return this.$store.state.var.selectAccountList
        },

        selectYesNoList() {
            return this.$store.state.var.selectYesNoList
        },

        entrance_exit_preview() {
            return this.$store.state.entrance_exit_preview.items
        },

        params() {
            return {
                page: this.entrance_exit_preview.current_page,
                month: this.formData.month,
                year: this.formData.year,
                code: this.formData.code,
                bank_accounts_id: this.formData.bank_accounts_id,
                account_types_id: this.formData.account_types_id,
                type: this.formData.type,
                attended: this.formData.attended
            }
        },

        me() {
            return this.$store.state.auth.me
        },
    }
}
</script>

<style src="@/assets/style/table.css">

</style>

<style scoped>
.avatar__image {
    width: 35px;
}

.modal-entrance-exit-edit,
.modal-entrance-exit-low {
    top: 50%;
    left: 50%;
    width: 50%;
    z-index: 15;
    position: fixed;
    min-height: 40%;
    margin-right: -50%;
    padding: 40px 20px 0;
    overflow-y: auto;
    background: #FFFFFF;
    transform: translate(-50%, -50%);
    box-shadow: 10px 10px 23px 0 rgb(204 204 204);
}

@media (max-width: 991px) {
    .modal-entrance-exit-edit,
    .modal-entrance-exit-low {
        width: 96%;
        height: 90%;
        overflow-y: auto;
    }
}
</style>