<template>

  <painel-component :itens="itensOne">
    <error-component :errors="errors" v-if="showError"/>
    <div class="clearfix mb-4" v-if="buttonAddRoute">
      <button-route class="float-end" :items="buttonRouteAdd"/>
    </div>



        <div v-if="showView">
          <form>
            <row-component>
              <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                 :class="{'formError' : errors.name}">
                <label>Nome:</label>
                <input-component :type-input="'text'" v-model="formData.name"/>
              </row-col-component>

              <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                 :class="{'formError' : errors.status}">
                <label>Status:</label>
                <Multiselect v-model="formData.status" :options="selectActiveInative" :searchable="true"
                             :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
              </row-col-component>
            </row-component>

            <row-component>
              <row-col-component
                  :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                  :class="{'formError' : errors.obs}">
                <label>Observações:</label>
                <input-text-area :rows="4" v-model="formData.description"/>
              </row-col-component>
            </row-component>
          </form>
        </div>




    <row-component>
      <row-col-component
          :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
        <button-submit :disaabled="sendForm" @submit="submit" :items="buttonSubmit" style="width: 100%;"/>
      </row-col-component>
      <row-col-component
          :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
        <button-route :items="buttonRoute" style="width: 100%;"/>
      </row-col-component>
    </row-component>

  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from '@/components/row/RowColComponent';
import InputComponent from '@/components/form/InputComponent';
import Multiselect from '@vueform/multiselect';
import ButtonSubmit from '@/components/buttons/ButtonSubmit';
import ButtonRoute from '@/components/buttons/ButtonRoute';
import {useToast} from "vue-toastification";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import InputTextArea from "@/components/form/InputTextArea.vue";

const toast = useToast()
export default {
  name: 'NameBudgetUpdate',

  props: {
    id: {
      required: true
    }
  },

  created() {
    this.$store.dispatch('updateNamePage', 'Nome Orçamento')
    this.checkACL()
    this.loadItem()
  },

  components: {
    InputTextArea,
    ErrorComponent,
    PainelComponent,
    RowComponent,
    RowColComponent,
    InputComponent,
    Multiselect,
    ButtonSubmit,
    ButtonRoute,
  },

  data() {
    return {
      itensOne: {
        title: "Editar",
        icon: "ti-edit"
      },

      buttonSubmit: {
        icon: 'ti-device-floppy',
        nameButton: 'Salvar',
        typeButton: 'button'
      },

      buttonSubmitAdd: {
        icon: 'ti-circle-plus',
        nameButton: 'Adicionar',
        typeButton: 'button'
      },

      buttonRoute: {
        icon: 'ti-arrow-back-up',
        route: '/name-budget/search',
        name: 'Voltar'
      },

      buttonAdd: {
        icon: 'ti-circle-plus',
      },

      buttonEdit: {
        icon: 'ti-edit',
      },

      buttonDelete: {
        icon: 'ti-trash',
      },

      buttonRouteAdd: {
        icon: 'ti-plus',
        route: '/name-budget/create',
      },

      buttonAddRoute: false,

      formData: {
        name: '',
        status: '',
        description: '',

      },


      edit: false,


      errors: [],
      showView: false,
      sendForm: false,
      showError: false,
    }
  },

  methods: {
    loadItem() {
      this.$store.dispatch('loadNameBudget', this.id)
          .then((r) => {
            this.formData = r
          }).catch(() => {
        toast.error('Erro desconhecido', {timeout: 2000})
      })
    },

    submit() {
      if (!this.sendForm) {
        this.sendForm = true;
        this.$store.dispatch('updateNameBudget', this.formData)
            .then(() => {
              this.showError = false
              this.sendForm = false
              this.errors = []
              toast.success("Atualização atualizado com sucesso", {timeout: 2000});
            }).catch((r) => {
          this.showError = true;
          this.sendForm = false
          this.errors = r.response.data.errors;
          this.scrollToTop()
          toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
        })
      }
    },




    async checkACL() {
      this.$store.dispatch('ACLitens',23)
          .then((r) => {
            if (r.data.indexOf('name-budget-edit') !== -1) {
              this.showView = true

              if (r.data.indexOf('name-budget-create') !== -1) {
                this.buttonAddRoute = true
              }

            } else {
              this.$router.push({name: 'home'})
            }
          }).catch(() => {
        this.$router.push({name: 'home'})
      })
    },


  },

  computed: {
    me() {
      return this.$store.state.auth.me
    },

    selectActiveInative() {
      return this.$store.state.var.selectActiveInative
    },

    noResultsText() {
      return this.$store.state.var.noResultsText
    },

    noOptionsText() {
      return this.$store.state.var.noOptionsText
    },

  }

}
</script>

<style scoped>

</style>
