<template>
    <painel-component :itens="itensOne">
        <Bar  :options="chartOptions" :data="chartData" :height="125"/>
    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent'
import {useToast} from "vue-toastification";
import {Bar} from 'vue-chartjs'
import {Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
const toast = useToast()

export default {
    name: 'HomeView',
    components: {
        Bar,
        PainelComponent
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Home')
        this.loadHome()
    },

    data() {
        return {
            chartData: {
                labels: [],
                datasets: [
                    {
                        label: '',
                        data: [],
                        backgroundColor: '#04589b'
                    },
                ]
            },

            chartOptions: {
                responsive: true
            },

            object: {},

            itensOne: {
                title: "",
                icon: "ti-list"
            },
        }
    },

    methods: {
        loadHome() {
            this.$store.dispatch("loadHome")
                .then((r) => {
                    this.object = r
                    this.loadCharts(this.object.budgetTotal)
                }).catch(() => {
                toast.error('Erro desconhecido!', {timeout: 1500})
            })
        },

        loadCharts(data) {
            let date = new Date()
            let year = date.getFullYear()

            this.chartData = {
                labels: data.labels,
                datasets: [
                    {
                        label: `Orçamentos abertos no ano de: ${year}`,
                        data: data.quant,
                        backgroundColor: '#36c1bd'
                    },
                ]
            }
        },
    }
}
</script>