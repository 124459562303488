import AdminComponent from '@/components/dashboard/AdminComponent'
import ProductSearch from '@/views/search/ProductSearch'
import ProductCreate from '@/views/create/ProductCreate'
import ProductUpdate from "@/views/update/ProductUpdate";
import ProductShow from "@/views/show/ProductShow";
import ProductUpdatePhoto from "@/views/update/ProductUpdatePhoto";

export default {
    path: '/product',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'search', name: 'product-search', component: ProductSearch},
        {path: 'create', name: 'product-create', component: ProductCreate},
        {path: 'edit/:id', name: 'product-edit', component: ProductUpdate, props: true},
        {path: 'show/:id', name: 'product-show', component: ProductShow, props: true},
        {path: 'photo/:id', name: 'product-edit-photo', component: ProductUpdatePhoto, props: true},
    ]
}
