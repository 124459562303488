export const URL_BASE = '/api/v1/admin/'
export const NAME_TOKEN = 'TOKEN_AUTH'

let estados = [];
estados['AC'] = 'Acre';
estados['AL'] = 'Alagoas';
estados['AP'] = 'Amapá';
estados['AM'] = 'Amazonas';
estados['BA'] = 'BahiaBahia';
estados['CE'] = 'Ceará';
estados['DF'] = 'Distrito Federal';
estados['ES'] = 'Espírito Santo';
estados['GO'] = 'Goiás';
estados['MA'] = 'Maranhão';
estados['MT'] = 'Mato Grosso';
estados['MS'] = 'Mato Grosso do Sul';
estados['MG'] = 'Minas Gerais';
estados['PA'] = 'Pará';
estados['PB'] = 'Paraíba';
estados['PR'] = 'Paraná';
estados['PE'] = 'Pernambuco';
estados['PI'] = 'Piauí';
estados['RJ'] = 'Rio de Janeiro';
estados['RN'] = 'Rio Grande do Norte';
estados['RS'] = 'Rio Grande do Sul';
estados['RO'] = 'Rondônia';
estados['RR'] = 'Roraima';
estados['SC'] = 'Santa Catarina';
estados['SP'] = 'São Paulo';
estados['SE'] = 'Sergipe';
estados['TO'] = 'Tocantins';

const money = {
    decimal: ',',
    thousands: '.',
    prefix: '',
    suffix: '',
    precision: 2,
    masked: false /* doesn't work with directive */
}

export let ESTADOS = estados
export let MONEY = money
