<template>
    <input :name="nameInput" type="checkbox" class="form-check-input" :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)">
</template>
  
<script>
export default {
    name: "InputCheckBox",
    props: {
        modelValue: {
            type: String,
            default: '',
            required: false
        },

        nameInput: {
            "required": false,
            "type": String,
            "default": ''
        },
    },
}
</script>

<style scoped>
.form-check-input:checked {
    background-color: #36c1bd;
    border-color: #36c1bd;
}

.form-check-input:hover {
    cursor: pointer;
}

.form-check-input[type=checkbox] {
    border-radius: 0;
}

.form-check-input:active {
    filter: none;
}

.form-check-input:focus {
    border-color: rgba(0, 0, 0, .25);
    outline: 0;
    box-shadow: none;
}
</style>