<template>
    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>

        <form v-if="showView">
            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError' : errors.name}">
                    <label>Nome:</label>
                    <input-component disabled :type-input="'text'" v-model="formData.name"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError' : errors.status}">
                    <label>Status:</label>
                    <Multiselect disabled v-model="formData.status" :options="selectActiveInative" :searchable="true"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError' : errors.price}">
                    <label>Preço:</label>
                    <input-component disabled :type-input="'text'" v-model="formData.price"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError' : errors.initial_inventory}">
                    <label>Estoque Inicial:</label>
                    <input-component disabled :type-input="'number'" v-model="formData.initial_inventory"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError' : errors.minimum_stock}">
                    <label>Estoque Mínimo:</label>
                    <input-component disabled :type-input="'number'" v-model="formData.minimum_stock"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError' : errors.brand_id}">
                    <label>Marca:</label>
                    <Multiselect disabled v-model="formData.brand_id" :options="selectBrandList" :searchable="true"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError' : errors.category_id}">
                    <label>Categoria:</label>
                    <Multiselect disabled v-model="formData.category_id" :options="selectCategoryList"
                                 :searchable="true"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError' : errors.obs}">
                    <label>Observações:</label>
                    <input-text-area disabled :rows="4" v-model="formData.obs"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <button-route :items="buttonRoute" style="width: 100%;"/>
                </row-col-component>
            </row-component>
        </form>

    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from '@/components/row/RowColComponent';
import InputComponent from '@/components/form/InputComponent';
import Multiselect from '@vueform/multiselect';
import ButtonRoute from '@/components/buttons/ButtonRoute';
import InputTextArea from '@/components/form/InputTextArea';
import ErrorComponent from "@/components/alerts/ErrorComponent.vue";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: 'ProductShow',

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Produtos')
        this.checkACL()
        this.loadProduct()
    },

    components: {
        ErrorComponent,
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        Multiselect,
        ButtonRoute,
        InputTextArea
    },

    data() {
        return {
            itensOne: {
                title: "Ver",
                icon: "ti-eye"
            },

            buttonRoute: {
                icon: 'ti-arrow-back-up',
                route: '/product/search',
                name: 'Voltar'
            },

            formData: {
                name: '',
                status: '',
                price: '',
                initial_inventory: '',
                minimum_stock: '',
                brand_id: '',
                category_id: '',
                obs: ''
            },

            errors: [],
            showView: false,
            sendForm: false,
            showError: false,
        }
    },

    methods: {
        loadProduct() {
            this.$store.dispatch('loadProduct', this.id)
                .then((r) => {
                    this.formData = r
                    this.$store.dispatch('loadCategoryMultiSelect')
                    this.$store.dispatch('loadBrandMultiSelect')
                }).catch(() => {
                toast.error('Erro desconhecido', {timeout: 2000})
            })
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 9)
                .then((r) => {
                    if (r.data.indexOf('product-search') !== -1) {
                        this.showView = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        selectCategoryList() {
            return this.$store.state.var.selectCategoryList
        },

        selectBrandList() {
            return this.$store.state.var.selectBrandList
        },
    }

}
</script>
