<template>
    <button @click="$emit('delete')" type="button" class="btn btn-danger buttonRound mx-1" tooltip="Excluir" flow="down">
        <i class="ti ti-trash"></i>
    </button>
</template>
  
<script>
export default {
    name: "ButtonDelete",

    methods: {
        delete() {
            this.$emit('delete')
        }
    }

}
</script>