<template>
    <painel-component :itens="itemsOne">
        <error-component v-if="showError" :errors="errors"/>

        <div class="clearfix" v-if="create">
            <button-route class="float-end" :items="itemsAdd"/>
        </div>

        <form v-if="showView">
            <div class="aux mb-4">
                <div class="avatar-form">
                    <img v-if="formData.photo" :src="formData.photo" alt="" class="avatar__image">
                    <img v-else src="@/assets/images/avatar.png" alt="" class="avatar__image">
                </div>
                <p>{{ formData.brand_model }}</p>
                <router-link :to="{ name: 'guest-edit-photo', id: this.id }" class="figure">
                    <i class="ti ti-camera"></i>
                </router-link>
            </div>

            <row-component>
                <row-col-component class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12"
                                   :class="{'formError':errors.name}">
                    <label>Nome:</label>
                    <input-component v-model="formData.name" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError':errors.status}">
                    <label>Status:</label>
                    <Multiselect v-model="formData.status" :options="selectActiveInative" :searchable="true"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError':errors.phone}">
                    <label>Telefone:</label>
                    <input-component v-model="formData.phone" v-mask="['(##) ####-####', '(##) #####-####']"
                                     type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12"
                                   :class="{'formError':errors.email}">
                    <label>Email:</label>
                    <input-component v-model="formData.email" type-input="email"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                   :class="{'formError':errors.desc}">
                    <label>Observações:</label>
                    <input-text-area v-model="formData.desc" :rows="6"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-submit @submit="submit" :disabled="sendForm" class="w-100" :items="itemsTwo"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itemsRoute"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import InputTextArea from "@/components/form/InputTextArea";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";

import {mask} from "vue-the-mask";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "GuestScheduleUpdate",
    components: {
        ButtonRoute,
        ButtonSubmit,
        InputTextArea,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        ErrorComponent,
        PainelComponent
    },

    directives: {
        mask
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch("updateNamePage", "Convidado Agenda")
        this.checkACL()
        this.loadItems()
    },

    data() {
        return {
            itemsOne: {
                icon: "ti-edit",
                title: "Editar",
            },

            itemsTwo: {
                icon: "ti-device-floppy",
                nameButton: "Salvar"
            },

            itemsRoute: {
                route: "/guest-schedule/search",
                icon: "ti-arrow-back-up",
                name: "Voltar"
            },

            itemsAdd: {
                route: "/guest-schedule/create",
                icon: "ti-plus",
            },

            create: false,

            formData: {
                id: this.id,
                name: "",
                phone: "",
                email: "",
                status: "",
                desc: "",
                photo: ""
            },

            errors: [],
            showView: false,
            sendForm: false,
            showError: false,
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch("loadGuestSchedule", this.id)
                .then(response => this.formData = response)
                .catch(() => {
                    toast.error("Erro desconhecido", {timeout: 2000})
                })
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateGuestSchedule', this.formData)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success("Cadastro atualizado com sucesso", {timeout: 2000});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollToTop()
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 21)
                .then((r) => {
                    if (r.data.indexOf('guest-schedule-edit') !== -1) {
                        this.showView = true

                        if (r.data.indexOf('guest-schedule-create') !== -1) {
                            this.create = true
                        }

                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },
    }
}

</script>