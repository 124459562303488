<template>
    <painel-component :itens="itensOne">
        <error-component v-if="showError" :errors="errors"/>

        <form v-if="showView">
            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.delivery_type}">
                    <input @change="deliveryType" v-model="formData.delivery_type" type="radio" name="docs"
                           id="current-address" value="1">
                    <label for="current-address">Endereço de Cadastro</label>

                    <input @change="deliveryType" v-model="formData.delivery_type" type="radio" name="docs"
                           id="new-address" value="2">
                    <label for="new-address">Endereço Novo</label>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.cep}">
                    <label>CEP:</label>
                    <input-component v-mask="'#####-###'" @change="searchApiCEP" v-model="formData.cep"
                                     type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.city}">
                    <label>Cidade:</label>
                    <input-component v-model="formData.city" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.state}">
                    <label>Estado:</label>
                    <input-component v-model="formData.state" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.district}">
                    <label>Bairro:</label>
                    <input-component v-model="formData.district" type-input="text"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.address}">
                    <label>Endereço:</label>
                    <input-component v-model="formData.address" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.country}">
                    <label>País:</label>
                    <input-component v-model="formData.country" type-input="text"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.complement}">
                    <label>Referência:</label>
                    <input-text-area v-model="formData.complement" :rows="4"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.schedule_id}">
                    <label>Agenda:</label>
                    <Multiselect v-model="formData.schedule_id" :options="selectSchedule" :searchable="true"
                                 @select="loadBudgetGetScheduleEvent(formData.schedule_id)"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.color}">
                    <label>Cor:</label>
                    <Multiselect v-model="formData.color" :options="selectScheduleColor" :searchable="true"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.date_start}">
                    <label>Data Início:</label>
                    <input-component v-mask="'##/##/####'" v-model="formData.date_start" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.time_start}">
                    <label>Hora Início:</label>
                    <input-component v-mask="'##:##'" v-model="formData.time_start" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.date_end}">
                    <label>Data Final:</label>
                    <input-component v-mask="'##/##/####'" v-model="formData.date_end" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.time_end}">
                    <label>Hora Final:</label>
                    <input-component v-mask="'##:##'" v-model="formData.time_end" type-input="text"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.desc}">
                    <label>Descrição:</label>
                    <input-text-area v-model="formData.desc" :rows="6"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-submit :disabled="sendForm" class="w-100" @click="submit" :items="itensThree"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itensTwo"/>
                </row-col-component>
            </row-component>
        </form>

        <div class="line-division mt-4">
            <h1 class="h1-text"><i class="ti ti-clock"></i> {{ formData.schedule_name }}</h1>
        </div>

        <FullCalendar ref="fullCalendar" :options="calendarOptions"/>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import InputTextArea from "@/components/form/InputTextArea";
import Multiselect from "@vueform/multiselect";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";

import {mask} from "vue-the-mask";
import {useToast} from "vue-toastification";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import ptLocale from "@fullcalendar/core/locales/pt";

const toast = useToast()
export default {
    name: "BudgetDeliveryCreate",
    components: {
        FullCalendar,
        ButtonRoute,
        ButtonSubmit,
        Multiselect,
        InputTextArea,
        InputComponent,
        RowColComponent,
        RowComponent,
        ErrorComponent,
        PainelComponent
    },

    directives: {
        mask
    },

    props: {
        id: {
            required: true
        },

        client: {
            required: true
        },
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Entrega')
        this.loadBudgetSchedule()
        this.checkACL()
    },

    data() {
        return {
            itensOne: {
                title: "Adicionar",
                icon: "ti-pencil-plus"
            },

            itensTwo: {
                icon: 'ti-arrow-back-up',
                route: '/budget/search',
                name: 'Voltar'
            },

            itensThree: {
                icon: 'ti-plus',
                nameButton: "Adicionar"
            },

            formData: {
                budget_id: this.id,
                schedule_id: '',
                date_start: this.dateAdd(),
                time_start: this.currentTime(),
                date_end: this.dateAdd(),
                time_end: this.timeAdd(),
                color: '',
                delivery_type: '1',
                cep: '',
                city: '',
                state: '',
                district: '',
                address: '',
                complement: '',
                country: '',
                desc: ''
            },

            calendarOptions: {
                plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
                initialView: 'dayGridMonth',
                dateClick: this.handleDateClick,
                eventClick: this.eventClick,
                locale: ptLocale,
                themeSystem: 'standard',
                selectable: true,
                eventDisplay: 'block',
                events: [],
                height: 'auto',
                headerToolbar: {
                    left: 'title',
                    right: 'dayGridMonth,dayGridWeek,listDay, prev,next'
                },
                weekends: true,
                customButtons: {
                    prev: {
                        click: () => {
                            let calendarApi = this.$refs.fullCalendar.getApi();
                            calendarApi.prev();
                            this.loadEventsMonth(calendarApi.getDate().getMonth() + 1)
                        }
                    },
                    next: {
                        click: () => {
                            let calendarApi = this.$refs.fullCalendar.getApi();
                            calendarApi.next();
                            this.loadEventsMonth(calendarApi.getDate().getMonth() + 1)
                        }
                    }
                },
            },

            schedule: [],

            errors: [],
            showView: false,
            sendForm: false,
            showError: false,
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('storeBudgetSchedule', this.formData)
                    .then((r) => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success("Cadastro efetuado com sucesso", {timeout: 2000});
                        this.$router.push({
                            name: 'budget-delivery-edit',
                            params: {id: this.id, client: this.client, delivery: r}
                        })
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    window.scrollTo(0, 0);
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        loadBudgetSchedule() {
            this.$store.dispatch('loadBudgetSchedule', this.client)
                .then((r) => {
                    let address = r.address
                    this.schedule = r.schedule

                    this.formData.cep = address.cep
                    this.formData.city = address.city
                    this.formData.state = address.state
                    this.formData.address = address.address
                    this.formData.country = address.country
                    this.formData.district = address.district
                    this.formData.complement = address.complement
                })
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 13)
                .then((r) => {
                    if (r.data.indexOf('budget-edit') !== -1) {
                        this.showView = true

                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        eventClick: function (dateObject) {
            let publicId = dateObject.event._def.publicId
            this.ShowEvent(publicId)
        },

        loadEventsMonth(month) {
            let params = {id: this.client, month: month}
            this.$store.dispatch('loadBudgetScheduleEventsMonth', params)
                .then(r => this.calendarOptions.events = r);
        },

        loadBudgetGetScheduleEvent(id){
            this.$store.dispatch('loadBudgetGetScheduleEvent', id)
                .then(r => this.calendarOptions.events = r);
        },

        deliveryType() {
            if (this.formData.delivery_type === '2') {
                this.formData = {
                    budget_id: this.id,
                    schedule_id: '',
                    date_start: this.dateAdd(),
                    time_start: this.currentTime(),
                    date_end: this.dateAdd(),
                    time_end: this.timeAdd(),
                    color: '',
                    delivery_type: '2',
                    cep: '',
                    city: '',
                    state: '',
                    district: '',
                    address: '',
                    complement: '',
                    country: '',
                    desc: ''
                }
            } else {
                this.loadBudgetSchedule()
            }
        },

        searchApiCEP() {
            this.resetAddress()
            let cep = this.formData.cep.replace(/\D/g, '');
            const dateCEP = {'cep': cep}
            this.$store.dispatch('searchApiCEP', dateCEP)
                .then((r) => {
                    this.formData.city = r.data.localidade
                    this.formData.state = r.data.uf
                    this.formData.district = r.data.bairro
                    this.formData.address = r.data.logradouro
                    this.formData.complement = r.data.complemento
                }).catch(() => {
            })
        },

        resetAddress() {
            this.formData.city = ''
            this.formData.state = ''
            this.formData.district = ''
            this.formData.address = ''
            this.formData.complement = ''
        },

        dateAdd() {
            let currentTime = new Date();
            if (currentTime.getDay() === 5) {
                currentTime.setDate(currentTime.getDate() + 3)
            }

            if (currentTime.getDay() === 4) {
                currentTime.setDate(currentTime.getDate() + 4)
            } else {
                currentTime.setDate(currentTime.getDate() + 2)
            }

            let day = currentTime.getDate();
            let month = currentTime.getMonth() + 1;
            let year = currentTime.getFullYear();

            day = (day < 10 ? '0' : '') + day;
            month = (month < 10 ? '0' : '') + month;

            return `${day}/${month}/${year}`
        },

        timeAdd() {
            const currentTime = new Date();
            let currentHours = currentTime.getHours() + 2;
            const currentMinutes = currentTime.getMinutes();
            const currentSeconds = currentTime.getSeconds();

            currentHours = currentHours.toString().padStart(2, '0');
            const formattedMinutes = currentMinutes.toString().padStart(2, '0');
            const formattedSeconds = currentSeconds.toString().padStart(2, '0');

            return `${currentHours}:${formattedMinutes}:${formattedSeconds}`;
        },

        currentTime() {
            const currentTime = new Date();
            let currentHours = currentTime.getHours();
            const currentMinutes = currentTime.getMinutes();
            const currentSeconds = currentTime.getSeconds();

            currentHours = currentHours.toString().padStart(2, '0');
            const formattedMinutes = currentMinutes.toString().padStart(2, '0');
            const formattedSeconds = currentSeconds.toString().padStart(2, '0');

            return `${currentHours}:${formattedMinutes}:${formattedSeconds}`;
        }
    },

    computed: {
        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        selectScheduleColor() {
            return this.$store.state.var.selectScheduleColor
        },

        selectSchedule() {
            return this.schedule
        },
    }
}
</script>

<style scoped>
.line-division {
    width: 100%;
    border-top: 1px solid #c4c4c4;
}

.h1-text {
    color: white;
    padding: 10px;
    margin-top: 1px;
    font-size: 16px;
    width: fit-content;
    background-color: #36c1bd;
}
</style>