<template>
    <router-link :to="{ name: route, params: { id: idParam } }" class="btn btn-success buttonRound mx-1">
        <i class="ti ti-edit"></i>
    </router-link>
</template>
  
<script>
export default {
    name: "ButtonEdit",
    props: {
        route: {
            'type': String,
            "required": true,
        },

        idParam: {
            'type': Number,
            "required": true,
        }
    },
}
</script>
  
<style scoped>
.buttonRound i {
    padding-top: 5px;
}
</style>