import axios from "axios";
import {URL_BASE} from "@/configs/constants";

const RESOURCE = 'page';
export default {

    loadPage(context, params) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}`, {params})
                .then(response => {
                    context.commit('LOAD_PAGES', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                return reject(errors)
            })
        })
    },

}