import axios from "axios";
import {URL_BASE} from "@/configs/constants";

export default {
    updateNamePage(context, namePage) {
        context.commit('UPDATE_NAME_PAGE', namePage)
    },

    loadUserMultiSelect(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}user-list`)
                .then(response => {
                    context.commit('UPDATE_USER_LIST', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                return reject(errors)
            })
        })
    },

    loadGroupUserMultiSelect(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}group-list`)
                .then(response => {
                    context.commit('UPDATE_GROUP_USER_LIST', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                return reject(errors)
            })
        })
    },

    loadCategoryMultiSelect(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}category-list`)
                .then(response => {
                    context.commit('UPDATE_CATEGORY_LIST', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                return reject(errors)
            })
        })
    },

    loadProductMultiSelect(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}product-list`)
                .then(response => {
                    context.commit('UPDATE_PRODUCT_LIST', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                return reject(errors)
            })
        })
    },

    loadProductBrandMultiSelect(context, formData) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}product-list-brand/${formData.brand}/${formData.category}`)
                .then(response => {
                    context.commit('UPDATE_PRODUCT_BRAND_LIST', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                return reject(errors)
            })
        })
    },

    loadProductPriceMultiSelect(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}product-price/${id}`)
                .then(response => {
                    context.commit('UPDATE_PRODUCT_PRICE_LIST', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                return reject(errors)
            })
        })
    },

    loadFleetMultiSelect(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}fleet-list`)
                .then(response => {
                    context.commit('UPDATE_FLEET_LIST', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                return reject(errors)
            })
        })
    },

    loadBrandMultiSelect(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}brand-list`)
                .then(response => {
                    context.commit('UPDATE_BRAND_LIST', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                return reject(errors)
            })
        })
    },

    loadServiceMultiSelect(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}service-list`)
                .then(response => {
                    context.commit('UPDATE_SERVICE_LIST', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                return reject(errors)
            })
        })
    },

    loadServicePriceMultiSelect(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}service-price/${id}`)
                .then(response => {
                    context.commit('UPDATE_SERVICE_PRICE_LIST', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                return reject(errors)
            })
        })
    },

    loadClientMultiSelect(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}client-list`)
                .then(response => {
                    context.commit('UPDATE_CLIENT_LIST', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                return reject(errors)
            })
        })
    },

    loadClientEmailMultiSelect(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}client-list-email/${id}`)
                .then(response => {
                    context.commit('UPDATE_CLIENT_EMAIL_LIST', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                return reject(errors)
            })
        })
    },

    loadClientPhoneMultiSelect(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}client-list-phone/${id}`)
                .then(response => {
                    context.commit('UPDATE_CLIENT_PHONE_LIST', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                return reject(errors)
            })
        })
    },

    loadBudgetTemplateMultiSelect(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}budget-template-list`)
                .then(response => {
                    context.commit('UPDATE_BUDGET_TEMPLATE_LIST', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                return reject(errors)
            })
        })
    },

    loadBudgetTemplateGetMultiSelect(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}budget-template-get/${id}`)
                .then(response => {
                    context.commit('UPDATE_BUDGET_TEMPLATE_GET_LIST', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                return reject(errors)
            })
        })
    },

    loadAccountBankMultiSelect(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}account-bank-list`)
                .then(response => {
                    context.commit('UPDATE_ACCOUNT_BANK_LIST', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                return reject(errors)
            })
        })
    },

    loadAccountTypeMultiSelect(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}account-type-list`)
                .then(response => {
                    context.commit('UPDATE_ACCOUNT_TYPE_LIST', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                return reject(errors)
            })
        })
    },

    loadAccountTypeSubMultiSelect(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}account-type-sub-list/${id}`)
                .then(response => {
                    context.commit('UPDATE_ACCOUNT_TYPE_SUB_LIST', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                return reject(errors)
            })
        })
    },

    loadProviderMultiSelect(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}provider-list`)
                .then(response => {
                    context.commit('UPDATE_PROVIDER_LIST', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                return reject(errors)
            })
        })
    },

    loadEmployeeMultiSelect(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}employee-list`)
                .then(response => {
                    context.commit('UPDATE_EMPLOYEE_LIST', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                return reject(errors)
            })
        })
    },

    loadEmployeeBankMultiSelect(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}employee-bank/${id}`)
                .then(response => {
                    context.commit('UPDATE_EMPLOYEE_BANK_LIST', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                return reject(errors)
            })
        })
    },

    loadBudgetListPayment(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}budget-list-payment`)
                .then(response => {
                    context.commit('UPDATE_BUDGET_LIST_PAYMENT', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                return reject(errors)
            })
        })
    },

    loadClientsBudgetList(context, params) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}budget-search-client`, {params})
                .then(response => {
                    context.commit('UPDATE_CLIENT_BUDGET', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    loadCategoryBrandList(context, id) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}category-list-brand/${id}`)
                .then(response => {
                    context.commit('UPDATE_CATEGORY_BRAND_LIST', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    loadGuestScheduleList(context) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}guest-schedule-list`)
                .then(response => {
                    context.commit('UPDATE_GUEST_SCHEDULE_LIST', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },


    loadNameBudgetList(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}name-budget-list`)
                .then(response => {
                    context.commit('UPDATE_NAME_BUDGET_LIST', response.data)
                    return resolve(response.data)
                }).catch(errors => {
                return reject(errors)
            }).finally(() => {

            })
        })
    },


}
