<template>
  <nav-menu :openMenu="active"  v-if="!currentRouteNameLogin" />

  <div class="wrapperComponent" v-if="!currentRouteNameLogin" :class="{ toggle: active }">
    <div class="menuTopBar">
      <h1>{{ currentRouteName }}</h1>
      <div class="menu-toggle" @click="toggleMenu()">
        <div class="hamburger-menu-button">
          <div class="hamburger-menu" :class="{ open: active }"></div>
        </div>
      </div>
    </div>

    <router-view v-slot="{ Component, route }">
      <transition name="fade" mode="out-in">
        <div :key="route.name">
          <component :is="Component" />
        </div>
      </transition>
    </router-view>
  </div>

  <div v-else>
    <router-view></router-view>
  </div>
</template>

<script>
import NavMenu from "@/components/nav/NavMenu";

export default {
  name: "AdminComponent",

  components: {
    NavMenu,
  },

  data() {
    return {
      active: false
    }
  },

  methods: {
    toggleMenu() {
      this.active = !this.active
    },
  },

  computed: {
    currentRouteName() {
      return this.$store.state.var.namePage;
    },

    currentRouteNameLogin() {
      return this.$route.name == 'login';
    },

  },

};
</script>

<style scoped>
.wrapperComponent {
  width: calc(100% - 260px);
  float: right;
  transition: all 0.6s ease-in-out;
}

.menuTopBar {
  position: relative;
  background-color: #ebebeb;
  height: 75px;
  -webkit-box-shadow: -3px 44px 38px -25px rgba(184, 184, 184, 1);
  -moz-box-shadow: -3px 44px 38px -25px rgba(184, 184, 184, 1);
  box-shadow: -3px 44px 38px -25px rgba(184, 184, 184, 1);
  padding: 20px 2%;

}

.menuTopBar h1 {
  font-size: 30px;
  color: #1b1c1d;
  font-weight: 100;
  text-transform: capitalize;
  padding-left: 60px;
}

@media(max-width: 441px) {
  .menuTopBar h1 {
    font-size: 1.2rem;
    margin-top: 5px;
  }
}

.menuTopBar h1 i {
  color: #f38f0c;
  font-size: 25px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media(max-width: 991px) {
  .wrapperComponent {
    width: 100%;
  }

  .toggle {
    transform: translate3d(260px, 0, 0);
  }
}
</style>
