<template>
    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>
        <div class="clearfix mb-4" v-if="buttonAddRoute">
            <button-route class="float-end" :items="buttonRouteAdd"/>
        </div>

        <form v-if="showView">
            <div class="aux my-4">
                <div class="avatar-form">
                    <img v-if="formData.photo" :src="formData.photo" alt="" class="avatar__image">
                    <img v-else src="@/assets/images/avatar.png" alt="" class="avatar__image">
                </div>
                <p>{{ formData.name }}</p>
            </div>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError' : errors.sexo}">
                    <label>Sexo:</label>
                    <input v-model="formData.sexo" type="radio" name="sexo" id="feminino" value="1">
                    <label for="feminino">Feminino</label>

                    <input v-model="formData.sexo" type="radio" name="sexo" id="masculino" value="2">
                    <label for="masculino">Masculino</label>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError' : errors.name}">
                    <label>Nome:</label>
                    <input-component :type-input="'text'" v-model="formData.name"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError' : errors.email}">
                    <label>Email:</label>
                    <input-component :type-input="'text'" v-model="formData.email"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError' : errors.status}">
                    <label>Status:</label>
                    <Multiselect v-model="formData.status" :options="selectActiveInative" :searchable="true"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError' : errors.group_id}">
                    <label>Grupo:</label>
                    <Multiselect v-model="formData.group_id" :options="selectGroupUserList" :searchable="true"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError' : errors.status}">
                    <switch-component @checkboxSwitcher="passwordUp" text-check-box="Alterar Senha"/>
                </row-col-component>
            </row-component>

            <row-component v-if="updatePassword">
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError' : errors.password}">
                    <label>Senha:</label>
                    <input-component :type-input="'password'" v-model="formData.password"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError' : errors.passwordconfirm}">
                    <label>Confirmar Senha:</label>
                    <input-component :type-input="'password'" v-model="formData.passwordconfirm"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-submit :disaabled="sendForm" @submit="submit" :class="{'preloaderSend' : sendForm}"
                                   :disabled="sendForm"
                                   :items="buttonSubmit"
                                   style="width: 100%;"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-route :items="buttonRoute" style="width: 100%;"/>
                </row-col-component>
            </row-component>
        </form>

    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from '@/components/row/RowColComponent';
import InputComponent from '@/components/form/InputComponent';
import Multiselect from '@vueform/multiselect';
import ButtonSubmit from '@/components/buttons/ButtonSubmit';
import ButtonRoute from '@/components/buttons/ButtonRoute';
import {useToast} from "vue-toastification";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import SwitchComponent from "@/components/form/SwitchComponent";

const toast = useToast()

export default {
    name: 'UserUpdate',

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Usuário')
        this.$store.dispatch('loadGroupUserMultiSelect')
        this.checkACL()
        this.loadUser()
    },

    components: {
        SwitchComponent,
        ErrorComponent,
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        Multiselect,
        ButtonSubmit,
        ButtonRoute,
    },

    data() {
        return {
            itensOne: {
                title: "Editar",
                icon: "ti-edit"
            },

            buttonSubmit: {
                icon: 'ti-device-floppy',
                nameButton: 'Salvar',
                typeButton: 'button'
            },

            buttonRoute: {
                icon: 'ti-arrow-back-up',
                route: '/user/search',
                name: 'Voltar'
            },

            buttonRouteAdd: {
                icon: 'ti-plus',
                route: '/user/create',
            },

            buttonAddRoute: false,

            formData: {
                name: '',
                sexo: '',
                status: '',
                email: '',
                group_id: '',
                updatepassword: '',
                password: '',
                passwordconfirm: ''
            },

            updatePassword: false,

            errors: [],
            showView: false,
            sendForm: false,
            showError: false,
        }
    },

    methods: {

        passwordUp() {
            this.updatePassword = !this.updatePassword
            this.formData.updatepassword = this.updatePassword
        },

        loadUser() {
            this.$store.dispatch('loadUser', this.id)
                .then((r) => {
                    this.formData = r
                    this.formData.updatepassword = false
                }).catch(() => {
                toast.error('Erro desconhecido', {timeout: 2000})
            })
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateUser', this.formData)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success("Cadastro atualizado com sucesso", {timeout: 2000});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollTop()
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        scrollToTop() {
            window.scrollTo(0, 0);
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 2)
                .then((r) => {
                    if (r.data.indexOf('user-edit') !== -1) {
                        this.showView = true

                        if (r.data.indexOf('user-create') !== -1) {
                            this.buttonAddRoute = true
                        }

                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        selectGroupUserList() {
            return this.$store.state.var.selectGroupUserList
        },

    }

}
</script>
