import axios from "axios";
import {URL_BASE} from "@/configs/constants";

export default {
    actions: {
        loadBudgetSchedule(context, id) {
            context.commit("LOADER", true)
            return new Promise((resolve, reject) => {
                axios.get(`${URL_BASE}budget-search-schedule/${id}`)
                    .then((response) => {
                        return resolve(response.data)
                    }).catch((errors) => {
                    return reject(errors)
                }).finally(() => context.commit("LOADER", false))
            })
        },

        storeBudgetSchedule(context, formData) {
            context.commit("LOADER", true)
            return new Promise((resolve, reject) => {
                axios.post(`${URL_BASE}budget-add-schedule`, formData)
                    .then((response) => {
                        return resolve(response.data)
                    }).catch((errors) => {
                    return reject(errors)
                }).finally(() => context.commit("LOADER", false))
            })
        },

        loadBudgetShowSchedule(context, id) {
            context.commit("LOADER", true)
            return new Promise((resolve, reject) => {
                axios.get(`${URL_BASE}budget-show-schedule/${id}`)
                    .then((response) => {
                        return resolve(response.data)
                    }).catch((errors) => {
                    return reject(errors)
                }).finally(() => context.commit("LOADER", false))
            })
        },

        updateBudgetSchedule(context, formData) {
            context.commit("LOADER", true)
            return new Promise((resolve, reject) => {
                axios.put(`${URL_BASE}budget-update-schedule/${formData.id}`, formData)
                    .then((response) => {
                        return resolve(response.data)
                    }).catch((errors) => {
                    return reject(errors)
                }).finally(() => context.commit("LOADER", false))
            })
        },

        destroyBudgetSchedule(context, id) {
            context.commit("LOADER", true)
            return new Promise((resolve, reject) => {
                axios.delete(`${URL_BASE}budget-delete-schedule/${id}`)
                    .then((response) => {
                        return resolve(response.data)
                    }).catch((errors) => {
                    return reject(errors)
                }).finally(() => context.commit("LOADER", false))
            })
        },

        loadBudgetScheduleEventsMonth(context, formData) {
            context.commit("LOADER", true)
            return new Promise((resolve, reject) => {
                axios.get(`${URL_BASE}budget-show-schedule-events-month/${formData.id}/${formData.month}`)
                    .then((response) => {
                        return resolve(response.data)
                    }).catch((errors) => {
                    return reject(errors)
                }).finally(() => context.commit("LOADER", false))
            })
        },

        loadBudgetScheduleEvent(context, id) {
            context.commit("LOADER", true)
            return new Promise((resolve, reject) => {
                axios.get(`${URL_BASE}budget-show-schedule-event/${id}`)
                    .then((response) => {
                        return resolve(response.data)
                    }).catch((errors) => {
                    return reject(errors)
                }).finally(() => context.commit("LOADER", false))
            })
        },

        loadBudgetGetScheduleEvent(context, id) {
            return new Promise((resolve, reject) => {
                axios.get(`${URL_BASE}budget-get-schedule-event/${id}`)
                    .then((response) => {
                        return resolve(response.data)
                    }).catch((errors) => {
                    return reject(errors)
                })
            })
        },
    }
}