export default {
    icon: 'ti ti-file-analytics',
    name: 'Relatório',
    subLinks: [
        {
            name: 'Cliente',
            route: '/report-client/client',
            pagePermissions: [
                'report-client'
            ],
        },

        {
            name: 'Produto',
            route: '/report-product/product',
            pagePermissions: [
                'report-client'
            ],
        },

        {
            name: 'Orçamento',
            route: '/report-budget/budget',
            pagePermissions: [
                'report-budget'
            ],
        },
    ],

    pageLinksNavCheck: [
        'report-client',
        'report-product',
        'report-budget'
    ],
}
