<template>
    <div class="modal-add-sub-account" v-if="modal_account_sub">
        <div class="btn-close" @click="closeModal"></div>
        <form>
            <row-component>
                <row-col-component
                        :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <label>Nome:</label>
                    <input-component :type-input="'text'" v-model="account_type_subs.name"/>
                </row-col-component>

                <row-col-component
                        :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <label>Status</label>
                    <Multiselect v-model="account_type_subs.status" :options="selectActiveInative" :searchable="true"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <button-submit v-if="!edit" :disaabled="sendForm" @submit="submitAccountSub" :items="buttonSubmitAdd"/>
            <button-submit v-if="edit" :disaabled="sendForm" @submit="submitAccountSubEdit" :items="buttonSubmit"/>
        </form>
    </div>

    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>
        <div class="clearfix mb-4" v-if="buttonAddRoute">
            <button-route class="float-end" :items="buttonRouteAdd"/>
        </div>

        <div class="tabs effect-3" v-if="showView">
            <input checked type="radio" id="tab-1" name="tab-effect-3">
            <span>
                <i class="ti ti-info-square-rounded"></i>
                <p>Informações</p>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3">
            <span>
                <i class="ti ti-cash-banknote"></i>
                <p>Sub Tipo de Conta</p>
            </span>

            <div class="line ease"></div>
            <div class="tab-content">

                <section id="tab-item-1">
                    <form>
                        <row-component>
                            <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                               :class="{'formError' : errors.name}">
                                <label>Nome:</label>
                                <input-component :type-input="'text'" v-model="formData.name"/>
                            </row-col-component>

                            <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                               :class="{'formError' : errors.status}">
                                <label>Status:</label>
                                <Multiselect v-model="formData.status" :options="selectActiveInative" :searchable="true"
                                             :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                    :class="{'formError' : errors.obs}">
                                <label>Observações:</label>
                                <input-text-area :rows="4" v-model="formData.obs"/>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>

                <section id="tab-item-2">
                    <div class="alert alert-secondary alert-dismissible fade show"
                         v-if="formData.account_type_subs.length === 0">
                        Não há nada cadastrado
                    </div>

                    <div class="clearfix">
                        <button-submit :disabled="modal_account_sub" @click="modal_account_sub = true"
                                       :items="buttonAdd"/>
                    </div>

                    <form v-for="(data, index) in formData.account_type_subs" :key="index"
                          class="card p-3 bg-light my-3">
                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                <label>Nome:</label>
                                <input-component disabled :type-input="'text'" v-model="data.name"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'">
                                <label>Status</label>
                                <Multiselect disabled v-model="formData.status" :options="selectActiveInative"
                                             :searchable="true"
                                             :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-1 col-xl-1 col-lg-1 col-md-12 col-sm-12 col-12 mt-md-4'">
                                <div class="btn-group" role="group" aria-label="Basic example">
                                    <button-submit @click.prevent="modalEdit(data)" :items="buttonEdit"/>
                                    <button-submit @click.prevent="destroyAccountSub(data)" :items="buttonDelete"/>
                                </div>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>
            </div>
        </div>

        <row-component>
            <row-col-component
                    :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <button-submit :disaabled="sendForm" @submit="submit" :items="buttonSubmit" style="width: 100%;"/>
            </row-col-component>
            <row-col-component
                    :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <button-route :items="buttonRoute" style="width: 100%;"/>
            </row-col-component>
        </row-component>

    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from '@/components/row/RowColComponent';
import InputComponent from '@/components/form/InputComponent';
import Multiselect from '@vueform/multiselect';
import ButtonSubmit from '@/components/buttons/ButtonSubmit';
import ButtonRoute from '@/components/buttons/ButtonRoute';
import {useToast} from "vue-toastification";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import InputTextArea from "@/components/form/InputTextArea.vue";
import swal from "sweetalert";

const toast = useToast()
export default {
    name: 'AccountTypeUpdate',

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Tipo de Conta')
        this.checkACL()
        this.loadAccountType()
    },

    components: {
        InputTextArea,
        ErrorComponent,
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        Multiselect,
        ButtonSubmit,
        ButtonRoute,
    },

    data() {
        return {
            itensOne: {
                title: "Editar",
                icon: "ti-edit"
            },

            buttonSubmit: {
                icon: 'ti-device-floppy',
                nameButton: 'Salvar',
                typeButton: 'button'
            },

            buttonSubmitAdd: {
                icon: 'ti-circle-plus',
                nameButton: 'Adicionar',
                typeButton: 'button'
            },

            buttonRoute: {
                icon: 'ti-arrow-back-up',
                route: '/account-type/search',
                name: 'Voltar'
            },

            buttonAdd: {
                icon: 'ti-circle-plus',
            },

            buttonEdit: {
                icon: 'ti-edit',
            },

            buttonDelete: {
                icon: 'ti-trash',
            },

            buttonRouteAdd: {
                icon: 'ti-plus',
                route: '/account-type/create',
            },

            buttonAddRoute: false,

            formData: {
                name: '',
                status: '',
                obs: '',
                account_type_subs: []
            },

            account_type_subs: {
                account_type_id: this.id,
                name: '',
                status: ''
            },

            edit: false,
            modal_account_sub: false,

            errors: [],
            showView: false,
            sendForm: false,
            showError: false,
        }
    },

    methods: {
        loadAccountType() {
            this.$store.dispatch('loadAccountType', this.id)
                .then((r) => {
                    this.formData = r
                }).catch(() => {
                toast.error('Erro desconhecido', {timeout: 2000})
            })
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateAccountType', this.formData)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success("Cadastro atualizado com sucesso", {timeout: 2000});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollToTop()
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        modalEdit(data) {
            this.account_type_subs.id = data.id
            this.account_type_subs.name = data.name
            this.account_type_subs.status = data.status
            this.modal_account_sub = true
            this.edit = true
        },

        closeModal() {
            this.modal_account_sub = false
            this.account_type_subs.name = ''
            this.account_type_subs.status = ''
            this.edit = false
        },

        submitAccountSub() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('storeAccountTypeSub', this.account_type_subs)
                    .then(() => {
                        this.modal_account_sub = false
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        this.loadAccountType()
                        toast.success("Sub conta adicionada com sucesso", {timeout: 2000});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollToTop()
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        submitAccountSubEdit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateAccountTypeSub', this.account_type_subs)
                    .then(() => {
                        this.modal_account_sub = false
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        this.loadAccountType()
                        toast.success("Sub conta atualizada com sucesso", {timeout: 2000});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollToTop()
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        destroyAccountSub(item) {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar ${item.name}, ${this.me.name} ?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,

                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    this.$store.dispatch('destroyAccountTypeSub', item.id).then(() => {
                        this.loadUsers(1)
                        toast.success('Cadastro deletado com sucesso', {timeout: 2000});
                    })
                        .catch(() => {
                            toast.error(`Não foi possivel deletar  ${item.name} `, {timeout: 2000});
                        })
                }
            });
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 14)
                .then((r) => {
                    if (r.data.indexOf('account-type-edit') !== -1) {
                        this.showView = true

                        if (r.data.indexOf('account-type-create') !== -1) {
                            this.buttonAddRoute = true
                        }

                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        scrollToTop() {
            window.scrollTo(0, 0);
        },
    },

    computed: {
        me() {
            return this.$store.state.auth.me
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

    }

}
</script>

<style scoped>
.tabs > input,
.tabs > span,
.effect-3 .line {
    width: 50%;
}

#tab-2,
#tab-2 + span,
#tab-2:checked ~ .line {
    left: 50%;
}

.btn-group {
    height: 46px;
}

.modal-add-sub-account .btn-close {
    top: 0;
    right: 0;
    margin: 1%;
    cursor: pointer;
    position: absolute;
}

.modal-add-sub-account {
    top: 50%;
    left: 50%;
    width: 40%;
    z-index: 9999;
    position: fixed;
    min-height: 20%;
    margin-right: -50%;
    padding: 40px 20px;
    overflow-y: auto;
    background: #FFFFFF;
    transform: translate(-50%, -50%);
    box-shadow: 10px 10px 23px 0 rgb(204 204 204);
}

@media (max-width: 991px) {
    .modal-add-sub-account {
        width: 90%;
    }
}
</style>
