<template>
  <painel-component :itens="itensOne">
    <row-component>
      <row-col-component :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'">
        <label>Nome:</label>
        <input-component :type-input="'text'" v-model="formData.name"/>
      </row-col-component>

      <row-col-component :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'">
        <label>Status:</label>
        <Multiselect v-model="formData.status" :options="selectActiveInative" :searchable="true"
                     :noOptionsText="noOptionsText"
                     :noResultsText="noResultsText"/>
      </row-col-component>

      <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mt-4'">
        <button-submit @submit="submit" :items="buttonSubmit" class="me-2"/>
        <button-route v-if="buttonCheck.create" :items="buttonRoute"/>
      </row-col-component>
    </row-component>

    <table class="table table-striped">
      <thead>
      <tr>
        <th scope="col" style="width: 35%">Nome</th>
        <th scope="col" style="width: 35%">Status</th>
        <th scope="col" style="width: 10%" v-if="buttonCheck.show || buttonCheck.edit || buttonCheck.delete">
          Ações
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(data, index) in nameBudget.data" :key="index">
        <td data-label="Nome">{{ data.name }}</td>
        <td data-label="Status">{{ statusString(data.status) }}</td>
        <td data-label="Ações" v-if="buttonCheck.edit || buttonCheck.show || buttonCheck.delete">
          <button-edit v-if="buttonCheck.edit" :route="'name-budget-edit'" :id-param="data.id"/>
          <button-delete v-if="buttonCheck.delete" @delete="destroyItem(data)"/>
          <button-show v-if="buttonCheck.show" :route="'name-budget-show'" :id-param="data.id"/>
        </td>
      </tr>
      </tbody>
    </table>

    <button-pagination :pagination="nameBudget" :offset="3" @paginate="loadItens"/>

  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from '@/components/row/RowColComponent';
import InputComponent from '@/components/form/InputComponent';
import Multiselect from '@vueform/multiselect';
import ButtonSubmit from '@/components/buttons/ButtonSubmit';
import ButtonRoute from '@/components/buttons/ButtonRoute';
import ButtonEdit from '@/components/buttons/ButtonEdit';
import {useToast} from "vue-toastification";
import ButtonPagination from "@/components/buttons/ButtonPagination.vue";
import ButtonShow from "@/components/buttons/ButtonShow.vue";
import ButtonDelete from "@/components/buttons/ButtonDelete.vue";
import swal from "sweetalert";

const toast = useToast()

export default {
  name: 'NameBudgetSearch',

  created() {
    this.$store.dispatch('updateNamePage', 'Nome  orçamento ')
    this.checkACL()
    this.loadItens(1)
  },

  components: {
    ButtonDelete,
    ButtonShow,
    ButtonPagination,
    PainelComponent,
    RowComponent,
    RowColComponent,
    InputComponent,
    Multiselect,
    ButtonSubmit,
    ButtonRoute,
    ButtonEdit,
  },

  data() {
    return {
      itensOne: {
        title: "Pesquisa",
        icon: "ti-list"
      },

      buttonSubmit: {
        icon: 'ti-search',
      },

      buttonRoute: {
        icon: 'ti-plus',
        route: '/name-budget/create'
      },

      buttonCheck: {
        edit: false,
        create: false,
        show: false,
        delete:false
      },

      formData: {
        name: '',
        status: ''
      },

      searchSubmit: false,
    }
  },

  methods: {

    submit() {
      this.loadItens(1)
      this.searchSubmit = true
    },

    statusString(value) {
      if (value === '1')
        return 'Ativo'
      return 'Inativo'
    },

    loadItens(page) {
      if (!this.searchSubmit) {
        this.$store.dispatch('loadNameBudgets', {...this.params, page})
            .then((r) => {
              if (this.searchSubmit) {
                this.searchSubmit = false;
                if (r.data == '') {
                  toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 2000});
                }
              }
            }).catch(() => {
          this.searchSubmit = false;
        });
      }
    },

    destroyItem(data){
    swal({
        title: 'Atenção!',
        text: `Deseja realmente deletar ${data.name}, ${this.me.name} ?`,
        closeOnClickOutside: false,
        buttons: {
          cancel: {
            text: 'Não',
            visible: true,
            closeModal: true,

          },
          confirm: "Sim"
        }
      }).then((result) => {
        if (result) {
          this.$store.dispatch('destroyNameBudget', data.id).then(() => {
            this.loadItens(1)
            toast.success('Cadastro deletado com sucesso', {timeout: 2000});
          })
              .catch((r) => {
                let msg = r.response.data.errors[0]
                toast.error(` ${msg}  `, {timeout: 6000});
              })
        }
      });

    },

    async checkACL() {
      this.$store.dispatch('ACLitens', 23)
          .then((r) => {
            if (r.data.indexOf('name-budget-search') !== -1) {

              if (r.data.indexOf('name-budget-search') !== -1) {
                this.buttonCheck.show = true
              }

              if (r.data.indexOf('name-budget-create') !== -1) {
                this.buttonCheck.create = true
              }

              if (r.data.indexOf('name-budget-edit') !== -1) {
                this.buttonCheck.edit = true
              }

              if (r.data.indexOf('name-budget-delete') !== -1) {
                this.buttonCheck.delete = true
              }


            } else {
              this.$router.push({name: 'home'})
            }
          }).catch(() => {
        this.$router.push({name: 'home'})
      })
    },

  },

  computed: {
    selectActiveInative() {
      return this.$store.state.var.selectActiveInative
    },

    noResultsText() {
      return this.$store.state.var.noResultsText
    },

    noOptionsText() {
      return this.$store.state.var.noOptionsText
    },

    nameBudget() {
      return this.$store.state.name_budget.items
    },

    params() {
      return {
        page: this.nameBudget.current_page,
        name: this.formData.name,
        status: this.formData.status
      }
    },

    me() {
      return this.$store.state.auth.me
    },
  }

}
</script>

<style src="@/assets/style/table.css">

</style>