<template>
    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError" :class="'mb-5'"></error-component>

        <div class="aux my-4">
            <div class="avatar-form">
                <img v-if="formData.photo" :src="imagePreview" class="avatar__image" alt="">
                <img v-else src="@/assets/images/avatar.png" class="avatar__image" alt="">
            </div>
            <p>{{ formData.nameClient }}</p>
        </div>

        <form autocomplete="off">
            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <label>Foto:</label>
                    <input type="file" name="file" id="formFile" @change="onFileChange">
                    <label for="formFile" class="labelFile">
                        <span>{{ this.namePhoto }}</span>
                        <span><i class="ti ti-camera"></i></span>
                    </label>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component
                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <button-route :items="buttonRoute" style="width: 100%;"/>
                </row-col-component>
            </row-component>
        </form>

    </painel-component>
</template>

<script>

import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import {useToast} from "vue-toastification";
import ButtonRoute from "@/components/buttons/ButtonRoute";

const toast = useToast();

export default {
    name: "ProductUpdatePhoto",

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Produtos')
        this.loadPageItens();
    },

    components: {
        ButtonRoute,
        PainelComponent,
        RowComponent,
        RowColComponent,
        ErrorComponent,
    },

    data() {
        return {
            itensOne: {
                title: "Alterar Foto",
                icon: "ti ti-edit"
            },

            buttonRoute: {
                icon: 'ti-arrow-back-up',
                route: `/product/edit/${this.id}`,
                name: 'Voltar'
            },

            formData: {
                photo: '',
                name: '',
                id: this.id
            },

            imagePreview: "",
            namePhoto: '',

            errors: [],
            showError: false,
            sendForm: false,
        }
    },

    methods: {
        loadPageItens() {
            this.$store.dispatch('loadProduct', this.id).then((r) => {
                this.formData.photo = r.photo;
                this.formData.nameClient = r.name;
            }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 2000});
            })
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateProductPhoto', this.formData)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        toast.success("Foto atualizada com sucesso", {timeout: 2000});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        onFileChange(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length)
                return
            this.formData.photo = files[0]
            this.namePhoto = this.formData.photo.name
            if (files[0].type.indexOf('image') === 0)
                this.previewImage(files[0])
            this.submit()
        },

        previewImage(file) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.imagePreview = e.target.result
            }
            reader.readAsDataURL(file)
        },

        photoPreviewTimer() {
            this.imagePreview = this.formData.photo
        },

    },

    beforeMount() {
        setTimeout(() => this.photoPreviewTimer(), 1000);
    },
}
</script>

<style scoped>
/* Personalização do Input File */
input[type="file"] {
    display: none;
}

.labelFile {
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    color: #000;
    border: 1px solid #ddd;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    cursor: pointer;
}

.labelFile span:nth-child(2) {
    padding: 15px;
    color: #fff;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #36c1bd;
    cursor: pointer;
}
</style>

