<template>
    <router-link :to="items.route" class="btn btn-secondary buttonRound">
        <i :class="'ti ' + items.icon"></i>
        {{ items.name }}
    </router-link>
</template>
  
<script>
export default {
    name: "ButtonRoute",
    props: {
        items: {
            "required": true,
            "type": Object,
            "default": []
        },
    },
}
</script>

<style scoped>
.buttonRound i {
    padding-top: 5px;
}
</style>