<template>
    <painel-component :itens="itemsOne">
        <row-component>
            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                <label>Código:</label>
                <input-component v-model="formData.code" type-input="text"/>
            </row-col-component>

            <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                <label>Nome:</label>
                <input-component v-model="formData.name" type-input="text"/>
            </row-col-component>

            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                <label>Status:</label>
                <Multiselect v-model="formData.status" :options="selectActiveInative"
                             :searchable="true" :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
            </row-col-component>

            <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mt-0 mt-lg-4">
                <button-submit @submit="submit" class="me-2" :items="btnSubmit"/>
            </row-col-component>
        </row-component>

        <table class="table table-striped">
            <thead>
            <tr>
                <th scope="col" style="width: 5%">Código</th>
                <th scope="col" style="width: 20%">Participantes</th>
                <th scope="col" style="width: 20%">Nome</th>
                <th scope="col" style="width: 10%" v-if="buttonCheck.edit">
                    Ações
                </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(data, index) in my_schedule.data" :key="index">
                <td data-label="Código">{{ data.code }}</td>
                <td data-label="Participantes">
                    <div class="c-profile__list">
                        <a v-for="image in data.guests" :key="image" class="c-profile"
                           :data-user="image.guest_name"
                           :style="{ backgroundImage: 'url(' + image.guest_photo + ')' }">
                        </a>
                    </div>
                </td>
                <td data-label="Nome">{{ data.name }}</td>
                <td data-label="Ações" v-if="buttonCheck.edit">
                    <button-edit v-if="buttonCheck.edit" :route="'my-schedule-edit'" :id-param="data.id"/>
                </td>
            </tr>
            </tbody>
        </table>

        <button-pagination :pagination="my_schedule" :offset="3" @paginate="loadMySchedules"/>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import {useToast} from "vue-toastification";
import ButtonEdit from "@/components/buttons/ButtonEdit";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonPagination from "@/components/buttons/ButtonPagination";

const toast = useToast()

export default {
    name: "MyScheduleSearch",
    components: {
        ButtonPagination,
        ButtonSubmit,
        ButtonEdit,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Minha agenda')
        this.loadMySchedules()
        this.checkACL()
    },

    data() {
        return {
            itemsOne: {
                title: "Pesquisa",
                icon: "ti-list"
            },

            btnSubmit: {
                icon: 'ti-search',
            },

            buttonCheck: {
                edit: false,
            },

            formData: {
                code: "",
                name: "",
                status: ""
            }
        }
    },

    methods: {
        submit() {
            this.loadMySchedules(1)
            this.searchSubmit = true
        },

        loadMySchedules(page) {
            if (!this.searchSubmit) {
                this.$store.dispatch('loadMySchedules', {...this.params, page})
                    .then((r) => {
                        if (this.searchSubmit) {
                            this.searchSubmit = false;
                            if (r.data.length === 0) {
                                toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 2000});
                            }
                        }
                    }).catch(() => {
                    this.searchSubmit = false;
                });
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 20)
                .then((r) => {
                    if (r.data.indexOf('my-schedule-search') !== -1) {
                        if (r.data.indexOf('my-schedule-edit') !== -1) {
                            this.buttonCheck.edit = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        my_schedule() {
            return this.$store.state.my_schedule.items
        },

        params() {
            return {
                page: this.my_schedule.current_page,
                code: this.formData.code,
                name: this.formData.name,
                status: this.formData.status,
            }
        }
    }
}
</script>

<style src="@/assets/style/table.css">

</style>

<style scoped>
.c-profile {
    width: 50px;
    height: 50px;
    cursor: pointer;
    border-radius: 50%;
    margin-bottom: 5px;
    display: inline-block;
    border: 4px solid white;
    background: #FFFFFF center center;
    background-size: cover;
    transition: all 200ms ease;
    box-shadow: 0 3px 8px rgba(44, 44, 84, 0.2);
}

.c-profile:nth-of-type(n+2) {
    margin-left: -42px;
}

.c-profile:hover {
    transform: scale(1.2);
    box-shadow: 0 8px 20px rgba(44, 44, 84, 0.2);
}

.c-profile:hover:after {
    top: 35px;
    opacity: 0;
    padding: 5px;
    width: 100px;
    color: #2C3E50;
    font-size: 10px;
    position: absolute;
    border-radius: 0.15rem;
    content: attr(data-user);
    background: rgba(255, 255, 255, 0.95);
    animation: fade 100ms ease 100ms forwards;
    box-shadow: 0 5px 12px rgb(0 0 0 / 12%);
    -webkit-animation: fade 50ms ease 50ms forwards;
}

.c-profile__list {
    z-index: 1;
    width: auto;
    position: relative;
    display: inline-flex;
}

.c-profile__list:hover .c-profile:nth-of-type(n+2) {
    margin-left: 7px;
}

@-webkit-keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
</style>