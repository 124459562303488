<template>
    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>

        <form v-if="showView">
            <div class="aux my-4">
                <div class="avatar-form">
                    <img id="image-car" v-if="formData.fleet_photo" :src="formData.fleet_photo" alt=""
                         class="avatar__image">
                    <img id="image-car" v-else src="@/assets/images/avatar.png" alt="" class="avatar__image">
                </div>
                <p>{{ formData.name }}</p>
            </div>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError' : errors.brand_model}">
                    <label>Marca / Modelo:</label>
                    <input-component disabled :type-input="'text'" v-model="formData.brand_model"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError' : errors.reindeer}">
                    <label>Renavam:</label>
                    <input-component disabled :type-input="'text'" v-model="formData.reindeer"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError' : errors.chassis}">
                    <label>Chassi:</label>
                    <input-component disabled :type-input="'text'" v-model="formData.chassis"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError' : errors.document}">
                    <label>Nº Documento:</label>
                    <input-component disabled :type-input="'text'" v-model="formData.document"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError' : errors.color}">
                    <label>Cor:</label>
                    <input-component disabled :type-input="'text'" v-model="formData.color"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError' : errors.board}">
                    <label>Placa:</label>
                    <input-component disabled :type-input="'text'" v-model="formData.board"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError' : errors.fuel}">
                    <label>Tipo de Combustível:</label>
                    <Multiselect disabled v-model="formData.fuel" :options="selectTypeFuelList" :searchable="true"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError' : errors.km}">
                    <label>KM:</label>
                    <input-component disabled min="1" :type-input="'number'" v-model="formData.km"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError' : errors.status}">
                    <label>Status:</label>
                    <Multiselect disabled v-model="formData.status" :options="selectActiveInative" :searchable="true"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <button-route :items="buttonRoute" style="width: 100%;"/>
                </row-col-component>
            </row-component>

            <div class="line-division">
                <h1 class="h1-text"><i class="ti ti-brand-line"></i> Observações</h1>
            </div>

            <div class="alert alert-secondary alert-dismissible fade show" v-if="formData.obsPrivate.length === 0">
                Não há nada cadastrado
            </div>

            <div v-else>
                <div class="card p-3 bg-light my-3 overflow-auto    " v-for="(data, index) in formData.obsPrivate"
                     :key="index">
                    <div>
                        <img v-if="data.user_photo" class="avatar__image mx-1" :src="data.user_photo" alt="">
                        <img v-else class="avatar__image mx-1" src="@/assets/images/avatar.png" alt="">
                        {{ data.user_name }}

                        <p class="d-inline-block mx-3"><i class="ti ti-calendar-due"></i> {{ data.date_create }}</p>
                        <p class="d-inline-block mx-3"><i class="ti ti-clock-filled"></i> {{ data.hour_create }}</p>
                    </div>
                    <p>{{ data.obs }}</p>
                </div>
            </div>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-col-xl-col-lg-col-md-12 col-sm-12 col-12'">
                    <label>Observações</label>
                    <input-text-area :rows="6" v-model="formDataObs.obs"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <button-submit @submit="submitObs" :items="buttonSubmitObs" style="width: 100%;"/>
                </row-col-component>
            </row-component>
        </form>

    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from '@/components/row/RowColComponent';
import InputComponent from '@/components/form/InputComponent';
import Multiselect from '@vueform/multiselect';
import ButtonSubmit from '@/components/buttons/ButtonSubmit';
import ButtonRoute from '@/components/buttons/ButtonRoute';
import ErrorComponent from "@/components/alerts/ErrorComponent";
import {useToast} from "vue-toastification";
import InputTextArea from "@/components/form/InputTextArea.vue";

const toast = useToast()
export default {
    name: 'FleetShow',

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Frota')
        this.checkACL()
        this.loadFleet()
    },

    components: {
        InputTextArea,
        ErrorComponent,
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        Multiselect,
        ButtonSubmit,
        ButtonRoute,
    },

    data() {
        return {
            itensOne: {
                title: "Ver",
                icon: "ti-eye"
            },

            buttonSubmit: {
                icon: 'ti-device-floppy',
                nameButton: 'Salvar',
                typeButton: 'button'
            },

            buttonSubmitObs: {
                icon: "ti-circle-plus",
                nameButton: 'Adicionar Observação',
                typeButton: 'button'
            },

            buttonRoute: {
                icon: 'ti-arrow-back-up',
                route: '/fleet/search',
                name: 'Voltar'
            },

            formData: {
                brand_model: '',
                reindeer: '',
                color: '',
                chassis: '',
                document: '',
                board: '',
                fuel: '',
                km: '',
                status: '',
                obsPrivate: []
            },

            formDataObs: {
                id: this.id,
                obs: ''
            },

            errors: [],
            showView: false,
            sendForm: false,
            showError: false,
        }
    },

    methods: {

        loadFleet() {
            this.$store.dispatch('loadFleet', this.id)
                .then((r) => {
                    this.formData = r
                }).catch(() => {
                toast.error('Erro desconhecido', {timeout: 2000})
            })
        },

        submitObs() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('storeFleetObs', this.formDataObs)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        this.loadFleet()
                        toast.success("Observação adicionada com sucesso", {timeout: 2000});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollToTop()
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 5)
                .then((r) => {
                    if (r.data.indexOf('fleet-search') !== -1) {
                        this.showView = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

    },

    computed: {
        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        selectTypeFuelList() {
            return this.$store.state.var.selectTypeFuelList
        },
    }

}
</script>

<style scoped>
.line-division {
    width: 100%;
    border-top: 1px solid #c4c4c4;
}

.h1-text {
    margin-top: 1px;
    width: fit-content;
    padding: 10px;
    background-color: #36c1bd;
    color: white;
    font-size: 16px;
}

#image-car {
    width: 100%;
}

.avatar__image {
    width: 25px;
}
</style>
