import AdminComponent from '@/components/dashboard/AdminComponent'
import NameBudgetSearch from "@/views/search/NameBudgetSearch.vue";
import NameBudgetCreate from "@/views/create/NameBudgetCreate.vue";
import NameBudgetUpdate from "@/views/update/NameBudgetUpdate.vue";
import NameBudgetShow from "@/views/show/NameBudgetShow.vue";


export default {
    path: '/name-budget',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'search', name: 'name-budget-search', component: NameBudgetSearch},
        {path: 'create', name: 'name-budget-create', component: NameBudgetCreate},
        {path: 'edit/:id', name: 'name-budget-edit', component: NameBudgetUpdate, props: true},
        {path: 'show/:id', name: 'name-budget-show', component: NameBudgetShow, props: true},
    ]
}