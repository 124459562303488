import AdminComponent from "@/components/dashboard/AdminComponent"
import UserSearch from "@/views/search/UserSearch"
import UserCreate from "@/views/create/UserCreate"
import UserUpdate from "@/views/update/UserUpdate";
import UserShow from "@/views/show/UserShow";
import UserUpdatePassword from "@/views/update/UserUpdatePassword";
import UserUpdatePhoto from "@/views/update/UserUpdatePhoto";

export default {
    path: '/user',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'search', name: 'user-search', component: UserSearch},
        {path: 'create', name: 'user-create', component: UserCreate},
        {path: 'edit/:id', name: 'user-edit', component: UserUpdate, props: true},
        {path: 'show/:id', name: 'user-show', component: UserShow, props: true},
        {path: 'user-password', name: 'user-edit-password', component: UserUpdatePassword},
        {path: 'user-photo', name: 'user-edit-photo', component: UserUpdatePhoto},
    ]
}
