import AdminComponent from '@/components/dashboard/AdminComponent'
import CategorySearch from "@/views/search/CategorySearch";
import CategoryCreate from "@/views/create/CategoryCreate";
import CategoryUpdate from "@/views/update/CategoryUpdate";
import CategoryShow from "@/views/show/CategoryShow";

export default {
    path: '/category',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'search', name: 'category-search', component: CategorySearch},
        {path: 'create', name: 'category-create', component: CategoryCreate},
        {path: 'edit/:id', name: 'category-edit', component: CategoryUpdate, props: true},
        {path: 'show/:id', name: 'category-show', component: CategoryShow, props: true},
    ]
}