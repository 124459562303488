export default {
    icon: 'ti ti-box-seam',
    name: 'Estoque',
    subLinks: [

        {
            name: 'Entrada / Saída',
            route: '/inventory/search',
            pagePermissions: [
                'inventorie-search',
                'inventorie-create',
            ],
        },

        {
            name: 'Produto',
            route: '/product/search',
            pagePermissions: [
                'product-search',
                'product-create',
                'product-edit',
                'product-delete'
            ],
        },

    ],

    pageLinksNavCheck: [
        'inventorie-search',
        'inventorie-create',
        'product-search',
        'product-create',
        'product-edit',
        'product-delete'
    ],
}