import AdminComponent from '@/components/dashboard/AdminComponent'
import EntranceExitSearch from "@/views/search/EntranceExitSearch";
import EntranceExitCreate from "@/views/create/EntranceExitCreate";

export default {
    path: '/entrance-exit',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'search', name: 'entrance-exit-search', component: EntranceExitSearch},
        {path: 'create', name: 'entrance-exit-create', component: EntranceExitCreate},
    ]
}