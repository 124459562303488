<template>
    <painel-component :itens="itemsOne">
        <error-component v-if="showError" :errors="errors"/>

        <form v-if="showView">
            <row-component>
                <row-col-component class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                    <label>Nome:</label>
                    <input-component v-model="formData.name" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Status:</label>
                    <Multiselect v-model="formData.status" :options="selectActiveInative"
                                 :searchable="true" :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <Multiselect v-model="formData.participants" mode="tags" track-by="name" label="name"
                                 :options="selectUserList" :searchable="true" :close-on-select="false"
                                 :noResultsText="noResultsText" :noOptionsText="noResultsText"
                                 :search="true">
                        <template v-slot:tag="{ option, handleTagRemove, disabled }">
                            <div class="multiselect-tag is-user" :class="{ 'is-disabled': disabled }">
                                <img :src="option.icon" alt="">
                                {{ option.name }}
                                <span v-if="!disabled" class="multiselect-tag-remove"
                                      @mousedown.prevent="handleTagRemove(option, $event)">
                                    <span class="multiselect-tag-remove-icon"></span>
                                </span>
                            </div>
                        </template>

                        <template v-slot:option="{ option }">
                            <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                        </template>
                    </Multiselect>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-submit @submit="submit" :disabled="sendForm" class="w-100" :items="btnSubmit"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="btnRoute"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "ScheduleCreate",
    components: {
        ButtonRoute,
        ButtonSubmit,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        ErrorComponent,
        PainelComponent
    },

    created() {
        this.$store.dispatch("loadUserMultiSelect")
        this.$store.dispatch('updateNamePage', 'Agendas')
        this.checkACL()
    },

    data() {
        return {
            itemsOne: {
                title: "Adicionar",
                icon: "ti-pencil-plus"
            },

            btnSubmit: {
                icon: 'ti-circle-plus',
                nameButton: 'Adicionar',
                typeButton: 'button'
            },

            btnRoute: {
                icon: 'ti-arrow-back-up',
                route: '/schedule/search',
                name: 'Voltar'
            },

            formData: {
                name: "",
                status: "",
                participants: []
            },

            errors: [],
            showView: false,
            sendForm: false,
            showError: false,
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('storeSchedule', this.formData)
                    .then((r) => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success("Cadastro efetuado com sucesso", {timeout: 2000});
                        this.$router.push({name: 'schedule-edit', params: {id: r.data}})
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollToTop()
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 19)
                .then((r) => {
                    if (r.data.indexOf('schedule-create') !== -1) {
                        this.showView = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        selectUserList() {
            return this.$store.state.var.selectUserList
        },
    }
}
</script>