<template>
  <div class="modal-search-budget-template" v-if="modalBudgetTemplate">
    <div class="btn-close" @click="modalBudgetTemplate = !modalBudgetTemplate"></div>
    <row-component>
      <row-col-component
          :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
        <label>Modelo de Orçamento:</label>
        <Multiselect v-model="budget_template_id" :options="selectBudgetTemplateList" :searchable="true"
                     @select="loadBudgetTemplateGetMultiSelect(budget_template_id)"
                     :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
      </row-col-component>
    </row-component>

    <button-submit @click="modalSearchBudgetTemplate" :items="buttonConfirm"/>
  </div>

  <div class="modal-search-client" v-if="modalClient">
    <div class="btn-close" @click="modalClient = !modalClient"></div>
    <row-component>
      <row-col-component
          :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
        <input v-model="client.type" type="radio" name="docs" id="tp-one" value="1">
        <label for="tp-one">CPF:</label>

        <input v-model="client.type" type="radio" name="docs" id="tp-two" value="2" checked>
        <label for="tp-two">CNPJ:</label>
      </row-col-component>

      <row-col-component
          :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
        <label>Nome:</label>
        <input-component v-model="client.name" type-input="text"/>
      </row-col-component>

      <row-col-component v-if="client.type === '1'"
                         :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
        <label>CPF:</label>
        <input-component v-mask="'###.###.###-##'" v-model="client.cpf" type-input="text"/>
      </row-col-component>

      <row-col-component v-if="client.type === '2'"
                         :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
        <label>CNPJ:</label>
        <input-component v-mask="'##.###.###/####-##'" v-model="client.cnpj" type-input="text"/>
      </row-col-component>

      <row-col-component :class-row-col="'col-xxl-1 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mt-0 mt-xxl-4'">
        <button-submit @click="submitSearch" :items="buttonSearch"/>
      </row-col-component>
    </row-component>

    <div class="mt-4">
      <row-component>
        <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                           v-for="(data, index) in object.data" :key="index">
          <div class="d-flex align-items-center bg-body-secondary p-2 position-relative">
            <div class="h-100 avatar__image__div">
              <img class="avatar__image w-100" v-if="data.photo" :src="data.photo" alt="">
              <img class="avatar__image w-100" v-else src="@/assets/images/avatar.png" alt="">
            </div>
            <div class="ms-3 w-75">
              <p class="mb-1 text-truncate">{{ data.surname }}</p>
              <p class="mb-1">{{ data.cnpj }} {{ data.cpf }}</p>
            </div>
            <div class="position-absolute end-0 me-1 me-lg-3">
              <button-submit @click="selectClient(data)" :items="buttonAdd"/>
            </div>
          </div>
        </row-col-component>
      </row-component>

      <button-pagination :pagination="object" :offset="3" @paginate="loadItems"/>
    </div>
  </div>

  <div class="modal-search-phone" v-if="modalPhone">
    <div class="btn-close" @click="modalPhone = !modalPhone"></div>
    <row-component>
      <row-col-component
          :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
        <label>Telefone:</label>
        <Multiselect v-model="formData.phone" :options="selectClientPhoneList" :searchable="true"
                     :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
      </row-col-component>
    </row-component>

    <button-submit @click="modalSearchClientPhone" :items="buttonConfirm"/>
  </div>

  <painel-component :itens="itensOne">
    <error-component :errors="errors" v-if="showError"/>
    <div class="clearfix">
      <button-submit tooltip="Buscar modelo de orçamento" @click="modalSearchBudgetTemplate"
                     class="fs-5 float-end" :items="buttonSearch"/>
    </div>

    <form>
      <row-component>
        <row-col-component
            :class-row-col="'col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12'"
            :class="{'formError' : errors.name_budget_id}">

          <label>Nome Orçamento:</label>
          <Multiselect v-model="formData.name_budget_id" :options="selectNameBudgetList" :searchable="true"
                       :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>

        </row-col-component>

        <row-col-component
            :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mt-md-4'"
            :class="{'formError' : errors.show_value}">
          <label>Exibir Valores?</label>

          <input checked v-model="formData.show_value" type="radio" name="docs" id="value-no" value="1">
          <label for="value-no">Não</label>

          <input v-model="formData.show_value" type="radio" name="docs" id="value-yes" value="2">
          <label for="value-yes">Sim</label>
        </row-col-component>
      </row-component>

      <row-component>
        <row-col-component
            :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
            :class="{'formError' : errors.client_name}">
          <label>Cliente:</label>
          <div class="input-group">
            <input disabled v-model="formData.client_name" type="text" class="form-control">
            <span @click="modalSearchClient" class="buttonSubmit input-group-text">
                            <i class="ti ti-search"></i>
                        </span>
          </div>
        </row-col-component>

        <row-col-component
            :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
            :class="{'formError' : errors.valid}">
          <label>Dias Válido:</label>
          <input-component min="15" :type-input="'number'" v-model="formData.valid"/>
        </row-col-component>

        <row-col-component
            :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
            :class="{'formError' : errors.phone}">
          <label>Telefone:</label>
          <div class="input-group">
            <input v-mask="['(##) ####-####', '(##) #####-####']" v-model="formData.phone" type="text"
                   class="form-control">
            <button type="button" :disabled="formData.client_id === '' || formData.client_name === ''"
                    :tooltip="formData.client_id === '' ? 'Selecione um cliente cadastrado' : '' || formData.client_name === '' ? 'Selecione um cliente cadastrado' : ''"
                    @click="modalSearchClientPhone" class="buttonSubmit input-group-text">
              <i class="ti ti-search"></i>
            </button>
          </div>
        </row-col-component>
      </row-component>

      <div class="clearfix">
        <button-submit @click="addProduct" class="fs-5 float-end" :items="buttonAdd"/>
      </div>
      
      <div class="card p-3 bg-light my-3" v-for="(data, index) in productAdd" :key="index">
        <row-component>
          <row-col-component
              :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
            <label>Marca:</label>
            <Multiselect v-model="data.brand_id" :options="selectBrandList" :searchable="true"
                         @select="loadCategoryBrandList(data.brand_id, index)"
                         :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
          </row-col-component>

          <row-col-component
              :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
            <label>Categoria:</label>
            <input-component v-if="data.brand_id === '' || data.brand_id === null" disabled
                             :type-input="'text'"/>
            <Multiselect v-else v-model="data.categorie_id" :options="selectCategoryBrandList"
                         :searchable="true" @clear="clearBrand(index)"
                         @select="loadProductBrandMultiSelect(data.brand_id, data.categorie_id, index)"
                         :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
          </row-col-component>

          <row-col-component
              :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
            <label>Produto:</label>
            <input-component v-if="data.categorie_id === '' || data.categorie_id === null" disabled
                             :type-input="'text'"/>
            <Multiselect v-else label="name" v-model="data.product_id" :options="selectProductBrandList"
                         @select="loadProductPriceMultiSelect(data.product_id, index)"
                         @clear="clearProduct(index)"
                         :searchable="true"
                         :noOptionsText="'Selecione uma categoria'" :noResultsText="noResultsText"/>
          </row-col-component>

          <row-col-component
              :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
            <label>Quantidade:</label>
            <input-component @keyup="calcProduct(index)" min="1" :type-input="'number'"
                             v-model="data.amount"/>
          </row-col-component>

          <row-col-component
              :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
            <label>Preço UN:</label>
            <input-component v-money="money" @keyup="calcProduct(index)" :type-input="'text'"
                             v-model="data.unit_price"/>
          </row-col-component>

          <row-col-component
              :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
            <label>Preço Total:</label>
            <input-component disabled :type-input="'text'" v-model="data.total_price"/>
          </row-col-component>

          <div class="clearfix" v-if="formData.products.length >= 2">
            <button-delete @click="productRemove(index)" class="fs-5 float-end"/>
          </div>
        </row-component>
      </div>

      <div class="clearfix">
        <button-submit @click="addService" class="fs-5 float-end" :items="buttonAdd"/>
      </div>
      
      <div class="card p-3 bg-light my-3" v-for="(data, index) in serviceAdd" :key="index">
        <row-component>
          <row-col-component
              :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
            <label>Serviço:</label>
            <Multiselect v-model="data.service_id" :options="selectServiceList"
                         @select="loadServicePriceMultiSelect(data.service_id, index)"
                         @clear="clearService(index)"
                         :searchable="true"
                         :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
          </row-col-component>

          <row-col-component
              :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
            <label>Quantidade:</label>
            <input-component @keyup="calcService(index)" min="1" :type-input="'number'"
                             v-model="data.amount"/>
          </row-col-component>

          <row-col-component
              :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
            <label>Preço UN:</label>
            <input-component v-money="money" @keyup="calcService(index)" :type-input="'text'"
                             v-model="data.unit_price"/>
          </row-col-component>

          <row-col-component
              :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
            <label>Preço Total:</label>
            <input-component disabled :type-input="'text'" v-model="data.total_price"/>
          </row-col-component>
        </row-component>

        <div class="clearfix" v-if="formData.services.length >= 2">
          <button-delete @click="serviceRemove(index)" class="fs-5 float-end"/>
        </div>
      </div>

      <div style="background: #c9c9c9" class="p-2 my-3 text-end fw-bold">
        Total: R$ {{ this.totalResult }}
      </div>

      <row-component>
        <row-col-component
            :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
            :class="{'formError' : errors.obs}">
          <label>Observações:</label>
          <input-text-area :rows="4" v-model="formData.obs"/>
        </row-col-component>
      </row-component>

      <div class="clearfix mt-3">
        <button-submit @click="addPaymentForms" class="fs-5 float-end" :items="buttonAdd"/>
      </div>

      <div class="card p-3 bg-light my-3" v-for="(data, index) in paymentFormsAdd" :key="index">
              <row-component>
                <row-col-component
                    class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                  <label>Forma de pagamento:</label>
                  <Multiselect :searchable="true" :noOptionsText="noOptionsText"
                               v-model="data.form_of_payment"
                               @select="calcDiscount(data.form_of_payment, index)"
                               :options="selectBudgetPaymentList.paymentForms"
                               :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component
                    class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                  <label>Quantidade:</label>
                  <Multiselect :searchable="true" :noOptionsText="noOptionsText"
                               v-model="data.portion"
                               @select="calcDiscount(data.form_of_payment, index)"
                               :disabled="data.form_of_payment<5"
                               :options="selectBudgetPaymentList.portion"
                               :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component
                    class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                  <label>Total:</label>
                  <input-component disabled :type-input="'text'" v-model="data.price"
                                   v-money="money"/>
                </row-col-component>

                <div class="clearfix" v-if="formData.payment_methods.length >= 2">
                  <button-delete @click="paymentFormsRemove(index)" class="fs-5 float-end"/>
                </div>
              </row-component>
            </div>
    </form>

    <row-component>
      <row-col-component
          :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
        <button-submit :disaabled="sendForm" @submit="submit" :items="buttonSubmit" style="width: 100%;"/>
      </row-col-component>
      <row-col-component
          :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
        <button-route :items="buttonRoute" style="width: 100%;"/>
      </row-col-component>
    </row-component>

  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from '@/components/row/RowColComponent';
import InputComponent from '@/components/form/InputComponent';
import Multiselect from '@vueform/multiselect';
import ButtonSubmit from '@/components/buttons/ButtonSubmit';
import ButtonRoute from '@/components/buttons/ButtonRoute';
import InputTextArea from '@/components/form/InputTextArea';
import ButtonDelete from "@/components/buttons/ButtonDelete";
import ButtonPagination from "@/components/buttons/ButtonPagination";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import {mask} from "vue-the-mask";
import {MONEY} from "@/configs/constants";
import {VMoney} from "v-money";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
  name: 'BudgetCreate',

  created() {
    this.$store.dispatch('updateNamePage', 'Orçamento')
    this.$store.dispatch('loadNameBudgetList')
    this.$store.dispatch('loadProductMultiSelect')
    this.$store.dispatch('loadServiceMultiSelect')
    this.$store.dispatch('loadClientMultiSelect')
    this.$store.dispatch('loadBrandMultiSelect')
    this.$store.dispatch('loadBudgetTemplateMultiSelect')
    this.$store.dispatch('loadBudgetListPayment')
    this.$store.dispatch('loadCategoryMultiSelect')
    this.checkACL()
    this.cloneFunction()
    this.nullInputArray()
  },

  components: {
    ErrorComponent,
    ButtonDelete,
    PainelComponent,
    RowComponent,
    RowColComponent,
    InputComponent,
    Multiselect,
    ButtonSubmit,
    ButtonRoute,
    InputTextArea,
    ButtonPagination
  },

  directives: {
    money: VMoney,
    mask
  },

  data() {
    return {
      itensOne: {
        title: "Adicionar",
        icon: "ti-pencil-plus"
      },

      buttonSubmit: {
        icon: 'ti-circle-plus',
        nameButton: 'Adicionar'
      },

      buttonRoute: {
        icon: 'ti-arrow-back-up',
        route: '/budget/search',
        name: 'Voltar'
      },

      buttonAdd: {
        icon: 'ti-circle-plus',
        typeButton: 'button'
      },

      buttonSearch: {
        icon: 'ti-search',
        typeButton: 'button'
      },

      buttonConfirm: {
        icon: 'ti-circle-plus',
        typeButton: 'button',
        nameButton: 'Confirmar'
      },

      modalClient: false,
      modalPhone: false,
      modalBudgetTemplate: false,
      budget_template_id: '',
      disabledField: true,

      formData: {
        name: '',
        client_id: '',
        client_name: '',
        responsible: '',
        email: '',
        phone: '',
        obs: '',
        valid: 15,
        show_value: 1,
        name_budget_id: '',
        payment_methods: [],
        products: [],
        services: []
      },

      client: {
        type: "2",
        name: "",
        cpf: "",
        cnpj: ""
      },

      money: MONEY,
      totalResult: 0,

      errors: [],
      showView: false,
      sendForm: false,
      showError: false,
    }
  },

  methods: {

    submit() {
      if (!this.sendForm) {
        this.sendForm = true;
        this.$store.dispatch('storeBudget', this.formData)
            .then((r) => {
              this.showError = false
              this.sendForm = false
              this.errors = []
              toast.success("Cadastro efetuado com sucesso", {timeout: 2000});
              this.$router.push({name: 'budget-edit', params: {id: r.data}})
            }).catch((r) => {
          this.showError = true;
          this.sendForm = false
          this.errors = r.response.data.errors;
          this.scrollToTop()
          toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
        })
      }
    },

    async checkACL() {
      this.$store.dispatch('ACLitens', 13)
          .then((r) => {
            if (r.data.indexOf('budget-create') !== -1) {
              this.showView = true
            } else {
              this.$router.push({name: 'home'})
            }
          }).catch(() => {
        this.$router.push({name: 'home'})
      })
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },

    cloneFunction() {
    if (this.storeState.length > 0) {
      let storeStateCopy = [...this.storeState];
      if (storeStateCopy.length > 1)
        storeStateCopy.splice(0, 1);
      this.formData = storeStateCopy[0];
      this.calcTotal();
    }
  },


    loadFakesFields(products) {
      products.forEach((element) => {
        let formData = {
          brand: element.brand_id,
          category: element.categorie_id
        }
        this.$store.dispatch('loadProductBrandMultiSelect', formData)
        this.$store.dispatch('loadCategoryBrandList', formData.brand)
      })
    },

    // #######Funções para modals de pesquisa#######
    modalSearchClient(params) {
      this.modalClient = true
      this.client.name = ""
      this.client.cpf = ""
      this.client.cnpj = ""
      this.loadItems(1)
      if (params === 1) {
        this.modalClient = false
        let client = this.selectClientList.find(element => element.value === this.formData.client_id)

        if (client) {
          this.formData.client_name = client.surname;
        }
      }
    },

    selectClient(data) {
      this.modalClient = false
      this.formData.client_id = data.id
      this.formData.client_name = data.surname
      this.formData.phone = ""
      this.formData.email = ""
    },

    submitSearch() {
      this.loadItems(1)
      this.searchSubmit = true
    },

    loadItems(page) {
      if (!this.searchSubmit) {
        this.$store.dispatch('loadClientsBudgetList', {...this.params, page})
            .then((r) => {
              if (this.searchSubmit) {
                this.searchSubmit = false;
                if (r.data.length === 0) {
                  toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 2000});
                }
              }
            }).catch(() => {
          this.searchSubmit = false;
        });
      }
    },

    modalSearchClientPhone() {
      this.modalPhone = !this.modalPhone
      this.$store.dispatch('loadClientPhoneMultiSelect', this.formData.client_id)
    },

    modalSearchBudgetTemplate() {
      this.modalBudgetTemplate = !this.modalBudgetTemplate
    },

    loadBudgetTemplateGetMultiSelect(id) {
      this.$store.dispatch('loadBudgetTemplateGetMultiSelect', id)
          .then((r) => {
            this.formData.name = r.name
            this.formData.obs = r.obs
            this.formData.services = r.services
            this.formData.products = r.products
            this.loadFakesFields(r.products)
            this.nullInputArray()
          })
    },

    // #######Funções para Produtos e Serviços#######
    loadCategoryBrandList(id, index) {
      this.$store.dispatch('loadCategoryBrandList', id)
      this.clearProduct(index)
    },

    loadProductBrandMultiSelect(brand, category, index) {
      let formData = {
        brand: brand,
        category: category
      }
      this.$store.dispatch('loadProductBrandMultiSelect', formData)
      this.clearProduct(index)
    },

    loadProductPriceMultiSelect(id, index) {
      this.$store.dispatch('loadProductPriceMultiSelect', id)
          .then((r) => {
            this.productAdd[index].unit_price = r
            this.calcProduct(index)
          })
    },

    clearProduct(index) {
      this.productAdd[index].amount = 1
      this.productAdd[index].unit_price = ''
      this.productAdd[index].total_price = ''
      this.calcProduct(index)
    },

    clearBrand(index) {
      this.productAdd[index].product_id = ''
      this.productAdd[index].brand_id = ''
      this.productAdd[index].amount = 1
      this.productAdd[index].unit_price = ''
      this.productAdd[index].total_price = ''
      this.calcProduct(index)
    },

    loadServicePriceMultiSelect(id, index) {
      this.$store.dispatch('loadServicePriceMultiSelect', id)
          .then((r) => {
            this.serviceAdd[index].unit_price = r
            this.calcService(index)
          })
    },

    clearService(index) {
      this.serviceAdd[index].amount = 1
      this.serviceAdd[index].unit_price = ''
      this.serviceAdd[index].total_price = ''
      this.calcService()
    },

    nullInputArray() {
      if (this.formData && Array.isArray(this.formData.products) && this.formData.products.length === 0) {
        this.addProduct();
      }
      if (this.formData && Array.isArray(this.formData.services) && this.formData.services.length === 0) {
        this.addService();
      }
      if (this.formData && Array.isArray(this.formData.payment_methods) && this.formData.payment_methods.length === 0) {
        this.addPaymentForms();
      }
    },

    addProduct() {
      this.formData.products.push({
        brand_id: '',
        product_id: '',
        categorie_id: '',
        amount: 1,
        unit_price: '',
        total_price: ''
      })
    },

    addService() {
      this.formData.services.push({
        service_id: '',
        amount: 1,
        unit_price: '',
        total_price: ''
      })
    },

    addPaymentForms() {
      this.formData.payment_methods.push({
        form_of_payment: '',
        portion: '1',
        price: ''
      })
    },

    productRemove(index) {
      this.formData.products.splice(index, 1)
      this.calcTotal()
    },

    serviceRemove(index) {
      this.formData.services.splice(index, 1);
      this.calcTotal(); 
    },


    paymentFormsRemove(index) {
      this.formData.payment_methods.splice(index, 1)
    },

    replaceStringOne(label) {
      if (label.length < 8) {
        return parseFloat(label.replace(',', '.'))
      } else {
        return parseFloat(label.replace(',', ''))
      }
    },

    calcProduct(index) {
      let budget = this.formData.products[index];
      budget.unit_price.toLocaleString('pt-br', {minimumFractionDigits: 2});
      let value = budget.unit_price.replace(/[.,]/g, '')
      let amount = budget.amount
      let result = (value * amount / 100)
      budget.total_price =  result.toLocaleString('pt-br', {minimumFractionDigits: 2})
      this.calcTotal()
    },
    

    calcService(index) {
      if (this.formData.services[index]) {
        let budget = this.formData.services[index];

        if (budget.unit_price && budget.amount) {
          let value = parseFloat(budget.unit_price.replace(/[.,]/g, ''));
          let amount = parseFloat(budget.amount);

          // Valida se ambos são números válidos
          if (!isNaN(value) && !isNaN(amount)) {
            let result = (value * amount) / 100;
            budget.total_price = result.toLocaleString('pt-br', { minimumFractionDigits: 2 });
          } else {
            budget.total_price = '0,00';
          }

          this.calcTotal();
        }
      }
    },

    calcTotal() {
      let sum = 0;
      let price_service = 0;
      let price_product = 0;
      let products = this.formData.products;
      let services = this.formData.services;

      if (this.totalResult > 0)
        sum = this.replaceStringOne(this.totalResult)

      products.forEach((item) => {
        let productPrice = parseFloat(item.total_price.replace(/[.,]/g, '')) / 100;
        if (!isNaN(productPrice)) {
          price_product += productPrice;
        }
      });

      services.forEach((item) => {
        let servicePrice = parseFloat(item.total_price.replace(/[.,]/g, '')) / 100;
        if (!isNaN(servicePrice)) {
          price_service += servicePrice;
        }
      });

      sum = price_service + price_product;
      this.totalResult = sum.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2});
    },

    calcDiscount(type, index) {
      let a = 0

      if (this.totalResult !== 0)
        a = this.replaceStringOne(this.totalResult.replace(/[.,]/g, '')) / 100
      if (type < 5) {
        let discount = a * 0.05
        let sum = a - discount
        this.formData.payment_methods[index].price = sum.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
      }

      if (type > 4) {
        let installments = this.formData.payment_methods[index].portion
        let sum = a / installments
        this.formData.payment_methods[index].price = sum.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
      }
    }
  },

  beforeMount() {
    this.storeState.splice(0, 1)
  },

  computed: {

    me() {
      return this.$store.state.auth.me
    },

    selectActiveInative() {
      return this.$store.state.var.selectActiveInative
    },

    noResultsText() {
      return this.$store.state.var.noResultsText
    },

    noOptionsText() {
      return this.$store.state.var.noOptionsText
    },

    selectProductBrandList() {
      return this.$store.state.var.selectProductBrandList
    },

    selectBrandList() {
      return this.$store.state.var.selectBrandList
    },

    selectCategoryBrandList() {
      return this.$store.state.var.selectCategoryBrandList
    },

    selectNameBudgetList() {
      return this.$store.state.var.selectNameBudgetList
    },


    selectServiceList() {
      return this.$store.state.var.selectServiceList
    },

    selectClientList() {
      return this.$store.state.var.selectClientList
    },

    selectClientPhoneList() {
      return this.$store.state.var.selectClientPhoneList
    },

    selectBudgetTemplateList() {
      return this.$store.state.var.selectBudgetTemplateList
    },

    selectBudgetPaymentList() {
      return this.$store.state.var.selectBudgetPaymentList
    },

    storeState() {
      return this.$store.state.var.storeState
    },

    productAdd() {
      return this.formData.products;
    },

    serviceAdd() {
      return this.formData.services;
    },

    paymentFormsAdd() {
      return this.formData.payment_methods;
    },

    object() {
      return this.$store.state.var.selectClientBudgetList
    },

    params() {
      return {
        page: this.object.current_page,
        name: this.client.name,
        type: this.client.type,
        cpf: this.client.cpf,
        cnpj: this.client.cnpj,
      }
    }
  }

}
</script>

<style scoped>
.avatar__image__div {
  width: 10%;
}

.buttonSubmit {
  background-color: #36c1bd;
  color: white;
  border-color: #36c1bd;
}

.modal-search-budget-template .btn-close,
.modal-search-client .btn-close,
.modal-search-email .btn-close,
.modal-search-phone .btn-close {
  top: 0;
  right: 0;
  margin: 1%;
  cursor: pointer;
  position: absolute;
}

.modal-search-client {
  z-index: 6;
  width: 80%;
  padding: 2%;
  height: 60%;
  overflow: auto;
  position: fixed;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 5px 10px 12px 0 #A6A6A6;
}

.modal-search-budget-template,
.modal-search-email,
.modal-search-phone {
  top: 50%;
  left: 50%;
  width: 40%;
  z-index: 9999;
  position: fixed;
  min-height: 20%;
  margin-right: -50%;
  padding: 40px 20px;
  overflow-y: auto;
  background: #FFFFFF;
  transform: translate(-50%, -50%);
  box-shadow: 10px 10px 23px 0 rgb(204 204 204);
}

@media (max-width: 991px) {
  .modal-search-client,
  .modal-search-budget-template,
  .modal-search-email,
  .modal-search-phone {
    width: 95%;
    height: 80%;
  }
}
</style>
