<template>
    <button :type="items.typeButton" :class="['btn btn-secondary buttonSubmit']" @click="submit">
        <i :class="'ti ' + items.icon"></i>
        {{ items.nameButton }}
    </button>
</template>
  
<script>
export default {
    name: "ButtonSubmit",

    props: {
        items: {
            icon: {
                "required": true,
                "type": String,
                "default": ''
            },

            nameButton: {
                "type": String,
                "default": ''
            },

            typeButton: {
                "required": false,
                "type": String,
                "default": 'button'
            },
        }
    },

    data() {
        return {
            buttonLoader: false,
        }
    },

    methods: {
        submit() {
            if (this.loderButton) {
                this.buttonLoader = true;
            }
            this.$emit('submit')
        }
    }
}
</script>
  
<style scoped>
.buttonSubmit {
    background-color: #36c1bd;
    color: white;
    border-color: #ffffff;
}

.buttonSubmit:active,
.buttonSubmit:focus,
.buttonSubmit:hover,
.buttonSubmit:focus-visible {
    background-color: #36c1bd;
    color: white;
    border-color: #36c1bd;
    box-shadow: none !important;
}

.preloderSend {
    padding-right: 40px;
}

.preloderSend:after {
    content: "";
    position: absolute;
    animation: load 3s infinite;
    margin-left: 4px;
}

@keyframes load {
    0% {
        content: "";
    }

    33% {
        content: ".";
    }

    66% {
        content: "..";
    }

    99% {
        content: "...";
    }
}
</style>