<template>
  <painel-component :itens="itensOne">
    <error-component :errors="errors" v-if="showError"/>


          <form v-if="showView">
            <row-component>
              <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                 :class="{'formError' : errors.name}">
                <label>Nome:</label>
                <input-component disabled :type-input="'text'" v-model="formData.name"/>
              </row-col-component>

              <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                 :class="{'formError' : errors.status}">
                <label>Status:</label>
                <Multiselect disabled v-model="formData.status" :options="selectActiveInative"
                             :searchable="true"
                             :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
              </row-col-component>
            </row-component>

            <row-component>
              <row-col-component
                  :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                  :class="{'formError' : errors.obs}">
                <label>Observações:</label>
                <input-text-area disabled :rows="4" v-model="formData.description"/>
              </row-col-component>
            </row-component>
          </form>




    <row-component>
      <row-col-component
          :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
        <button-route :items="buttonRoute" style="width: 100%;"/>
      </row-col-component>
    </row-component>

  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from '@/components/row/RowColComponent';
import InputComponent from '@/components/form/InputComponent';
import Multiselect from '@vueform/multiselect';
import ButtonRoute from '@/components/buttons/ButtonRoute';
import {useToast} from "vue-toastification";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import InputTextArea from "@/components/form/InputTextArea.vue";

const toast = useToast()
export default {
  name: 'NameBudgetShow',

  props: {
    id: {
      required: true
    }
  },

  created() {
    this.$store.dispatch('updateNamePage', 'Nome orçamento')
    this.checkACL()
    this.loadAccountType()
  },

  components: {
    InputTextArea,
    ErrorComponent,
    PainelComponent,
    RowComponent,
    RowColComponent,
    InputComponent,
    Multiselect,
    ButtonRoute,
  },

  data() {
    return {
      itensOne: {
        title: "Ver",
        icon: "ti-eye"
      },

      buttonRoute: {
        icon: 'ti-arrow-back-up',
        route: '/name-budget/search',
        name: 'Voltar'
      },

      buttonAdd: {
        icon: 'ti-circle-plus',
      },

      formData: {
        name: '',
        status: '',
        description: '',
      },

      errors: [],
      showView: false,
      sendForm: false,
      showError: false,
    }
  },

  methods: {
    loadAccountType() {
      this.$store.dispatch('loadNameBudget', this.id)
          .then((r) => {
            this.formData = r
          }).catch(() => {
        toast.error('Erro desconhecido', {timeout: 2000})
      })
    },

    async checkACL() {
      this.$store.dispatch('ACLitens', 23)
          .then((r) => {
            if (r.data.indexOf('name-budget-search') !== -1) {
              this.showView = true

            } else {
              this.$router.push({name: 'home'})
            }
          }).catch(() => {
        this.$router.push({name: 'home'})
      })
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },

  computed: {
    me() {
      return this.$store.state.auth.me
    },

    selectActiveInative() {
      return this.$store.state.var.selectActiveInative
    },

    noResultsText() {
      return this.$store.state.var.noResultsText
    },

    noOptionsText() {
      return this.$store.state.var.noOptionsText
    },

  }

}
</script>

<style scoped>
</style>
