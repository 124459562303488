<template>
    <painel-component :itens="itensOne">
        <row-component>
            <row-col-component :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'">
                <label>Nome:</label>
                <input-component :type-input="'text'" v-model="formData.name"/>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'">
                <label>Status:</label>
                <Multiselect v-model="formData.status" :options="selectActiveInative" :searchable="true"
                             :noOptionsText="noOptionsText"
                             :noResultsText="noResultsText"/>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mt-4'">
                <button-submit @submit="submit" :items="buttonSubmit" class="me-2"/>
                <button-route v-if="buttonCheck.create" :items="buttonRoute"/>
            </row-col-component>
        </row-component>

        <table class="table table-striped">
            <thead>
            <tr>
                <th scope="col" style="width: 35%">Nome</th>
                <th scope="col" style="width: 35%">Status</th>
                <th scope="col" style="width: 10%" v-if="buttonCheck.show || buttonCheck.edit">
                    Ações
                </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(account_type, index) in account_type.data" :key="index">
                <td data-label="Nome">{{ account_type.name }}</td>
                <td data-label="Status">{{ statusString(account_type.status) }}</td>
                <td data-label="Ações" v-if="buttonCheck.show || buttonCheck.edit">
                    <button-edit v-if="buttonCheck.edit" :route="'account-type-edit'" :id-param="account_type.id"/>
                    <button-show v-if="buttonCheck.show" :route="'account-type-show'" :id-param="account_type.id"/>
                </td>
            </tr>
            </tbody>
        </table>

        <button-pagination :pagination="account_type" :offset="3" @paginate="loadAccountTypes"/>

    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from '@/components/row/RowColComponent';
import InputComponent from '@/components/form/InputComponent';
import Multiselect from '@vueform/multiselect';
import ButtonSubmit from '@/components/buttons/ButtonSubmit';
import ButtonRoute from '@/components/buttons/ButtonRoute';
import ButtonEdit from '@/components/buttons/ButtonEdit';
import ButtonShow from '@/components/buttons/ButtonShow';
import {useToast} from "vue-toastification";
import ButtonPagination from "@/components/buttons/ButtonPagination.vue";

const toast = useToast()

export default {
    name: 'AccountTypeSearch',

    created() {
        this.$store.dispatch('updateNamePage', 'Tipo de Conta')
        this.checkACL()
        this.loadAccountTypes(1)
    },

    components: {
        ButtonPagination,
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        Multiselect,
        ButtonSubmit,
        ButtonRoute,
        ButtonEdit,
        ButtonShow
    },

    data() {
        return {
            itensOne: {
                title: "Pesquisa",
                icon: "ti-list"
            },

            buttonSubmit: {
                icon: 'ti-search',
            },

            buttonRoute: {
                icon: 'ti-plus',
                route: '/account-type/create'
            },

            buttonCheck: {
                edit: false,
                create: false,
                show: false
            },

            formData: {
                name: '',
                status: ''
            },

            searchSubmit: false,
        }
    },

    methods: {

        submit() {
            this.loadAccountTypes(1)
            this.searchSubmit = true
        },

        statusString(value) {
            if (value === '1')
                return 'Ativo'
            return 'Inativo'
        },

        loadAccountTypes(page) {
            if (!this.searchSubmit) {
                this.$store.dispatch('loadAccountTypes', {...this.params, page})
                    .then((r) => {
                        if (this.searchSubmit) {
                            this.searchSubmit = false;
                            if (r.data == '') {
                                toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 2000});
                            }
                        }
                    }).catch(() => {
                    this.searchSubmit = false;
                });
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 14)
                .then((r) => {
                    if (r.data.indexOf('account-type-search') !== -1) {

                        if (r.data.indexOf('account-type-search') !== -1) {
                            this.buttonCheck.show = true
                        }

                        if (r.data.indexOf('account-type-create') !== -1) {
                            this.buttonCheck.create = true
                        }

                        if (r.data.indexOf('account-type-edit') !== -1) {
                            this.buttonCheck.edit = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

    },

    computed: {
        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        account_type() {
            return this.$store.state.account_type.items
        },

        params() {
            return {
                page: this.account_type.current_page,
                name: this.formData.name,
                status: this.formData.status
            }
        },

        me() {
            return this.$store.state.auth.me
        },
    }

}
</script>

<style src="@/assets/style/table.css">

</style>