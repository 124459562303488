import AdminComponent from '@/components/dashboard/AdminComponent'
import InventorySearch from "@/views/search/InventorySearch";
import InventoryCreate from "@/views/create/InventoryCreate";

export default {
    path: '/inventory',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'search', name: 'inventory-search', component: InventorySearch},
        {path: 'create', name: 'inventory-create', component: InventoryCreate},
    ]
}