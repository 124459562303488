<template>
    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>

        <form v-if="showView">
            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <label>Nome:</label>
                    <input-component :type-input="'text'" v-model="formData.name"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <label>Status:</label>
                    <Multiselect v-model="formData.status" :options="selectActiveInative" :searchable="true"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.permissions }"
                                   v-for="(permission, index) in selectPage" :key="index">
                    <div class="form-check checkBoxHeader">
                        <input-check-box class="form-check-input " type="checkbox" @click="checkAll(index)"
                                         :value="index"
                                         :id="'checkBoxMaster' + index" :name="index"/>
                        <label class="form-check-label">{{ permission.label }}</label>
                    </div>

                    <div class="form-check " v-for="(option, indexTwo) in permission.options" :key="indexTwo">
                        <input-check-box type="checkbox" :value="option.value" :id="indexTwo"
                                         :class="'form-check-input checkBoxAll' + index + ' checkOne' + option.value"
                                         @click="checkOne(index, permission)"/>
                        <label class="form-check-label">{{ option.label }}</label>
                    </div>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-submit :disaabled="sendForm" :class="{'preloaderSend' : sendForm}" :disabled="sendForm" @submit="submit"
                                   :items="buttonSubmit"
                                   style="width: 100%;"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-route :items="buttonRoute" style="width: 100%;"/>
                </row-col-component>
            </row-component>
        </form>

    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from '@/components/row/RowColComponent';
import InputComponent from '@/components/form/InputComponent';
import Multiselect from '@vueform/multiselect';
import ButtonSubmit from '@/components/buttons/ButtonSubmit';
import ButtonRoute from '@/components/buttons/ButtonRoute';
import InputCheckBox from '@/components/form/InputCheckBox';
import ErrorComponent from "@/components/alerts/ErrorComponent";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: 'UserGroupCreate',

    created() {
        this.$store.dispatch('updateNamePage', 'Grupo de Usuário')
        this.$store.dispatch('loadPage')
        this.checkACL()
    },

    components: {
        ErrorComponent,
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        Multiselect,
        ButtonSubmit,
        ButtonRoute,
        InputCheckBox
    },

    data() {
        return {
            itensOne: {
                title: "Adicionar",
                icon: "ti-pencil-plus"
            },

            buttonSubmit: {
                icon: 'ti-circle-plus',
                nameButton: 'Adicionar',
                typeButton: 'button'
            },

            buttonRoute: {
                icon: 'ti-arrow-back-up',
                route: '/user-group/search',
                name: 'Voltar'
            },

            formData: {
                name: '',
                status: '',
                permissions: []
            },

            errors: [],
            showView: false,
            sendForm: false,
            showError: false,
        }
    },

    methods: {

        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('storeGroupUser', this.formData)
                    .then((r) => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success("Cadastro efetuado com sucesso", {timeout: 2000});
                        this.$router.push({name: 'user-group-edit', params: {id: r.data.id}})
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollTop()
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        scrollToTop() {
            window.scrollTo(0, 0);
        },

        checkOne(index, permission) {
            let listPermission = document.getElementsByClassName("checkBoxAll" + index);
            let cont = 0;
            for (let i = 0; i < listPermission.length; ++i) {
                if (listPermission[i].checked) {
                    listPermission[i].checked = true;
                    if (!this.formData.permissions.includes(parseInt(listPermission[i].value))) {
                        this.formData.permissions.push(parseInt(listPermission[i].value))
                    }
                    cont = cont + 1;
                } else {
                    listPermission[i].checked = false;
                    let indexArray = this.formData.permissions.indexOf(parseInt(listPermission[i].value));
                    if (indexArray !== -1) {
                        this.formData.permissions.splice(indexArray, 1);
                    }
                }
            }
            document.getElementById("checkBoxMaster" + index).checked = cont >= permission.options.length;
        },

        checkAll(index) {
            let listPermission = document.getElementsByClassName("checkBoxAll" + index);

            if (document.getElementById("checkBoxMaster" + index).checked) {
                for (let i = 0; i < listPermission.length; ++i) {
                    listPermission[i].checked = true;
                    if (!this.formData.permissions.includes(parseInt(listPermission[i].value))) {
                        this.formData.permissions.push(parseInt(listPermission[i].value))
                    }
                }
            } else {
                for (let cont = 0; cont < listPermission.length; ++cont) {
                    listPermission[cont].checked = false;
                    let indexArray = this.formData.permissions.indexOf(parseInt(listPermission[cont].value));
                    if (indexArray !== -1) {
                        this.formData.permissions.splice(indexArray, 1);
                    }
                }
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 1)
                .then((r) => {
                    if (r.data.indexOf('group-user-create') !== -1) {
                        this.showView = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

    },

    computed: {
        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        selectPage() {
            return this.$store.state.page.items
        }

    }

}
</script>