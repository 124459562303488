<template>
    <div class="modal-w position-fixed start-0 top-0 d-grid" v-if="event.show">
        <div class="card-container" v-if="event.show">
            <div @click="event.show = false" role="button" class="btn-close"></div>

            <div class="header-card" :class="[{
                'blue-bg': parseInt(event.formData.color) === 1,
                'green-bg': parseInt(event.formData.color) === 2,
                'red-bg': parseInt(event.formData.color) === 3,
                'salmon-bg': parseInt(event.formData.color) === 4,
                'silver-bg': parseInt(event.formData.color) === 5,
             }]">
                <h3>Evento: {{ event.formData.name }}</h3>
            </div>

            <div class="footer-card">
                <div class="skills">
                    <div class="date-start">
                        <i class="ti ti-calendar icon"></i>
                        <span>Data Início: {{ event.formData.date_start }} </span>
                        <span>Data Final: {{ event.formData.date_end }} </span>
                    </div>

                    <div class="date-start">
                        <i class="ti ti-clock icon"></i>
                        <span>Hora Início: {{ event.formData.time_start }} </span>
                        <span>Hora Final: {{ event.formData.time_end }} </span>
                    </div>
                </div>

                <div class="skills">
                    <div class="places">
                        <i class="ti ti-map-pin-filled icon"></i>
                        <span>Local: {{ event.formData.place }} </span>
                    </div>
                </div>

                <div class="skills">
                    <textarea rows="4" v-model="event.formData.desc" disabled></textarea>
                </div>
            </div>
        </div>
    </div>

    <painel-component :itens="itensOne">
        <error-component v-if="showError" :errors="errors"/>

        <div class="clearfix">
            <button-delete class="float-end" tooltip="Deletar entrega" @click="destroy(this.delivery)"/>
            <button-submit @click="printDelivery" class="float-end" :items="itensFour"/>
        </div>

        <form v-if="showView">
            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.delivery_type}">
                    <input @change="deliveryType" v-model="formData.delivery_type" type="radio" name="docs"
                           id="current-address" value="1">
                    <label for="current-address">Endereço de Cadastro</label>

                    <input @change="deliveryType" v-model="formData.delivery_type" type="radio" name="docs"
                           id="new-address" value="2">
                    <label for="new-address">Endereço Novo</label>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.cep}">
                    <label>CEP:</label>
                    <input-component v-mask="'#####-###'" @change="searchApiCEP" v-model="formData.cep"
                                     type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.city}">
                    <label>Cidade:</label>
                    <input-component v-model="formData.city" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.state}">
                    <label>Estado:</label>
                    <input-component v-model="formData.state" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.district}">
                    <label>Bairro:</label>
                    <input-component v-model="formData.district" type-input="text"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.address}">
                    <label>Endereço:</label>
                    <input-component v-model="formData.address" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.country}">
                    <label>País:</label>
                    <input-component v-model="formData.country" type-input="text"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.complement}">
                    <label>Referência:</label>
                    <input-text-area v-model="formData.complement" :rows="4"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.schedule_id}">
                    <label>Agenda:</label>
                    <Multiselect v-model="formData.schedule_id" :options="selectSchedule" :searchable="true"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.color}">
                    <label>Cor:</label>
                    <Multiselect v-model="formData.color" :options="selectScheduleColor" :searchable="true"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.date_start}">
                    <label>Data Início:</label>
                    <input-component v-mask="'##/##/####'" v-model="formData.date_start" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.time_start}">
                    <label>Hora Início:</label>
                    <input-component v-mask="'##:##'" v-model="formData.time_start" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.date_end}">
                    <label>Data Final:</label>
                    <input-component v-mask="'##/##/####'" v-model="formData.date_end" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.time_end}">
                    <label>Hora Final:</label>
                    <input-component v-mask="'##:##'" v-model="formData.time_end" type-input="text"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.desc}">
                    <label>Descrição:</label>
                    <input-text-area v-model="formData.desc" :rows="6"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-submit :disabled="sendForm" class="w-100" @click="submit" :items="itensThree"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itensTwo"/>
                </row-col-component>
            </row-component>
        </form>

        <div class="line-division mt-4">
            <h1 class="h1-text"><i class="ti ti-clock"></i> {{ formData.schedule_name }}</h1>
        </div>

        <FullCalendar ref="fullCalendar" :options="calendarOptions"/>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import InputTextArea from "@/components/form/InputTextArea";
import Multiselect from "@vueform/multiselect";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import ButtonDelete from "@/components/buttons/ButtonDelete";

import swal from "sweetalert";
import {mask} from "vue-the-mask";
import {useToast} from "vue-toastification";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import ptLocale from "@fullcalendar/core/locales/pt";

const toast = useToast()
export default {
    name: "BudgetDeliveryCreate",
    components: {
        FullCalendar,
        ButtonDelete,
        ButtonRoute,
        ButtonSubmit,
        Multiselect,
        InputTextArea,
        InputComponent,
        RowColComponent,
        RowComponent,
        ErrorComponent,
        PainelComponent
    },

    directives: {
        mask
    },

    props: {
        id: {
            required: true
        },

        client: {
            required: true
        },

        delivery: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Entrega')
        this.loadItems()
        this.loadBudgetSchedule()
        this.checkACL()
    },

    data() {
        return {
            itensOne: {
                title: "Editar",
                icon: "ti-edit"
            },

            itensTwo: {
                icon: 'ti-arrow-back-up',
                route: '/budget/search',
                name: 'Voltar'
            },

            itensThree: {
                icon: 'ti-device-floppy',
                nameButton: 'Salvar',
            },

            itensFour: {
                icon: 'ti-printer',
            },

            formData: {
                id: this.delivery,
                budget_id: this.id,
                schedule_id: '',
                schedule_name: '',
                date_start: this.dateAdd(),
                time_start: this.currentTime(),
                date_end: this.dateAdd(),
                time_end: this.timeAdd(),
                color: '',
                delivery_type: '1',
                cep: '',
                city: '',
                state: '',
                district: '',
                address: '',
                complement: '',
                country: '',
                desc: '',
                link_delivery: '',
                events: []
            },

            schedule: [],

            event: {
                show: false,
                formData: {
                    creator_user_id: '',
                    creator_user_name: '',
                    name: '',
                    date_start: '',
                    time_start: '',
                    date_end: '',
                    time_end: '',
                    place: '',
                    desc: '',
                    color: '',
                    creator_user_photo: '',
                    date_create: '',
                    hour_create: '',
                    guests: []
                }
            },

            calendarOptions: {
                plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
                initialView: 'dayGridMonth',
                dateClick: this.handleDateClick,
                eventClick: this.eventClick,
                locale: ptLocale,
                themeSystem: 'standard',
                selectable: true,
                eventDisplay: 'block',
                events: [],
                height: 'auto',
                headerToolbar: {
                    left: 'title',
                    right: 'dayGridMonth,dayGridWeek,listDay, prev,next'
                },
                weekends: true,
                customButtons: {
                    prev: {
                        click: () => {
                            let calendarApi = this.$refs.fullCalendar.getApi();
                            calendarApi.prev();
                            this.loadEventsMonth(calendarApi.getDate().getMonth() + 1)
                        }
                    },
                    next: {
                        click: () => {
                            let calendarApi = this.$refs.fullCalendar.getApi();
                            calendarApi.next();
                            this.loadEventsMonth(calendarApi.getDate().getMonth() + 1)
                        }
                    }
                },
            },

            errors: [],
            showView: false,
            sendForm: false,
            showError: false,
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateBudgetSchedule', this.formData)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success("Cadastro atualizado com sucesso", {timeout: 2000});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    window.scrollTo(0, 0);
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        destroy(id) {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar este evento?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,

                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    this.$store.dispatch('destroyBudgetSchedule', id).then(() => {
                        this.$router.push({name: 'budget-search'})
                        toast.success('Cadastro deletado com sucesso', {timeout: 2000});
                    }).catch(() => {
                        toast.error(`Não foi possível deletar este evento`, {timeout: 2000});
                    })
                }
            });
        },

        

        loadItems() {
            this.$store.dispatch('loadBudgetShowSchedule', this.delivery)
                .then((r) => {
                    this.formData = r
                    this.calendarOptions.events = r.events
                }).catch(() => {
                toast.error('Erro desconhecido', {timeout: 2000})
            })
        },

        printDelivery() {
            window.open(this.formData.link_delivery, "_blank")
        },

        eventClick: function (dateObject) {
            let publicId = dateObject.event._def.publicId
            this.ShowEvent(publicId)
        },

        loadBudgetSchedule() {
            this.$store.dispatch('loadBudgetSchedule', this.client)
                .then(r => this.schedule = r.schedule)
        },

        loadEventsMonth(month) {
            let params = {id: this.client, month: month}
            this.$store.dispatch('loadBudgetScheduleEventsMonth', params)
                .then((r) => {
                    this.calendarOptions.events = r
                });
        },

        ShowEvent(params) {
            this.$store.dispatch('loadBudgetScheduleEvent', params)
                .then((r) => {
                    this.event.formData = r;
                    this.event.show = true;
                }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 2000});
            })
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 13)
                .then((r) => {
                    if (r.data.indexOf('budget-edit') !== -1) {
                        this.showView = true

                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        deliveryType() {
            if (this.formData.delivery_type === '2') {
                this.formData = {
                    id: this.delivery,
                    budget_id: this.id,
                    schedule_id: '',
                    date_start: this.dateAdd(),
                    time_start: this.currentTime(),
                    date_end: this.dateAdd(),
                    time_end: this.timeAdd(),
                    color: '',
                    delivery_type: '2',
                    cep: '',
                    city: '',
                    state: '',
                    district: '',
                    address: '',
                    complement: '',
                    country: '',
                    desc: ''
                }
            } else {
                this.loadBudgetSchedule()
            }
        },

        searchApiCEP() {
            this.resetAddress()
            let cep = this.formData.cep.replace(/\D/g, '');
            const dateCEP = {'cep': cep}
            this.$store.dispatch('searchApiCEP', dateCEP)
                .then((r) => {
                    this.formData.city = r.data.localidade
                    this.formData.state = r.data.uf
                    this.formData.district = r.data.bairro
                    this.formData.address = r.data.logradouro
                    this.formData.complement = r.data.complemento
                }).catch(() => {
            })
        },

        resetAddress() {
            this.formData.city = ''
            this.formData.state = ''
            this.formData.district = ''
            this.formData.address = ''
            this.formData.complement = ''
        },

        dateAdd() {
            let currentTime = new Date();
            if (currentTime.getDay() === 5) {
                currentTime.setDate(currentTime.getDate() + 3)
            }

            if (currentTime.getDay() === 4) {
                currentTime.setDate(currentTime.getDate() + 4)
            } else {
                currentTime.setDate(currentTime.getDate() + 2)
            }

            let day = currentTime.getDate();
            let month = currentTime.getMonth() + 1;
            let year = currentTime.getFullYear();

            day = (day < 10 ? '0' : '') + day;
            month = (month < 10 ? '0' : '') + month;

            return `${day}/${month}/${year}`
        },

        timeAdd() {
            const currentTime = new Date();
            let currentHours = currentTime.getHours() + 2;
            const currentMinutes = currentTime.getMinutes();
            const currentSeconds = currentTime.getSeconds();

            currentHours = currentHours.toString().padStart(2, '0');
            const formattedMinutes = currentMinutes.toString().padStart(2, '0');
            const formattedSeconds = currentSeconds.toString().padStart(2, '0');

            return `${currentHours}:${formattedMinutes}:${formattedSeconds}`;
        },

        currentTime() {
            const currentTime = new Date();
            let currentHours = currentTime.getHours();
            const currentMinutes = currentTime.getMinutes();
            const currentSeconds = currentTime.getSeconds();

            currentHours = currentHours.toString().padStart(2, '0');
            const formattedMinutes = currentMinutes.toString().padStart(2, '0');
            const formattedSeconds = currentSeconds.toString().padStart(2, '0');

            return `${currentHours}:${formattedMinutes}:${formattedSeconds}`;
        }
    },

    computed: {
        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        selectScheduleColor() {
            return this.$store.state.var.selectScheduleColor
        },

        selectSchedule() {
            return this.schedule
        },
    }
}
</script>

<style scoped>
.line-division {
    width: 100%;
    border-top: 1px solid #c4c4c4;
}

.h1-text {
    color: white;
    padding: 10px;
    margin-top: 1px;
    font-size: 16px;
    width: fit-content;
    background-color: #36c1bd;
}

.modal-w {
    z-index: 100;
    width: 100vw;
    height: 100vh;
    place-items: center;
    background-color: rgba(0, 0, 0, 0.7);
}

.btn-close {
    top: 10px;
    right: 15px;
    position: absolute;
}

.card-container {
    width: 30%;
    background: #fff;
    position: relative;
    padding: 0 0 5px 0;
}

.header-card {
    width: 100%;
    display: flex;
    min-height: 50px;
    flex-direction: column;
    padding: 10px 0 0 15px;
    justify-content: space-evenly;
}

.footer-card {
    height: 50%;
    width: 100%;
    display: flex;
    padding-left: 10px;
    flex-direction: column;
}

.card-container img {
    width: 50px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 10px;
}

.card-container h3 {
    font-size: 16px;
}

.card-container h4 {
    font-size: 16px;
    font-weight: 400;
}

.skills {
    width: 100%;
    display: flex;
    margin-top: 20px;
    align-items: center;
    background-color: #fff;
}

.skills textarea {
    width: 98%;
    resize: none;
}

.places {
    width: 50%;
    display: flex;
    padding-left: 35px;
    position: relative;
    flex-direction: column;
}

.date-start {
    width: 50%;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.places i.icon {
    top: 0;
    left: 0;
    font-size: 1.2rem;
    position: absolute;
}

.date-start i.icon {
    left: 0;
    top: 20%;
    font-size: 1.2rem;
    position: absolute;
}

.blue-bg {
    color: white;
    background-color: #2185d0;
}

.green-bg {
    color: white;
    background-color: #16ab39;
}

.red-bg {
    color: white;
    background-color: #db2828;
}

.salmon-bg {
    background-color: #b5cc18;
}

.silver-bg {
    color: white;
    background-color: #5a5a5a;
}

@media (max-width: 991px) {
    .card-container {
        height: 480px;
        width: 90%;
    }

    .skills {
        flex-direction: column;
        align-items: flex-start;
    }

    .date-start {
        width: 100%;
        align-items: flex-start;
        padding: 5px 5px 5px 50px;
    }

    .places {
        padding-left: 50px;
    }
}
</style>