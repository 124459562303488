export default {
    icon: 'ti ti-list',
    name: 'Cadastro',
    subLinks: [

        {
            name: 'Categoria',
            route: '/category/search',
            pagePermissions: [
                'category-search',
                'category-create',
                'category-edit',
                'category-delete'
            ],
        },

        {
            name: 'Cliente',
            route: '/client/search',
            pagePermissions: [
                'client-search',
                'client-create',
                'client-edit',
            ],
        },

        {
            name: 'Fornecedor',
            route: '/provider/search',
            pagePermissions: [
                'provider-search',
                'provider-create',
                'provider-edit'
            ],
        },

        {
            name: 'Funcionário',
            route: '/employee/search',
            pagePermissions: [
                'employee-search',
                'employee-create',
                'employee-edit'
            ],
        },

        {
            name: 'Frota',
            route: '/fleet/search',
            pagePermissions: [
                'fleet-search',
                'fleet-create',
                'fleet-edit'
            ],
        },

        {
            name: 'Grupo de Usuário',
            route: '/user-group/search',
            pagePermissions: [
                'group-user-search',
                'group-user-create',
                'group-user-edit',
                'group-user-delete'
            ],
        },

        {
            name: 'Marca',
            route: '/brand/search',
            pagePermissions: [
                'brand-search',
                'brand-create',
                'brand-edit',
                'brand-delete'
            ],
        },

        {
            name: 'Serviço',
            route: '/service/search',
            pagePermissions: [
                'service-search',
                'service-create',
                'service-edit',
                'service-delete'
            ],
        },

        {
            name: 'Usuário',
            route: '/user/search',
            pagePermissions: [
                'user-search',
                'user-create',
                'user-edit',
                'user-delete',
            ],
        },

    ],

    pageLinksNavCheck: [
        'category-search',
        'category-create',
        'category-edit',
        'category-delete',
        'client-search',
        'client-create',
        'client-edit',
        'user-search',
        'user-create',
        'user-edit',
        'user-delete',
        'group-user-search',
        'group-user-create',
        'group-user-edit',
        'group-user-delete',
        'service-search',
        'service-create',
        'service-edit',
        'service-delete',
        'fleet-search',
        'fleet-create',
        'fleet-edit',
        'provider-search',
        'provider-create',
        'provider-edit',
        'employee-search',
        'employee-create',
        'employee-edit',
        'brand-search',
        'brand-create',
        'brand-edit',
        'brand-delete'
    ],
}
