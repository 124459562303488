import AdminComponent from '@/components/dashboard/AdminComponent'
import EmployeeSearch from "@/views/search/EmployeeSearch";
import EmployeeCreate from "@/views/create/EmployeeCreate";
import EmployeeUpdate from "@/views/update/EmployeeUpdate";
import EmployeeShow from "@/views/show/EmployeeShow";
import EmployeeUpdatePhoto from "@/views/update/EmployeeUpdatePhoto";

export default {
    path: '/employee',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'search', name: 'employee-search', component: EmployeeSearch},
        {path: 'create', name: 'employee-create', component: EmployeeCreate},
        {path: 'edit/:id', name: 'employee-edit', component: EmployeeUpdate, props: true},
        {path: 'show/:id', name: 'employee-show', component: EmployeeShow, props: true},
        {path: 'photo/:id', name: 'employee-edit-photo', component: EmployeeUpdatePhoto, props: true},
    ]
}
