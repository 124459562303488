<template>
    <div class="window-modal" v-if="windowEvent || windowSchedule">
        <div class="modal-event" v-if="windowEvent">
            <div class="btn-close" @click="closeModalEvent"></div>

            <form>
                <row-component>
                    <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                       :class="{ 'formError': errors.name }">
                        <label>Nome do evento:</label>
                        <input-component :name-input="'name'" :type-input="'text'" v-model="formDateEvent.name"/>
                    </row-col-component>
                </row-component>

                <row-component>
                    <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                       :class="{ 'formError': errors.date_start }">
                        <label>Data Início:</label>
                        <input-component :name-input="'name'" :type-input="'text'" v-model="formDateEvent.date_start"
                                         v-mask="'##/##/####'"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                       :class="{ 'formError': errors.time_start }">
                        <label>Hora Início:</label>
                        <input-component :name-input="'name'" :type-input="'text'" v-model="formDateEvent.time_start"
                                         v-mask="'##:##:##'"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                       :class="{ 'formError': errors.date_end }">
                        <label>Data Final:</label>
                        <input-component :name-input="'name'" :type-input="'text'" v-model="formDateEvent.date_end"
                                         v-mask="'##/##/####'"/>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                       :class="{ 'formError': errors.time_end }">
                        <label>Hora Final:</label>
                        <input-component :name-input="'name'" :type-input="'text'" v-model="formDateEvent.time_end"
                                         v-mask="'##:##:##'"/>
                    </row-col-component>
                </row-component>

                <row-component>
                    <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                        <label>Convidados:</label>
                        <Multiselect v-model="formDateEvent.participants" mode="tags" track-by="name" label="name"
                                     :options="selectGuestScheduleList" :searchable="true" :close-on-select="false"
                                     :noResultsText="noResultsText" :noOptionsText="noOptionsText">
                            <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                <div class="multiselect-tag is-user" :class="{ 'is-disabled': disabled }">
                                    <img :src="option.icon" alt="">
                                    {{ option.name }}
                                    <span v-if="!disabled" class="multiselect-tag-remove"
                                          @mousedown.prevent="handleTagRemove(option, $event)">
                                        <span class="multiselect-tag-remove-icon"></span>
                                    </span>
                                </div>
                            </template>

                            <template v-slot:option="{ option }">
                                <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                            </template>
                        </Multiselect>
                    </row-col-component>

                    <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                        <label>Local:</label>
                        <input-component :name-input="'name'" :type-input="'text'" v-model="formDateEvent.place"/>
                    </row-col-component>
                </row-component>

                <row-component>
                    <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                        <label>Descrição:</label>
                        <input-text-area :modelValue="formDateEvent.desc" v-model="formDateEvent.desc" :rows="4"/>
                    </row-col-component>
                </row-component>

                <row-component>
                    <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                        <section>
                            <div class="swatch purple">
                                <input type="radio" name="swatch_demo" id="swatch_1" value="1" checked
                                       v-model="formDateEvent.color"/>
                                <label for="swatch_1"><i class="ti ti-checks"></i></label>
                            </div>
                            <div class="swatch green">
                                <input type="radio" name="swatch_demo" id="swatch_2" value="2"
                                       v-model="formDateEvent.color"/>
                                <label for="swatch_2"><i class="ti ti-checks"></i></label>
                            </div>
                            <div class="swatch blue">
                                <input type="radio" name="swatch_demo" id="swatch_3" value="3"
                                       v-model="formDateEvent.color"/>
                                <label for="swatch_3"><i class="ti ti-checks"></i></label>
                            </div>
                            <div class="swatch orange">
                                <input type="radio" name="swatch_demo" id="swatch_4" value="4"
                                       v-model="formDateEvent.color"/>
                                <label for="swatch_4"><i class="ti ti-checks"></i></label>
                            </div>
                            <div class="swatch red">
                                <input type="radio" name="swatch_demo" id="swatch_5" value="5"
                                       v-model="formDateEvent.color"/>
                                <label for="swatch_5"><i class="ti ti-checks"></i></label>
                            </div>
                        </section>
                    </row-col-component>
                </row-component>
            </form>

            <button-submit @submit="SubmitEvent" :items="{icon: 'ti ti-plus', nameButton: 'Adicionar'}"/>
        </div>

        <div class="card-container" v-if="windowSchedule">
            <div class="btn-close" @click="closeModalShowEvent"></div>

            <div class="header-card" :class="[{
        'blue-bg': parseInt(formDateEventView.color) === 1,
        'green-bg': parseInt(formDateEventView.color) === 2,
        'red-bg': parseInt(formDateEventView.color) === 3,
        'salmon-bg': parseInt(formDateEventView.color) === 4,
        'silver-bg': parseInt(formDateEventView.color) === 5,
        }]">

                <h3>Evento: {{ formDateEventView.name }}</h3>
            </div>

            <div class="footer-card">
                <div class="skills">
                    <div class="date-start">
                        <i class="ti ti-calendar icon"></i>
                        <span>Data Início: {{ formDateEventView.date_start }} </span>
                        <span>Data Final: {{ formDateEventView.date_end }} </span>
                    </div>

                    <div class="date-start">
                        <i class="ti ti-clock icon"></i>
                        <span>Hora Início: {{ formDateEventView.time_start }} </span>
                        <span>Hora Final: {{ formDateEventView.time_end }} </span>
                    </div>
                </div>

                <div class="skills">
                    <div class="places">
                        <i class="ti ti-map-pin-filled icon"></i>
                        <span>Local: {{ formDateEventView.place }} </span>
                    </div>
                </div>

                <div class="skills">
                    <textarea rows="4" v-model="formDateEventView.desc" disabled></textarea>
                </div>

                <div class="skills">
                    <button-delete @click="deleteSubmit(formDateEventView)"/>
                </div>
            </div>
        </div>
    </div>

    <painel-component :itens="itemsOne">
        <error-component v-if="showError" :errors="errors"/>

        <form v-if="showView">
            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <div class="aux-row-2">
                        <p>Criador da agenda:</p>
                        <img class="character-label-icon-2" :src="formDate.creator_user_photo" alt="">
                        {{ formDate.creator_user_name }}
                    </div>

                    <div class="aux-row-2">
                        <p>Código:</p>
                        {{ formDate.code }}
                    </div>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <div class="aux-row-3">
                        <div class="text"><i class="ti ti-calendar fs-5 me-1"></i>{{ formDate.date_create }}</div>
                        <div class="text"><i class="ti ti-clock fs-5 me-1"></i>{{ formDate.hour_create }}</div>
                    </div>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-10 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.name }">
                    <label>Nome:</label>
                    <input-component disabled :name-input="'name'" :type-input="'text'" v-model="formDate.name"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.status }">
                    <label>Status:</label>
                    <Multiselect disabled v-model="formDate.status" :searchable="true" :options="selectActiveInative"
                                 :noResultsText="noResultsText" :noOptionsText="noOptionsText" :name="'status'"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.participants }">
                    <label>Participantes:</label>
                    <Multiselect disabled v-model="formDate.participants" mode="tags" track-by="name" label="name"
                                 :options="selectUserList" :searchable="true" :search="true"
                                 :noResultsText="noResultsText" :noOptionsText="noOptionsText"
                                 :close-on-select="false">
                        <template v-slot:tag="{ option, handleTagRemove, disabled }">
                            <div class="multiselect-tag is-user" :class="{ 'is-disabled': disabled }">
                                <img :src="option.icon" alt="">
                                {{ option.name }}
                                <span v-if="!disabled" class="multiselect-tag-remove"
                                      @mousedown.prevent="handleTagRemove(option, $event)">
                                    <span class="multiselect-tag-remove-icon"></span>
                                </span>
                            </div>
                        </template>

                        <template v-slot:option="{ option }">
                            <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                        </template>
                    </Multiselect>
                </row-col-component>
            </row-component>

            <div class="line-division my-3">
                <h1 class="h1-text">
                    <i class="calendar alternate icon"></i>
                    Eventos da Agenda
                </h1>
            </div>

            <FullCalendar ref="fullCalendar" :options="calendarOptions"/>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="btnRoute"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import InputTextArea from "@/components/form/InputTextArea";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";

import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import ptLocale from '@fullcalendar/core/locales/pt'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import "@fullcalendar/core"

import {mask} from 'vue-the-mask'
import swal from 'sweetalert';
import {useToast} from "vue-toastification";
import ButtonDelete from "@/components/buttons/ButtonDelete.vue";

const toast = useToast();

export default {
    name: "ScheduleUpdate",
    components: {
        ButtonDelete,
        ButtonRoute,
        ButtonSubmit,
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        Multiselect,
        ErrorComponent,
        InputTextArea,
        FullCalendar,
    },

    directives: {
        mask,
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Minha agenda')
        this.$store.dispatch('loadUserMultiSelect')
        this.$store.dispatch('loadGuestScheduleList')
        this.loadMySchedule()
        this.checkACL()
    },

    data() {
        return {
            calendarOptions: {
                plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
                initialView: 'dayGridMonth',
                dateClick: this.handleDateClick,
                eventClick: this.eventClick,
                locale: ptLocale,
                themeSystem: 'standard',
                selectable: true,
                eventDisplay: 'block',
                events: [],
                height: 'auto',
                headerToolbar: {
                    left: 'title',
                    right: 'dayGridMonth,dayGridWeek,listDay, prev,next'
                },
                weekends: true,
                customButtons: {
                    prev: {
                        click: () => {
                            let calendarApi = this.$refs.fullCalendar.getApi();
                            calendarApi.prev();
                            this.getEventApiMonth(calendarApi.getDate().getMonth() + 1)
                        }
                    },
                    next: {
                        click: () => {
                            let calendarApi = this.$refs.fullCalendar.getApi();
                            calendarApi.next();
                            this.getEventApiMonth(calendarApi.getDate().getMonth() + 1)
                        }
                    }
                },
            },

            itemsOne: {
                title: "Editar",
                icon: "ti ti-edit"
            },

            btnRoute: {
                icon: 'ti-arrow-back-up',
                route: '/my-schedule/search',
                name: 'Voltar'
            },

            formDate: {
                name: '',
                status: '',
                creator_user_name: '',
                date_create: '',
                hour_create: '',
                creator_user_photo: '',
                participants: [],
            },

            formDateEvent: {
                schedule_id: this.id,
                name: '',
                date_start: '',
                time_start: '',
                date_end: '',
                time_end: '',
                place: '',
                desc: '',
                participants: [],
                color: 1,
            },

            formDateEventView: {
                creator_user_id: '',
                creator_user_name: '',
                name: '',
                date_start: '',
                time_start: '',
                date_end: '',
                time_end: '',
                place: '',
                desc: '',
                color: '',
                creator_user_photo: '',
                date_create: '',
                hour_create: '',
                guests: []
            },

            windowEvent: false,
            windowSchedule: false,

            errors: [],
            sendForm: false,
            showView: false,
            showError: false,
        }
    },

    methods: {
        loadMySchedule() {
            this.$store.dispatch('loadMySchedule', this.id).then((r) => {
                this.formDate = r;
                this.calendarOptions.events = r.events
            }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 2000});
            })
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 20)
                .then((r) => {
                    if (r.data.indexOf('my-schedule-edit') !== -1) {
                        this.showView = true

                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        getEventApiMonth(month) {
            let params = {id: this.id, month: month}
            this.$store.dispatch('loadMyScheduleEventsMonth', params)
                .then((r) => {
                    this.calendarOptions.events = r
                });
        },

        handleDateClick: function (arg) {
            this.windowEvent = true
            this.formDateEvent.time_start = this.timeCurrent()
            this.formDateEvent.time_end = this.timeCurrentAdd()

            this.formDateEvent.date_start = this.dateEventStart(arg.dateStr)
            this.formDateEvent.date_end = this.dateEventStart(arg.dateStr)
        },

        eventClick: function (dateObject) {
            let publicId = dateObject.event._def.publicId
            this.ShowEvent(publicId)
        },

        dateEventStart(arg) {
            let date = arg.split("-")
            return `${date[2]}/${date[1]}/${date[0]}`
        },

        timeCurrent() {
            let currentTime = new Date();
            let currentHours = currentTime.getHours();
            let currentMinutes = currentTime.getMinutes();
            let currentSeconds = currentTime.getSeconds();
            if (currentHours <= 9)
                currentHours = "0" + currentHours;

            if (currentMinutes <= 9)
                currentMinutes = "0" + currentMinutes

            if (currentSeconds <= 9)
                currentSeconds = "0" + currentSeconds

            return `${currentHours}:${currentMinutes}:${currentSeconds}`
        },

        closeModalEvent() {
            this.clearFormDateEvent()
            this.windowEvent = false
        },

        SubmitEvent() {
            this.$store.dispatch('storeMyScheduleEvent', this.formDateEvent)
                .then((r) => {
                    this.windowEvent = false
                    this.clearFormDateEvent()
                    this.errors = [];
                    this.calendarOptions.events = r.data
                    toast.success("Evento criado com sucesso", {timeout: 2000});
                    this.loadMySchedule()
                }).catch((r) => {
                this.errors = r.response.data.errors;
                toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
            })
        },

        timeCurrentAdd() {
            let currentTime = new Date();
            let currentHours = currentTime.getHours() + 2;
            let currentMinutes = currentTime.getMinutes();
            let currentSeconds = currentTime.getSeconds();
            if (currentHours <= 9)
                currentHours = "0" + currentHours;

            if (currentMinutes <= 9)
                currentMinutes = "0" + currentMinutes

            if (currentSeconds <= 9)
                currentSeconds = "0" + currentSeconds

            return `${currentHours}:${currentMinutes}:${currentSeconds}`
        },

        ShowEvent(params) {
            this.$store.dispatch('loadMyScheduleShowEvent', params).then((r) => {
                this.formDateEventView = r;
                this.windowSchedule = true;
            }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 2000});
            })
        },

        deleteSubmit(item) {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar ?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    this.$store.dispatch('destroyMyScheduleEvent', item.id).then(() => {
                        this.closeModalShowEvent()
                        this.loadMySchedule()
                        toast.success("Evento deletado com sucesso", {timeout: 10000});
                    }).catch(() => {
                        toast.error(`Não foi possível deletar`, {timeout: 10000});
                    })
                }
            });

        },

        closeModalShowEvent() {
            this.clearFormDateEventView()
            this.windowSchedule = false
        },

        clearFormDateEvent() {
            this.formDateEvent = {
                schedule_id: this.id,
                name: '',
                date_start: '',
                time_start: this.timeCurrent(),
                date_end: '',
                time_end: this.timeCurrentAdd(),
                place: '',
                desc: '',
                color: '1',
                guests: []
            }
        },

        clearFormDateEventView() {
            this.formDateEventView = {
                creator_user_id: '',
                creator_user_name: '',
                name: '',
                date_start: '',
                time_start: '',
                date_end: '',
                time_end: '',
                place: '',
                desc: '',
                color: '',
                creator_user_photo: '',
                date_create: '',
                hour_create: ''
            }
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative;
        },

        selectUserList() {
            return this.$store.state.var.selectUserList;
        },

        selectGuestScheduleList() {
            return this.$store.state.var.selectGuestScheduleList;
        },
    }
}
</script>

<style>
.fc {
    margin-bottom: 100px;
}

.fc .fc-col-header-cell-cushion {
    color: #2C3E50;
    padding: 2px 4px;
    text-decoration: none;
    display: inline-block;
    text-transform: capitalize;
}

.fc .fc-toolbar-title {
    margin: 0;
    font-size: 1.75em;
    text-transform: capitalize;
}

.fc .fc-daygrid-day-number {
    z-index: 1;
    padding: 4px;
    color: #2C3E50;
    position: relative;
    text-decoration: none;
}

.fc .fc-toolbar-title {
    margin: 0;
    font-size: 1.75em;
    text-transform: capitalize;
    font-family: 'Comfortaa', sans-serif;
}

.fc-theme-standard .fc-list-day-cushion {
    z-index: 1;
    padding: 4px;
    color: #2C3E50;
    position: relative;
    text-decoration: none;
}

.fc .fc-today-button,
.fc .fc-button-primary:disabled {
    display: none;
}

.fc a {
    cursor: pointer;
}

.fc-direction-ltr .fc-list-day-text {
    float: left;
    color: #2C3E50;
    text-decoration: none;
    text-transform: capitalize;
}

@media (max-width: 661px) {
    .fc .fc-toolbar {
        display: block;
    }
}
</style>

<style scoped>
.line-division {
    width: 100%;
    border-top: 1px solid #c4c4c4;
}

.h1-text {
    color: white;
    padding: 10px;
    margin-top: 1px;
    font-size: 16px;
    width: fit-content;
    background-color: #36c1bd;
}

.character-label-icon-2 {
    height: 45px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 10px;
}

.aux-row-2 {
    width: 98%;
    display: flex;
    align-items: center;
}

.text {
    margin-right: 10px;
}

.aux-row-2 p {
    margin: 0 10px 0 0;
}

.aux-row-3 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.window-modal {
    top: 0;
    left: 0;
    z-index: 20;
    width: 100vw;
    height: 100vh;
    display: grid;
    position: fixed;
    place-items: center;
}

.modal-event {
    width: 850px;
    background: white;
    padding: 40px 20px;
    position: relative;
    height: fit-content;
    box-shadow: 10px 10px 23px 0 rgb(204 204 204);
}

.btn-close {
    top: 10px;
    right: 10px;
    cursor: pointer;
    position: absolute;
}

.card-container {
    width: 450px;
    background: #fff;
    position: relative;
    padding: 0 0 5px 0;
    box-shadow: 10px 10px 23px 0 rgb(204 204 204);
}

.header-card {
    width: 100%;
    display: flex;
    min-height: 50px;
    flex-direction: column;
    padding: 10px 0 0 15px;
    justify-content: space-evenly;
}

.footer-card {
    height: 50%;
    width: 100%;
    display: flex;
    padding-left: 10px;
    flex-direction: column;
}

.card-container img {
    width: 50px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 10px;
}

.card-container h3 {
    font-size: 16px;
}

.card-container h4 {
    font-size: 16px;
    font-weight: 400;
}

.skills {
    width: 100%;
    display: flex;
    margin-top: 20px;
    align-items: center;
    background-color: #fff;
}

.skills textarea {
    width: 98%;
    resize: none;
}

.places {
    width: 50%;
    display: flex;
    padding-left: 35px;
    position: relative;
    flex-direction: column;
}

.date-start {
    width: 50%;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.places i.icon {
    top: 0;
    left: 0;
    font-size: 1.2rem;
    position: absolute;
}

.date-start i.icon {
    left: 0;
    top: 20%;
    font-size: 1.2rem;
    position: absolute;
}

.blue-bg {
    color: white;
    background-color: #2185d0;
}

.green-bg {
    color: white;
    background-color: #16ab39;
}

.red-bg {
    color: white;
    background-color: #db2828;
}

.salmon-bg {
    background-color: #b5cc18;
}

.silver-bg {
    color: white;
    background-color: #5a5a5a;
}

@-webkit-keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

section {
    display: flex;
    justify-content: space-around;
}

.swatch {
    width: 60px;
    height: 60px;
    margin: 0.5rem;
    line-height: 60px;
    position: relative;
    border-radius: 60px;
    display: inline-block;
}

.swatch > [type=radio],
.swatch > [type=checkbox] {
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

.swatch > [type=radio] + label,
.swatch > [type=checkbox] + label {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    position: absolute;
    transition: all 0.5s ease-in-out;
}

.swatch > [type=radio] + label i,
.swatch > [type=checkbox] + label i {
    opacity: 0;
    font-size: 3rem;
    transition: opacity 0.5s;
}

.swatch > [type=radio]:checked + label i,
.swatch > [type=checkbox]:checked + label i {
    opacity: 1;
}

.swatch.orange > [type=radio] + label,
.swatch.orange > [type=checkbox] + label {
    color: #fff;
    background-color: #b5cc18;
}

.swatch.red > [type=radio] + label,
.swatch.red > [type=checkbox] + label {
    color: #fff;
    background-color: #5a5a5a;
}

.swatch.purple > [type=radio] + label,
.swatch.purple > [type=checkbox] + label {
    color: #fff;
    background-color: #2185d0;
}

.swatch.green > [type=radio] + label,
.swatch.green > [type=checkbox] + label {
    color: #fff;
    background-color: #16ab39;
}

.swatch.blue > [type=radio] + label,
.swatch.blue > [type=checkbox] + label {
    color: #fff;
    background-color: #db2828;
}

.swatch > [type=radio]:checked + label,
.swatch > [type=checkbox]:checked + label {
    width: 40px;
    height: 40px;
    display: grid;
    line-height: 43px;
    border-radius: 50%;
    place-items: center;
    transition: all 0.5s ease-in-out;
}

.swatch > [type=radio]:checked + label i,
.swatch > [type=checkbox]:checked + label i {
    opacity: 1;
    font-size: 1.5rem;
    transition: opacity 0.5s;
}

.swatch label::after,
.swatch label::before {
    display: none;
}

input[type="radio"] + label {
    cursor: pointer;
    padding: 0;
    line-height: unset;
}

@media (max-width: 991px) {
    .modal-event {
        width: 90%;
    }

    .card-container {
        height: 480px;
        width: 90%;
    }

    .skills {
        flex-direction: column;
        align-items: flex-start;
    }

    .date-start {
        width: 100%;
        align-items: flex-start;
        padding: 5px 5px 5px 50px;
    }

    .places {
        padding-left: 50px;
    }

    .close-event {
        margin: 0 10px 0 0;
    }
}
</style>