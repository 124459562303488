<template>
    <painel-component :itens="itensOne">

        <row-component>
            <row-col-component :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'">
                <label>Marca/Modelo:</label>
                <Multiselect label="name" v-model="formData.brand_model" :options="selectFleetList" :searchable="true"
                             :noOptionsText="noOptionsText"
                             :noResultsText="noResultsText"/>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'">
                <label>Status:</label>
                <Multiselect v-model="formData.status" :options="selectActiveInative" :searchable="true"
                             :noOptionsText="noOptionsText"
                             :noResultsText="noResultsText"/>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mt-4'">
                <button-submit @submit="submit" :items="buttonSubmit" class="me-2"/>
                <button-route v-if="buttonCheck.create" :items="buttonRoute"/>
            </row-col-component>
        </row-component>

        <table class="table table-striped">
            <thead>
            <tr>
                <th scope="col" style="width: 20%">Modelo / Marca</th>
                <th scope="col" style="width: 20%">Criado por:</th>
                <th scope="col" style="width: 20%">Quilometragem</th>
                <th scope="col" style="width: 20%">Combustível</th>
                <th scope="col" style="width: 10%">Status</th>
                <th scope="col" style="width: 10%" v-if="buttonCheck.show || buttonCheck.edit">
                    Ações
                </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(fleet, index) in object" :key="index">
                <td data-label="Modelo / Marca">
                    <img v-if="fleet.photo_fleet" class="avatar__image mx-1" :src="fleet.photo_fleet" alt="">
                    <img v-else class="avatar__image mx-1" src="@/assets/images/avatar.png" alt="">
                    {{ fleet.brand_model }}
                </td>
                <td data-label="Criado por:">
                    <img v-if="fleet.photo_creator" class="avatar__image mx-1" :src="fleet.photo_creator" alt="">
                    <img v-else class="avatar__image mx-1" src="@/assets/images/avatar.png" alt="">
                    {{ fleet.creator_user_name }}
                </td>
                <td data-label="Quilometragem">{{ fleet.km }}</td>
                <td data-label="Combustível">{{ stringFuel(fleet.fuel) }}</td>
                <td data-label="Status">{{ statusString(fleet.status) }}</td>
                <td data-label="Ações">
                    <button-edit v-if="buttonCheck.edit" :route="'fleet-edit'" :id-param="fleet.id"/>
                    <button-show v-if="buttonCheck.show" :route="'fleet-show'" :id-param="fleet.id"/>
                </td>
            </tr>
            </tbody>
        </table>

        <button-pagination :pagination="fleet.itens" :offset="3" @paginate="loadFleets"/>

    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from '@/components/row/RowColComponent';
import Multiselect from '@vueform/multiselect';
import ButtonSubmit from '@/components/buttons/ButtonSubmit';
import ButtonRoute from '@/components/buttons/ButtonRoute';
import ButtonEdit from '@/components/buttons/ButtonEdit';
import ButtonShow from '@/components/buttons/ButtonShow';
import {useToast} from "vue-toastification";
import {mask} from 'vue-the-mask'
import ButtonPagination from "@/components/buttons/ButtonPagination.vue";

const toast = useToast()

export default {
    name: 'FleetSearch',

    created() {
        this.$store.dispatch('updateNamePage', 'Frota')
        this.$store.dispatch('loadFleetMultiSelect')
        this.checkACL()
        this.loadFleets(1)
    },

    components: {
        ButtonPagination,
        PainelComponent,
        RowComponent,
        RowColComponent,
        Multiselect,
        ButtonSubmit,
        ButtonRoute,
        ButtonEdit,
        ButtonShow
    },

    directives: {
        mask
    },

    data() {
        return {
            itensOne: {
                title: "Pesquisa",
                icon: "ti-list"
            },

            buttonSubmit: {
                icon: 'ti-search',
            },

            buttonRoute: {
                icon: 'ti-plus',
                route: '/fleet/create'
            },

            buttonCheck: {
                edit: false,
                create: false,
                show: false
            },

            object: {},

            formData: {
                brand_model: '',
                status: ''
            },

            searchSubmit: false,
        }
    },

    methods: {

        submit() {
            this.loadFleets(1)
            this.searchSubmit = true
        },

        statusString(value) {
            if (value === '1')
                return 'Ativo'
            return 'Inativo'
        },

        stringFuel(value) {
            if (value === '1') {
                return 'Diesel'
            }
            if (value === '2') {
                return 'Elétrico'
            }
            if (value === '3') {
                return 'Etanol'
            }
            if (value === '4') {
                return 'Etanol/Gasolina'
            }
            if (value === '5') {
                return 'Gasolina'
            }
            if (value === '6') {
                return 'GNV(Gás Natural Veicular)'
            }
        },

        loadFleets(page) {
            if (!this.searchSubmit) {
                this.$store.dispatch('loadFleets', {...this.params, page})
                    .then((r) => {
                        this.object = r.itens.data
                        if (this.searchSubmit) {
                            this.searchSubmit = false;
                            if (r.itens.data == '') {
                                toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 2000});
                            }
                        }
                    }).catch(() => {
                    this.searchSubmit = false;
                });
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 5)
                .then((r) => {
                    if (r.data.indexOf('fleet-search') !== -1) {

                        if (r.data.indexOf('fleet-search') !== -1) {
                            this.buttonCheck.show = true
                        }

                        if (r.data.indexOf('fleet-create') !== -1) {
                            this.buttonCheck.create = true
                        }

                        if (r.data.indexOf('fleet-edit') !== -1) {
                            this.buttonCheck.edit = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

    },

    computed: {
        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        selectFleetList() {
            return this.$store.state.var.selectFleetList
        },

        fleet() {
            return this.$store.state.fleet.items
        },

        params() {
            return {
                page: this.fleet.current_page,
                brand_model: this.formData.brand_model,
                status: this.formData.status
            }
        },
    }

}
</script>

<style src="@/assets/style/table.css">

</style>

<style scoped>
.avatar__image {
    width: 25px;
}
</style>
