<template>
    <painel-component :itens="itensOne">

        <form v-if="showView">
            <div class="aux mb-4 mt-3">
                <div class="avatar-form">
                    <img v-if="formData.photo" :src="formData.photo" alt="" class="avatar__image">
                    <img v-else src="@/assets/images/avatar.png" alt="" class="avatar__image">
                </div>
                <p>{{ formData.name }}</p>
            </div>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <label>Sexo:</label>
                    <input disabled v-model="formData.sexo" type="radio" name="sexo" id="feminino" value="1">
                    <label for="feminino">Feminino</label>

                    <input disabled v-model="formData.sexo" type="radio" name="sexo" id="masculino" value="2">
                    <label for="masculino">Masculino</label>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <label>Nome:</label>
                    <input-component disabled :type-input="'text'" v-model="formData.name"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <label>Email:</label>
                    <input-component disabled :type-input="'text'" v-model="formData.email"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <label>Status:</label>
                    <Multiselect disabled v-model="formData.status" :options="selectActiveInative" :searchable="true"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <label>Grupo:</label>
                    <Multiselect disabled v-model="formData.group_id" :options="selectGroupUserList" :searchable="true"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <button-route :items="buttonRoute" style="width: 100%;"/>
                </row-col-component>
            </row-component>
        </form>

    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from '@/components/row/RowColComponent';
import InputComponent from '@/components/form/InputComponent';
import Multiselect from '@vueform/multiselect';
import ButtonRoute from '@/components/buttons/ButtonRoute';
import {useToast} from "vue-toastification";

const toast = useToast()

export default {
    name: 'UserShow',

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Usuário')
        this.$store.dispatch('loadGroupUserMultiSelect')
        this.checkACL()
        this.loadUser()
    },

    components: {
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        Multiselect,
        ButtonRoute,
    },

    data() {
        return {
            itensOne: {
                title: "Ver",
                icon: "ti-eye"
            },

            buttonRoute: {
                icon: 'ti-arrow-back-up',
                route: '/user/search',
                name: 'Voltar'
            },

            formData: {
                name: '',
                email: '',
                group_id: '',
                photo: '',
                status: '',
                sexo: '',
            },

            showView: false

        }
    },

    methods: {

        loadUser() {
            this.$store.dispatch('loadUser', this.id)
                .then((r) => {
                    this.formData = r
                }).catch(() => {
                toast.error('Erro desconhecido', {timeout: 2000})
            })
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 2)
                .then((r) => {
                    if (r.data.indexOf('user-search') !== -1) {
                        this.showView = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

    },

    computed: {
        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        selectGroupUserList() {
            return this.$store.state.var.selectGroupUserList
        },

    }

}
</script>