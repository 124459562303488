<template>
    <div class="flag"
         :class="[{'entrance-color' : formData.type === '1', 'exit-color' : formData.type === '2'}]">
        <i :class="[{'ti ti-circle-arrow-up' :formData.type === '1', 'ti ti-circle-arrow-down' :formData.type === '2'}]"></i>
        {{ statusEntranceExit(formData.type) }}
        <div @click="$emit('close-modal')" class="btn-close clearfix"></div>
    </div>

    <div class="mt-4">
        <row-component>
            <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <div class="d-flex align-items-start flex-column">
                    <div class="d-flex">
                        <p class="my-0">Criado por:</p>
                        <img v-if="formData.photo_user" class="avatar__image mx-2" :src="formData.photo_user" alt="">
                        <img v-else class="avatar__image mx-2" src="@/assets/images/avatar.png" alt="">
                        {{ formData.name_user }}
                    </div>
                    <p>Código: {{ formData.code }}</p>
                </div>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <div class="d-flex align-items-center">
                    <p class="d-inline-block mx-3">Data de Criação: <br> <i class="ti ti-calendar-due"></i>
                        {{ formData.date_create }}</p>
                    <p class="d-inline-block mx-3">Horário de criação: <br> <i class="ti ti-clock-filled"></i>
                        {{ formData.hour_create }}</p>
                </div>
            </row-col-component>
            <hr>
        </row-component>

        <row-component>
            <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <div>
                    <p><strong>{{ statusHistoric(historic.historic_type) }}</strong></p>
                    <img v-if="formData.photo_user" class="avatar__image me-2" :src="formData.photo_user" alt="">
                    <img v-else class="avatar__image me-2" src="@/assets/images/avatar.png" alt="">
                    {{ formData.name_user }}
                </div>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <div class="d-flex align-items-center">
                    <p class="d-inline-block mx-3">Data de Criação: <br> <i class="ti ti-calendar-due"></i>
                        {{ historic.historic_date_create }}</p>
                    <p class="d-inline-block mx-3">Horário de criação: <br> <i class="ti ti-clock-filled"></i>
                        {{ historic.historic_time_create }}</p>
                </div>
            </row-col-component>
            <hr>
        </row-component>

        <row-component>
            <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                <p><strong>Conta Bancária:</strong></p>
                {{ formData.bank_accounts_name }}
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                <p><strong>Valor:</strong></p>
                R$ {{ formData.price }}
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                <p><strong>Vencimento:</strong></p>
                {{ formData.due_date }}
            </row-col-component>
            <hr>
        </row-component>

        <row-component>
            <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <label>Tipo de Conta:</label>
                <Multiselect disabled v-model="formData.account_types_id" :options="selectAccountList"
                             :searchable="true"
                             :noOptionsText="noOptionsText"
                             :noResultsText="noResultsText"/>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <label>Sub Conta:</label>
                <Multiselect disabled v-model="formData.account_type_sub_id" :options="selectAccountSubList"
                             :searchable="true"
                             :noOptionsText="noOptionsText"
                             :noResultsText="noResultsText"/>
            </row-col-component>
        </row-component>

        <row-component>
            <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <label>Antecipado:</label>
                <input-component disabled :type-input="'text'" v-model="formData.attended"/>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <label>Valor Pago:</label>
                <input-component disabled :type-input="'text'" v-model="formData.price_paid"/>
            </row-col-component>
        </row-component>
    </div>

</template>

<script>
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from '@/components/row/RowColComponent';
import InputComponent from '@/components/form/InputComponent';
import Multiselect from '@vueform/multiselect';
import {useToast} from "vue-toastification";

const toast = useToast()

export default {
    name: 'EntranceExitShow',

    props: {
        id: {
            required: true
        }
    },

    emits:['close-modal'],

    created() {
        this.$store.dispatch('updateNamePage', 'Entrada/Saída')
        this.$store.dispatch('loadAccountBankMultiSelect')
        this.$store.dispatch('loadAccountTypeMultiSelect')
        this.loadEntranceExit()
    },

    components: {
        RowComponent,
        RowColComponent,
        InputComponent,
        Multiselect,
    },

    data() {
        return {
            itensOne: {
                title: "Pesquisa",
                icon: "ti-list"
            },

            buttonSubmit: {
                icon: 'ti-search',
            },

            buttonRoute: {
                icon: 'ti-plus',
                route: '/entrance-exit/create'
            },

            buttonCheck: {
                create: false,
            },

            formData: {
                code: '',
                type: '',
                type_preview: '',
                bank_accounts_id: '',
                account_types_id: '',
                due_date: '',
                price: '',
                price_paid: '',
                obs: '',
                user_id: '',
                user_name: '',
                account_type_sub: '',
                account_type_sub_id: '',
                effective_date: '',
                attended: '',
                photo_user: '',
                name_user: '',
                date_create: '',
                hour_create: '',
                account_types_name: '',
                bank_accounts_name: '',
                bank_accounts_photo: '',
            },

            historic: {},
            searchSubmit: false,
        }
    },

    methods: {
        loadEntranceExit() {
            this.$store.dispatch('loadEntranceExit', this.id)
                .then((r) => {
                    this.formData = r
                    this.historic = r.historic
                    this.statusAttended(r.attended)
                    this.loadAccountTypeSubMultiSelect()
                }).catch(() => {
                toast.error('Erro desconhecido', {timeout: 2000})
            })
        },

        loadAccountTypeSubMultiSelect() {
            this.$store.dispatch('loadAccountTypeSubMultiSelect', this.formData.account_types_id)
        },

        statusEntranceExit(value) {
            if (parseInt(value) === 1)
                return 'Entrada'
            return 'Saída'
        },

        statusAttended(value) {
            if (parseInt(value) === 1)
                return this.formData.attended = 'Não'

            return this.formData.attended = 'Sim'
        },

        statusHistoric(value) {
            if (parseInt(value) === 1)
                return 'Baixa realizada por:'

            if (parseInt(value) === 2)
                return 'Lançamento direto realizada por:'

            if (parseInt(value) === 2)
                return 'Editado por:'
        }
    },

    computed: {
        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        selectAccountSubList() {
            return this.$store.state.var.selectAccountSubList
        },

        selectAccountList() {
            return this.$store.state.var.selectAccountList
        },
    }
}
</script>

<style scoped>
.avatar__image {
    width: 35px;
}

.flag {
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    color: #FFFFFF;
    font-weight: bold;
    position: absolute;
}

.flag i {
    font-size: 18px;
}

.entrance-color {
    background-color: #16ab39;
}

.exit-color {
    background-color: #db2828;
}

.btn-close {
    float: right;
    cursor: pointer;
}
</style>