import axios from "axios";

import {URL_BASE} from "@/configs/constants";

const RESOURCE = "guest-schedule";

export default {
    loadGuestSchedules(context, params) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}`, {params})
                .then((response) => {
                    context.commit("LOAD_GUEST_SCHEDULES", response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    storeGuestSchedule(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}${RESOURCE}`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    loadGuestSchedule(context, id) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}/${id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    updateGuestSchedule(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}${RESOURCE}/${formData.id}`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    destroyGuestSchedule(context, id) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_BASE}${RESOURCE}/${id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    updateGuestSchedulePhoto(context, formData) {
        let config = {headers: {'Content-Type': 'multipart/form-data'}}
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}guest-schedule-photo`, formData, config)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    }
}