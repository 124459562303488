<template>
    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>

        <div class="tabs effect-3" v-if="showView">
            <input checked type="radio" id="tab-1" name="tab-effect-3">
            <span>
                <i class="ti ti-info-square-rounded"></i>
                <p>Informações</p>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3">
            <span>
                <i class="ti ti-box"></i>
                <p>Produtos</p>
            </span>

            <input type="radio" id="tab-3" name="tab-effect-3">
            <span>
                <i class="ti ti-settings"></i>
                <p>Serviços</p>
            </span>

            <input type="radio" id="tab-4" name="tab-effect-3">
            <span>
                <i class="ti ti-cash-banknote"></i>
                <p>Forma de Pagamento</p>
            </span>

            <div class="line ease"></div>
            <div class="tab-content">

                <section id="tab-item-1">
                    <form>
                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                    :class="{'formError' : errors.name}">
                                <label>Nome:</label>
                                <input-component :type-input="'text'" v-model="formData.name"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                    :class="{'formError' : errors.status}">
                                <label>Status:</label>
                                <Multiselect v-model="formData.status" :options="selectActiveInative"
                                             :searchable="true"
                                             :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                    :class="{'formError' : errors.obs}">
                                <label>Observações:</label>
                                <input-text-area :rows="4" v-model="formData.obs"/>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>

                <section id="tab-item-2">
                    <div class="clearfix">
                        <button-submit @click="addProduct" class="fs-5 float-end" :items="buttonAdd"/>
                    </div>

                    <form v-for="(data, index) in productAdd" :key="index" class="card p-3 bg-light my-3">
                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                <label>Marca:</label>
                                <Multiselect v-model="data.brand_id" :options="selectBrandList" :searchable="true"
                                             :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                <label>Categoria:</label>
                                <Multiselect v-model="data.categorie_id" :options="selectCategoryList"
                                             @select="loadProductBrandMultiSelect(data.brand_id, data.categorie_id, index)"
                                             @clear="clearBrand(index)" :searchable="true"
                                             :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                <label>Produto:</label>
                                <input-component v-if="data.categorie_id === '' || data.categorie_id === null" disabled
                                                 :type-input="'text'"/>
                                <Multiselect v-else label="name" v-model="data.product_id"
                                             :options="selectProductBrandList"
                                             @select="loadProductPriceMultiSelect(data.product_id, index)"
                                             @clear="clearProduct(index)" :searchable="true"
                                             :noOptionsText="'Selecione uma marca'" :noResultsText="noResultsText"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                <label>Quantidade:</label>
                                <input-component @keyup="calcProduct(index)" min="1" :type-input="'number'"
                                                 v-model="data.amount"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                <label>Preço UN:</label>
                                <input-component v-money="money" @keyup="calcProduct(index)" :type-input="'text'"
                                                 v-model="data.unit_price"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                <label>Preço Total:</label>
                                <input-component disabled :type-input="'text'" v-model="data.total_price"/>
                            </row-col-component>
                        </row-component>

                        <div class="clearfix" v-if="formData.products.length >= 2">
                            <button-delete @click="productRemove(index)" class="fs-5 float-end"/>
                        </div>
                    </form>
                </section>

                <section id="tab-item-3">
                    <div class="clearfix">
                        <button-submit @click="addService" class="fs-5 float-end" :items="buttonAdd"/>
                    </div>
                    <form v-for="(data, index) in serviceAdd" :key="index" class="card p-3 bg-light my-3">
                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                <label>Serviço:</label>
                                <Multiselect v-model="data.service_id" :options="selectServiceList"
                                             @select="loadServicePriceMultiSelect(data.service_id, index)"
                                             @clear="clearService(index)"
                                             :searchable="true"
                                             :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                <label>Quantidade:</label>
                                <input-component @keyup="calcService(index)" min="1" :type-input="'number'"
                                                 v-model="data.amount"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                <label>Preço UN:</label>
                                <input-component v-money="money" @keyup="calcService(index)" :type-input="'text'"
                                                 v-model="data.unit_price"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'">
                                <label>Preço Total:</label>
                                <input-component disabled :type-input="'text'" v-model="data.total_price"/>
                            </row-col-component>
                        </row-component>

                        <div class="clearfix" v-if="formData.services.length >= 2">
                            <button-delete @click="serviceRemove(index)" class="fs-5 float-end"/>
                        </div>
                    </form>
                </section>

                <section id="tab-item-4">
                    <QuillEditor theme="snow" :contentType="'html'" ref="myEditor"
                                 v-model:content="formData.form_of_payment" style="min-height: 250px"/>
                </section>
            </div>
        </div>

        <row-component>
            <row-col-component
                    :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <button-submit :disaabled="sendForm" @submit="submit" :items="buttonSubmit" style="width: 100%;"/>
            </row-col-component>
            <row-col-component
                    :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <button-route :items="buttonRoute" style="width: 100%;"/>
            </row-col-component>
        </row-component>

    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from '@/components/row/RowColComponent';
import InputComponent from '@/components/form/InputComponent';
import Multiselect from '@vueform/multiselect';
import ButtonSubmit from '@/components/buttons/ButtonSubmit';
import ButtonRoute from '@/components/buttons/ButtonRoute';
import InputTextArea from '@/components/form/InputTextArea';
import ButtonDelete from "@/components/buttons/ButtonDelete";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import {MONEY} from "@/configs/constants";
import {VMoney} from "v-money";
import {QuillEditor} from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: 'BudgetTemplateCreate',

    created() {
        this.$store.dispatch('updateNamePage', 'Modelo de Orçamento')
        this.$store.dispatch('loadProductMultiSelect')
        this.$store.dispatch('loadServiceMultiSelect')
        this.$store.dispatch('loadBrandMultiSelect')
        this.$store.dispatch('loadCategoryMultiSelect')
        this.checkACL()
        this.cloneFunction()
        this.nullInputArray()
    },

    components: {
        ErrorComponent,
        ButtonDelete,
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        Multiselect,
        ButtonSubmit,
        ButtonRoute,
        InputTextArea,
        QuillEditor
    },

    directives: {
        money: VMoney
    },

    data() {
        return {
            itensOne: {
                title: "Adicionar",
                icon: "ti-pencil-plus"
            },

            buttonSubmit: {
                icon: 'ti-circle-plus',
                nameButton: 'Adicionar'
            },

            buttonRoute: {
                icon: 'ti-arrow-back-up',
                route: '/budget-template/search',
                name: 'Voltar'
            },

            buttonAdd: {
                icon: 'ti-circle-plus',
            },

            formData: {
                name: '',
                status: '',
                obs: '',
                products: [],
                services: [],
                form_of_payment: ''
            },

            money: MONEY,

            errors: [],
            showView: false,
            sendForm: false,
            showError: false,
        }
    },

    methods: {

        loadProductPriceMultiSelect(id, index) {
            this.$store.dispatch('loadProductPriceMultiSelect', id)
                .then((r) => {
                    this.productAdd[index].unit_price = r
                    this.calcProduct(index)
                })
        },

        cloneFunction() {
            if (this.storeState.length > 0) {
                if (this.storeState.length > 1)
                    this.storeState.splice(0, 1)
                this.formData = this.storeState[0]
            }
        },

        clearBrand(index) {
            this.productAdd[index].product_id = ''
            this.productAdd[index].brand_id = ''
            this.productAdd[index].amount = 1
            this.productAdd[index].unit_price = ''
            this.productAdd[index].total_price = ''
            this.calcProduct(index)
        },

        clearProduct(index) {
            this.productAdd[index].amount = 1
            this.productAdd[index].unit_price = ''
            this.productAdd[index].total_price = ''
            this.calcProduct(index)
        },

        loadServicePriceMultiSelect(id, index) {
            this.$store.dispatch('loadServicePriceMultiSelect', id)
                .then((r) => {
                    this.serviceAdd[index].unit_price = r
                    this.calcService(index)
                })
        },

        clearService(index) {
            this.serviceAdd[index].amount = 1
            this.serviceAdd[index].unit_price = ''
            this.serviceAdd[index].total_price = ''
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('storeBudgetTemplate', this.formData)
                    .then((r) => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success("Cadastro efetuado com sucesso", {timeout: 2000});
                        this.$router.push({name: 'budget-template-edit', params: {id: r.data}})
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollToTop()
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 12)
                .then((r) => {
                    if (r.data.indexOf('budget-template-create') !== -1) {
                        this.showView = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        scrollToTop() {
            window.scrollTo(0, 0);
        },

        nullInputArray() {
            if (this.formData.products.length === 0)
                this.addProduct()
            if (this.formData.services.length === 0)
                this.addService()
        },

        addProduct() {
            this.formData.products.push({
                brand_id: '',
                product_id: '',
                categorie_id: '',
                amount: 1,
                unit_price: '',
                total_price: ''
            })
        },

        addService() {
            this.formData.services.push({
                service_id: '',
                amount: 1,
                unit_price: '',
                total_price: ''
            })
        },

        loadProductBrandMultiSelect(brand, category, index) {
            let formData = {
                brand: brand,
                category: category
            }
            this.$store.dispatch('loadProductBrandMultiSelect', formData)
            this.clearProduct(index)
        },

        productRemove(index) {
            this.formData.products.splice(index, 1)
        },

        serviceRemove(index) {
            this.formData.services.splice(index, 1)
        },

        replaceStringOne(label) {
            if (label.length < 8) {
                return parseFloat(label.replace(',', '.'))
            } else {
                return parseFloat(label.replace(',', ''))
            }
        },

        calcProduct(index) {
            let budget = this.formData.products[index];
            budget.unit_price.toLocaleString('pt-br', {minimumFractionDigits: 2});
            let value = budget.unit_price.replace(/[.,]/g, '')
            let amount = budget.amount
            let result = (value * amount / 100)
            budget.total_price = result.toLocaleString('pt-br', {minimumFractionDigits: 2})
            this.calcTotalProduct()
        },

        calcTotalProduct() {
            let sum = 0;
            if (this.totalResult > 0)
                sum = this.replaceStringOne(this.totalResult)
            this.formData.products.forEach(item => {
                sum += this.replaceStringOne(item.total_price.replace(/[.,]/g, '')) / 100;
            });
            this.totalResult = sum.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2});
        },

        calcService(index) {
            let budget = this.formData.services[index];
            budget.unit_price.toLocaleString('pt-br', {minimumFractionDigits: 2});
            let value = budget.unit_price.replace(/[.,]/g, '')
            let amount = budget.amount
            let result = (value * amount / 100)
            budget.total_price = result.toLocaleString('pt-br', {minimumFractionDigits: 2})
            this.calcTotalService()
        },

        calcTotalService() {
            let sum = 0;
            if (this.totalResult > 0)
                sum = this.replaceStringOne(this.totalResult)
            this.formData.services.forEach(item => {
                sum += this.replaceStringOne(item.total_price.replace(/[.,]/g, '')) / 100;
            });
            this.totalResult = sum.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2});
        },

    },

    beforeMount() {
        this.storeState.splice(0, 1)
    },

    computed: {

        me() {
            return this.$store.state.auth.me
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        selectBrandList() {
            return this.$store.state.var.selectBrandList
        },

        selectCategoryList() {
            return this.$store.state.var.selectCategoryList
        },

        selectProductBrandList() {
            return this.$store.state.var.selectProductBrandList
        },

        selectServiceList() {
            return this.$store.state.var.selectServiceList
        },

        productAdd() {
            return this.formData.products;
        },

        serviceAdd() {
            return this.formData.services;
        },

        storeState() {
            return this.$store.state.var.storeState
        }
    }

}
</script>

<style scoped>
.tabs > input,
.tabs > span,
.effect-3 .line {
    width: calc(100% / 4);
}

#tab-2,
#tab-2 + span,
#tab-2:checked ~ .line {
    left: 25%
}

#tab-3,
#tab-3 + span,
#tab-3:checked ~ .line {
    left: 50%;
}

#tab-4,
#tab-4 + span,
#tab-4:checked ~ .line {
    left: 75%;
}
</style>