import AdminComponent from '@/components/dashboard/AdminComponent'
import ScheduleSearch from "@/views/search/ScheduleSearch";
import ScheduleCreate from "@/views/create/ScheduleCreate";
import ScheduleUpdate from "@/views/update/ScheduleUpdate";
import ScheduleShow from "@/views/show/ScheduleShow";

export default {
    path: '/schedule',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'search', name: 'schedule-search', component: ScheduleSearch},
        {path: 'create', name: 'schedule-create', component: ScheduleCreate},
        {path: 'edit/:id', name: 'schedule-edit', component: ScheduleUpdate, props: true},
        {path: 'show/:id', name: 'schedule-show', component: ScheduleShow, props: true},
    ]
}
