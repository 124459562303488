import AdminComponent from "@/components/dashboard/AdminComponent";

import ReportProduct from "@/views/create/ReportProduct";

export default {
    path: "/report-product",
    meta: {auth: true},
    component: AdminComponent,
    children: [
        {path: "product", name: "report-product", component: ReportProduct},
    ]
}