<template>
    <painel-component :itens="itemsOne">
        <form v-if="showView">
            <row-component>
                <row-col-component class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                    <label>Nome:</label>
                    <input-component disabled v-model="formData.name" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Status:</label>
                    <Multiselect disabled v-model="formData.status" :options="selectActiveInative" :searchable="true"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Telefone:</label>
                    <input-component disabled v-model="formData.phone" v-mask="['(##) ####-####', '(##) #####-####']"
                                     type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                    <label>Email:</label>
                    <input-component disabled v-model="formData.email" type-input="email"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <label>Observações:</label>
                    <input-text-area disabled v-model="formData.desc" :rows="6"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itemsRoute"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import InputTextArea from "@/components/form/InputTextArea";
import ButtonRoute from "@/components/buttons/ButtonRoute";

import {mask} from "vue-the-mask";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "GuestScheduleShow",
    components: {
        ButtonRoute,
        InputTextArea,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    directives: {
        mask
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch("updateNamePage", "Convidado Agenda")
        this.checkACL()
        this.loadItems()
    },

    data() {
        return {
            itemsOne: {
                icon: "ti-eye",
                title: "Ver",
            },

            itemsRoute: {
                route: "/guest-schedule/search",
                icon: "ti-arrow-back-up",
                name: "Voltar"
            },

            formData: {
                id: this.id,
                name: "",
                phone: "",
                email: "",
                status: "",
                desc: ""
            },

            showView: false
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch("loadGuestSchedule", this.id)
                .then(response => this.formData = response)
                .catch(() => {
                    toast.error("Erro desconhecido", {timeout: 2000})
                })
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 21)
                .then((r) => {
                    if (r.data.indexOf('guest-schedule-search') !== -1) {
                        this.showView = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },
    }
}

</script>