import actions from "@/store/modules/schedule/actions";
import getters from "@/store/modules/schedule/getters";
import mutations from "@/store/modules/schedule/mutations";
import state from "@/store/modules/schedule/state";

export default {
    actions,
    getters,
    mutations,
    state
}