<template>
    <div class="menuContainer" :class="{ openMenuContainer: openMenu }">
        <div class="logoContainer">
            <router-link to="/" @click="activeLinkRouteRemove">
                <img src="@/assets/logo.png" alt="D7M marketing">
            </router-link>
        </div>

        <div class="userContainer" @click="dropMenu.navUsuario = !dropMenu.navUsuario">
            <div class="avatar">
                <img v-if="me.photo" :src="me.photo" alt="">
                <img v-else src="@/assets/images/avatar.png" alt="">
                <p>{{ me.name }}
                    <i :class="dropMenu.navUsuario ? 'ti ti-chevron-down iconMenu' : 'ti ti-chevron-right iconMenu'"></i>
                </p>
            </div>
            <transition name="slide">
                <ul class="links submenu userMenu" v-if="dropMenu.navUsuario">
                    <li>
                        <router-link :to="{name: 'user-edit-photo'}">
                            <i class="ti ti-photo"></i> Alterar Foto
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{name: 'user-edit-password'}">
                            <i class="ti ti-lock"></i>Alterar Senha
                        </router-link>
                    </li>
                    <li><a href="#" @click.prevent="logout"><i class="ti ti-logout"></i> Sair</a></li>
                </ul>
            </transition>
        </div>

        <div class="linkContainer" v-if="showItensMenu.pageCadastro">
            <ul>
                <li @click="dropMenu.navCadastro = !dropMenu.navCadastro"><a href="#">
                    <i :class="'icon ' + linksMenu.menuCadastro.icon"></i>
                    {{ linksMenu.menuCadastro.name }}
                    <i :class="dropMenu.navCadastro ? 'ti ti-chevron-down iconMenu' : 'ti ti-chevron-right iconMenu'"></i></a>
                </li>
                <transition name="dropMenu">
                    <div class="submenu" v-if="dropMenu.navCadastro">
                        <div v-for="(link, index) in linksMenu.menuCadastro.subLinks" :key="index">
                            <router-link v-if="checkPermissionMenu(link.pagePermissions)" :to="link.route"
                                         :class="[{ 'activeBackground': (activeLinkRoute(link.route)) }, 'linkroute']">
                                {{ link.name }}
                            </router-link>
                        </div>
                    </div>
                </transition>
            </ul>
        </div>

        <div class="linkContainer" v-if="showItensMenu.pageEstoque">
            <ul>
                <li @click="dropMenu.navEstoque = !dropMenu.navEstoque"><a href="#">
                    <i :class="'icon ' + linksMenu.menuEstoque.icon"></i>
                    {{ linksMenu.menuEstoque.name }}
                    <i :class="dropMenu.navEstoque ? 'ti ti-chevron-down iconMenu' : 'ti ti-chevron-right iconMenu'"></i></a>
                </li>
                <transition name="dropMenu">
                    <div class="submenu" v-if="dropMenu.navEstoque">
                        <div v-for="(link, index) in linksMenu.menuEstoque.subLinks" :key="index">
                            <router-link v-if="checkPermissionMenu(link.pagePermissions)" :to="link.route"
                                         :class="[{ 'activeBackground': (activeLinkRoute(link.route)) }, 'linkroute']">
                                {{ link.name }}
                            </router-link>
                        </div>
                    </div>
                </transition>
            </ul>
        </div>

        <div class="linkContainer" v-if="showItensMenu.pageFinanceiro">
            <ul>
                <li @click="dropMenu.navFinanceiro = !dropMenu.navFinanceiro"><a href="#">
                    <i :class="'icon ' + linksMenu.menuFinanceiro.icon"></i>
                    {{ linksMenu.menuFinanceiro.name }}
                    <i :class="dropMenu.navFinanceiro ? 'ti ti-chevron-down iconMenu' : 'ti ti-chevron-right iconMenu'"></i></a>
                </li>
                <transition name="dropMenu">
                    <div class="submenu" v-if="dropMenu.navFinanceiro">
                        <div v-for="(link, index) in linksMenu.menuFinanceiro.subLinks" :key="index">
                            <router-link v-if="checkPermissionMenu(link.pagePermissions)" :to="link.route"
                                         :class="[{ 'activeBackground': (activeLinkRoute(link.route)) }, 'linkroute']">
                                {{ link.name }}
                            </router-link>
                        </div>
                    </div>
                </transition>
            </ul>
        </div>

        <div class="linkContainer" v-if="showItensMenu.pageAgenda">
            <ul>
                <li @click="dropMenu.navAgenda = !dropMenu.navAgenda"><a href="#">
                    <i :class="'icon ' + linksMenu.menuAgenda.icon"></i>
                    {{ linksMenu.menuAgenda.name }}
                    <i :class="dropMenu.navAgenda ? 'ti ti-chevron-down iconMenu' : 'ti ti-chevron-right iconMenu'"></i></a>
                </li>
                <transition name="dropMenu">
                    <div class="submenu" v-if="dropMenu.navAgenda">
                        <div v-for="(link, index) in linksMenu.menuAgenda.subLinks" :key="index">
                            <router-link v-if="checkPermissionMenu(link.pagePermissions)" :to="link.route"
                                         :class="[{ 'activeBackground': (activeLinkRoute(link.route)) }, 'linkroute']">
                                {{ link.name }}
                            </router-link>
                        </div>
                    </div>
                </transition>
            </ul>
        </div>

        <div class="linkContainer" v-if="showItensMenu.pageRelatorio">
            <ul>
                <li @click="dropMenu.navRelatorio = !dropMenu.navRelatorio"><a href="#">
                    <i :class="'icon ' + linksMenu.menuRelatorio.icon"></i>
                    {{ linksMenu.menuRelatorio.name }}
                    <i :class="dropMenu.navRelatorio ? 'ti ti-chevron-down iconMenu' : 'ti ti-chevron-right iconMenu'"></i></a>
                </li>
                <transition name="dropMenu">
                    <div class="submenu" v-if="dropMenu.navRelatorio">
                        <div v-for="(link, index) in linksMenu.menuRelatorio.subLinks" :key="index">
                            <router-link v-if="checkPermissionMenu(link.pagePermissions)" :to="link.route"
                                         :class="[{ 'activeBackground': (activeLinkRoute(link.route)) }, 'linkroute']">
                                {{ link.name }}
                            </router-link>
                        </div>
                    </div>
                </transition>
            </ul>
        </div>
    </div>
</template>

<script>
import linksMenu from '@/configs/linksMenu';
import swal from 'sweetalert';
import {useToast} from "vue-toastification";

const toast = useToast();
export default {
    name: "NavMenu",

    props: {
        openMenu: {
            "required": true,
            "type": Boolean,
        },
    },

    created() {
        this.checkACLnav()
    },

    data() {
        return {
            linksMenu,
            dropMenu: {
                navUsuario: false,
                navCadastro: false,
                navEstoque: false,
                navFinanceiro: false,
                navAgenda: false,
                navRelatorio: false
            },

            activeLink: false,
            permissionsPage: [],
            showItensMenu: {
                pageCadastro: false,
                pageEstoque: false,
                pageFinanceiro: false,
                pageAgenda: false,
                pageRelatorio: false
            }
        }
    },

    methods: {
        activeLinkRoute(nameLink) {
            let x1 = nameLink.split("/");
            return this.$route.matched[0].path === "/" + x1[1];
        },

        activeLinkRouteRemove() {
            this.activeLink = false;
        },

        logout() {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente sair do sistema ${this.me.name} ?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,

                    },
                    confirm: "Sim"
                }

            }).then((result) => {
                if (result) {
                    this.$store.dispatch('logout')
                    toast.success("Logout efetuado com sucesso!", {timeout: 2000})
                    setTimeout(() => this.$router.push({name: 'login'}), 2000)
                }
            });
        },

        async checkACLnav() {
            this.$store.dispatch('ACLitensnav')
                .then((r) => {
                    this.permissionsPage = r.data;
                    this.permissionsPage.forEach(item => {

                        if (linksMenu.menuCadastro.pageLinksNavCheck.includes(item)) {
                            this.showItensMenu.pageCadastro = true
                        }

                        if (linksMenu.menuEstoque.pageLinksNavCheck.includes(item)) {
                            this.showItensMenu.pageEstoque = true
                        }

                        if (linksMenu.menuFinanceiro.pageLinksNavCheck.includes(item)) {
                            this.showItensMenu.pageFinanceiro = true
                        }

                        if (linksMenu.menuAgenda.pageLinksNavCheck.includes(item)) {
                            this.showItensMenu.pageAgenda = true
                        }

                        if (linksMenu.menuRelatorio.pageLinksNavCheck.includes(item)) {
                            this.showItensMenu.pageRelatorio = true
                        }
                    })
                })
        },

        checkPermissionMenu(page) {
            let shouldSkip = false;
            this.permissionsPage.forEach(value => {
                if (shouldSkip) {
                    return;
                }
                if (page.includes(value)) {
                    shouldSkip = true;
                }
            });
            return shouldSkip;
        },
    },

    computed: {
        me() {
            return this.$store.state.auth.me
        },
    },

}
</script>

<style scoped>
.userMenu {
    margin-top: 15px;
}

.menuContainer {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 260px;
    min-height: 100vh;
    box-shadow: 0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%);
    padding: 0 10px;
    overflow-x: auto;
}

.menuContainer .logoContainer {
    border-bottom: 1px inset #c3c3c3;
    padding: 10px 0;
}

.menuContainer .logoContainer img {
    max-width: 110px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.menuContainer .userContainer {
    border-bottom: 1px solid #c3c3c3;
    padding-top: 5px;
    padding-bottom: 5px;
    min-height: 48px;
}

.menuContainer .userContainer:hover {
    cursor: pointer;
}

.menuContainer .userContainer .avatar img {
    max-width: 35px;
    border-radius: 50%;
    float: left;
    margin-right: 10px;
}

.menuContainer .userContainer p {
    color: #000;
    margin: 0;
    padding-top: 5px;
}

.menuContainer .submenu {
    clear: both;
    list-style-type: none;
    transform-origin: top;
    transition: transform 0.4s ease-in-out;
    overflow: hidden;
    background-color: #e3e3e3;
    width: 100%;
}

.menuContainer .submenu li {
    padding: 5px 0;
}

.menuContainer .submenu li a {
    font-size: 16px;
}

.menuContainer .links a {
    display: block;
    text-decoration: none;
    color: #000;
    line-height: 1.5em;
    height: 35px;
}

.menuContainer .links a i {
    color: #686666;
    font-size: 16px;
    margin-right: 2px;
}

.menuContainer .iconMenu {
    position: absolute;
    right: 15px;
}

.linkroute {
    display: block;
    padding: 10px;
    height: 45px;
    background-color: #ebebeb;
    text-decoration: none;
    color: #000000;
}

.linkroute:hover {
    background-color: rgba(54, 193, 189, 1);
    color: #ffffff;
    -webkit-transition: background-color 500ms linear;
    -ms-transition: background-color 500ms linear;
    transition: background-color 500ms linear;
}

.linkContainer ul {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-top: 10px;
}

.linkContainer ul:hover {
    cursor: pointer;
}

.linkContainer ul li {
    padding: 10px;
    height: 45px;
    background-color: #ebebeb;
}

.linkContainer ul li a {
    color: #000000;
    text-decoration: none;
    font-size: 16px;
}

.linkContainer ul li img {
    margin: 0 0.25rem 0.20rem 0;
    height: 1em;
    text-align: center;
}

.linkContainer .iconMenu {
    position: absolute;
    right: 15px;
}

.linkContainer .submenu {
    list-style-type: none;
    transform-origin: top;
    transition: transform 0.4s ease-in-out;
    overflow: hidden;
    width: 100%;
}

.activeBackground {
    background-color: #36c1bd;
    color: white !important;
}

.dropMenu-enter,
.dropMenu-leave-to,
.dropMenu-enter-from {
    transform: scaleY(0);
}

.slide-enter,
.slide-leave-to,
.slide-enter-from {
    transform: scaleY(0);
}

@media (max-width: 991px) {
    .menuContainer {
        left: -260px;
        transition: all 0.6s ease-in-out;
    }

    .openMenuContainer {
        left: 0px;
        transition: all 0.6s ease-in-out;
    }
}
</style>


