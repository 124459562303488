<template>
    <router-link :to="{ name: route, params: { id: idParam } }" class="btn btn-secondary buttonRound mx-1">
        <i class="ti ti-eye"></i>
    </router-link>
</template>
  
<script>
export default {
    name: "ButtonShow",

    props: {
        route: {
            'type': String,
            "required": true,
        },

        idParam: {
            'type': Number,
            "required": true,
        }
    }
}
</script>
  
<style scoped>
.buttonRound i {
    padding-top: 5px;
}
</style>