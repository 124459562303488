<template>
    <div class="position-fixed vw-100 vh-100 z-3 start-0 top-0" v-if="historic_modal">
        <div style="width: 60%"
             class="h-auto p-3 bg-white shadow-lg position-absolute top-50 start-50 translate-middle">
            <div class="clearfix">
                <div role="button" @click="historic_modal = false" class="btn-close float-end"></div>
            </div>
            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <label>Adicionar Histórico:</label>
                    <input-text-area rows="6" v-model="historic.obs"/>
                </row-col-component>

                <row-col-component
                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <button-submit @submit="submitHist" :items="buttonAddHistoric" class="w-100"/>
                </row-col-component>
            </row-component>
        </div>
    </div>

    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>
        <div class="clearfix mb-4" v-if="buttonAddRoute">
            <button-route class="float-end" :items="buttonRouteAdd"/>
        </div>

        <div class="tabs effect-3" v-if="showView">
            <input checked type="radio" id="tab-1" name="tab-effect-3" :class="{ 'mobileTab-client': tabMobile }">
            <span :class="{ 'mobileTab-client': tabMobile }">
                <i class="ti ti-info-square-rounded"></i>
                <p>Informações</p>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3" v-if="picked === 2">
            <span v-if="picked === 2">
                <i class="ti ti-user-circle"></i>
                <p>Sócio</p>
            </span>

            <input type="radio" id="tab-3" name="tab-effect-3" :class="{ 'mobileTab-client': tabMobile }">
            <span :class="{ 'mobileTab-client': tabMobile }">
                <i class="ti ti-mail"></i>
                <p>Email</p>
            </span>

            <input type="radio" id="tab-4" name="tab-effect-3" :class="{ 'mobileTab-client': tabMobile }">
            <span :class="{ 'mobileTab-client': tabMobile }">
                <i class="ti ti-phone-filled"></i>
                <p>Telefone</p>
            </span>

            <input type="radio" id="tab-5" name="tab-effect-3" v-if="picked === 2">
            <span v-if="picked === 2">
                <i class="ti ti-list-check"></i>
                <p>Atividades econômicas secundárias</p>
            </span>

            <input type="radio" id="tab-6" name="tab-effect-3" :class="{ 'mobileTab-client': tabMobile }">
            <span :class="{ 'mobileTab-client': tabMobile }">
                <i class="ti ti-history"></i>
                <p>Histórico</p>
            </span>
            <div class="line ease" :class="{ 'line-3': tabMobile }"></div>
            <div class="tab-content">

                <section id="tab-item-1">
                    <form>
                        <div class="aux my-4">
                            <div class="avatar-form">
                                <img v-if="formData.photo" :src="formData.photo" alt="" class="avatar__image">
                                <img v-else src="@/assets/images/avatar.png" alt="" class="avatar__image">
                            </div>
                            <p>{{ formData.name }}</p>
                            <router-link :to="{ name: 'client-edit-photo', id: this.id }" class="figure">
                                <i class="ti ti-camera"></i>
                            </router-link>
                        </div>

                        <div v-if="picked === 2">
                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                        :class="{'formError' : errors.cnpj}">
                                    <label>CNPJ:</label>
                                    <div class="input-group">
                                        <input v-model="formData.cnpj" type="text" class="form-control"
                                               style="border-right: 0">
                                        <button type="button" @click="searchAPiCNPJ"
                                                class="buttonSubmit input-group-text">
                                            <i class="ti ti-search"></i>
                                        </button>
                                    </div>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                        :class="{'formError' : errors.size}">
                                    <label>Porte:</label>
                                    <input-component :type-input="'text'" v-model="formData.size"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                                        :class="{'formError' : errors.status}">
                                    <label>Status:</label>
                                    <Multiselect v-model="formData.status" :options="selectActiveInative"
                                                 :searchable="true"
                                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                        :class="{'formError' : errors.name}">
                                    <label>Nome:</label>
                                    <input-component :type-input="'text'" v-model="formData.name"/>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                        :class="{'formError' : errors.name_f}">
                                    <label>Nome Fantasia:</label>
                                    <input-component :type-input="'text'" v-model="formData.name_f"/>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                        :class="{'formError' : errors.surname}">
                                    <label>Apelido:</label>
                                    <input-component :type-input="'text'" v-model="formData.surname"/>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                        :class="{'formError' : errors.legal_nature}">
                                    <label>Natureza Jurídica:</label>
                                    <input-component :type-input="'text'" v-model="formData.legal_nature"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                        :class="{'formError' : errors.company_status}">
                                    <label>Status da Empresa:</label>
                                    <input-component :type-input="'text'" v-model="formData.company_status"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                        :class="{'formError' : errors.share_capital}">
                                    <label>Capital Social:</label>
                                    <input-component :type-input="'text'" v-model="formData.share_capital"/>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'"
                                        :class="{'formError' : errors.main_activity}">
                                    <label>Atividade Econômica Principal:</label>
                                    <input-component :type-input="'text'" v-model="formData.main_activity"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                                        :class="{'formError' : errors.date_open}">
                                    <label>Data de Abertura:</label>
                                    <input-component v-mask="'##/##/####'" :type-input="'text'"
                                                     v-model="formData.date_open"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                                        :class="{'formError' : errors.headquarters}">
                                    <label>Matriz:</label>
                                    <input-component :type-input="'text'" v-model="formData.headquarters"/>
                                </row-col-component>
                            </row-component>
                        </div>

                        <div v-else>
                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                        :class="{'formError' : errors.name}">
                                    <label>Nome:</label>
                                    <input-component :type-input="'text'" v-model="formData.name"/>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                        :class="{'formError' : errors.surname}">
                                    <label>Apelido:</label>
                                    <input-component :type-input="'text'" v-model="formData.surname"/>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                        :class="{'formError' : errors.cpf}">
                                    <label>CPF:</label>
                                    <input-component disabled="" :type-input="'text'" v-model="formData.cpf"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                        :class="{'formError' : errors.rg}">
                                    <label>RG:</label>
                                    <input-component v-mask="['##.###.###-X', '#.###.###']" :type-input="'text'"
                                                     v-model="formData.rg"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                        :class="{'formError' : errors.birth_date}">
                                    <label>Data Nasc:</label>
                                    <input-component v-mask="'##/##/####'" :type-input="'text'"
                                                     v-model="formData.birth_date"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                        :class="{'formError' : errors.status}">
                                    <label>Status:</label>
                                    <Multiselect v-model="formData.status" :options="selectActiveInative"
                                                 :searchable="true"
                                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                                </row-col-component>
                            </row-component>
                        </div>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                    :class="{'formError' : errors.cep}">
                                <label>CEP:</label>
                                <input-component v-mask="'#####-###'" @change="searchApiCEP" :type-input="'text'"
                                                 v-model="formData.cep"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                    :class="{'formError' : errors.city}">
                                <label>Cidade:</label>
                                <input-component :type-input="'text'" v-model="formData.city"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                    :class="{'formError' : errors.state}">
                                <label>Estado:</label>
                                <input-component :type-input="'text'" v-model="formData.state"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                    :class="{'formError' : errors.district}">
                                <label>Bairro:</label>
                                <input-component :type-input="'text'" v-model="formData.district"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12'"
                                    :class="{'formError' : errors.address}">
                                <label>Endereço:</label>
                                <input-component :type-input="'text'" v-model="formData.address"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                                    :class="{'formError' : errors.country}">
                                <label>País:</label>
                                <input-component :type-input="'text'" v-model="formData.country"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                    :class="{'formError' : errors.complement}">
                                <label>Referência:</label>
                                <input-text-area :rows="4" v-model="formData.complement"/>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>

                <section id="tab-item-2">
                    <div class="clearfix">
                        <button-submit @click="addMember" class="fs-5 float-end" :items="buttonAdd"/>
                    </div>
                    <form v-for="(data, index) in membersAdd" :key="index" class="card p-3 bg-light my-3">
                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                                <label>Nome:</label>
                                <input-component :type-input="'text'" v-model="data.name"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                <label>CPF / CNPJ:</label>
                                <input-component :type-input="'text'" v-model="data.cpf_cnpj"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                <label>Idade:</label>
                                <input-component :type-input="'text'" v-model="data.age"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                <label>Qualificação:</label>
                                <input-component :type-input="'text'" v-model="data.qualification"/>
                            </row-col-component>
                        </row-component>
                        <div class="clearfix" v-if="formData.members.length >= 2">
                            <button-delete @click="membersRemove(index)" class="fs-5 float-end"/>
                        </div>
                    </form>
                </section>

                <section id="tab-item-3">
                    <div class="clearfix">
                        <button-submit @click="addEmail" class="fs-5 float-end" :items="buttonAdd"/>
                    </div>
                    <form v-for="(data, index) in emailsAdd" :key="index" class="card p-3 bg-light my-3">
                        <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                            <label>Receber Notificação:</label>
                            <input type="radio" name="not-email" :id="'nao-email' + index" v-model="data.notification"
                                   value="1">
                            <label :for="'nao-email' + index">Não</label>

                            <input type="radio" name="not-email" :id="'sim-email' + index" v-model="data.notification"
                                   value="2">
                            <label :for="'sim-email' + index">Sim</label>
                        </row-col-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                                <label>Nome:</label>
                                <input-component :type-input="'text'" v-model="data.name"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                <label>Email:</label>
                                <input-component :type-input="'email'" v-model="data.email"/>
                            </row-col-component>
                        </row-component>

                        <div class="clearfix" v-if="formData.emails.length >= 2">
                            <button-delete @click="emailsRemove(index)" class="fs-5 float-end"/>
                        </div>
                    </form>
                </section>

                <section id="tab-item-4">
                    <div class="clearfix">
                        <button-submit @click="addPhones" class="fs-5 float-end" :items="buttonAdd"/>
                    </div>
                    <form v-for="(data, index) in phonesAdd" :key="index" class="card p-3 bg-light my-3">
                        <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                            <label>Receber Notificação:</label>
                            <input type="radio" name="not-phone" :id="'nao-phone' + index" v-model="data.notification"
                                   value="1">
                            <label :for="'nao-phone' + index">Não</label>

                            <input type="radio" name="not-phone" :id="'sim-phone' + index" v-model="data.notification"
                                   value="2">
                            <label :for="'sim-phone' + index">Sim</label>
                        </row-col-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                                <label>Nome:</label>
                                <input-component :type-input="'text'" v-model="data.name"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                <label>Telefone:</label>
                                <input-component v-mask="['(##) ####-####', '(##) #####-####']" :type-input="'text'"
                                                 v-model="data.phone"/>
                            </row-col-component>
                        </row-component>

                        <div class="clearfix" v-if="formData.phones.length >= 2">
                            <button-delete @click="phonesRemove(index)" class="fs-5 float-end"/>
                        </div>
                    </form>
                </section>

                <section id="tab-item-5">
                    <div class="clearfix">
                        <button-submit @click="addActivities" class="fs-5 float-end" :items="buttonAdd"/>
                    </div>
                    <form v-for="(data, index) in activityAdd" :key="index" class="card p-3 bg-light my-3">
                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                                <label>Código:</label>
                                <input-component :type-input="'text'" v-model="data.code"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                <label>Descrição:</label>
                                <input-component :type-input="'text'" v-model="data.desc"/>
                            </row-col-component>
                        </row-component>

                        <div class="clearfix" v-if="formData.secondary_activitys.length >= 2">
                            <button-delete @click="activitiesRemove(index)" class="fs-5 float-end"/>
                        </div>
                    </form>
                </section>

                <section id="tab-item-6">
                    <div class="clearfix mb-3">
                        <button-submit @click="historic_modal = true" class="float-end" :items="{icon: 'ti ti-plus'}"/>
                    </div>

                    <div class="alert alert-secondary alert-dismissible fade show"
                         v-if="formData.historics.length === 0">
                        Não há nenhum histórico
                    </div>

                    <div v-else style="height: 450px" class="overflow-y-scroll bg-body-secondary p-3 my-3">
                        <div class="card p-3 bg-light my-3 overflow-auto"
                             v-for="(data, index) in formData.historics"
                             :key="index">
                            <div>
                                <img v-if="data.user_photo" width="25" class="mx-1 rounded-circle"
                                     :src="data.user_photo" alt="">
                                <img v-else width="25" class="mx-1 rounded-circle" src="@/assets/images/avatar.png"
                                     alt="">
                                {{ data.name_user }}

                                <p class="d-inline-block mx-3">
                                    <i class="fa-solid fa-calendar"></i>
                                    {{ data.date_create }}
                                </p>
                                <p class="d-inline-block mx-3">
                                    <i class="fa-solid fa-clock"></i>
                                    {{ data.hour_create }}
                                </p>

                                <p class="d-inline-block mx-3">
                                    Plataforma: {{ data.platform }},
                                    Navegador: {{ data.browser }},
                                    Endereço IP: {{ data.ip }}
                                </p>
                            </div>

                            <p>{{ data.obs }}</p>
                        </div>
                    </div>
                </section>
            </div>
        </div>

        <row-component>
            <row-col-component
                    :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <button-submit :disaabled="sendForm" @submit="submit" :items="buttonSubmit" style="width: 100%;"/>
            </row-col-component>
            <row-col-component
                    :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <button-route :items="buttonRoute" style="width: 100%;"/>
            </row-col-component>
        </row-component>

    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from '@/components/row/RowColComponent';
import InputComponent from '@/components/form/InputComponent';
import Multiselect from '@vueform/multiselect';
import ButtonSubmit from '@/components/buttons/ButtonSubmit';
import ButtonRoute from '@/components/buttons/ButtonRoute';
import InputTextArea from '@/components/form/InputTextArea';
import {ESTADOS} from "@/configs/constants";
import {useToast} from "vue-toastification";
import {mask} from "vue-the-mask";
import ButtonDelete from "@/components/buttons/ButtonDelete";
import ErrorComponent from "@/components/alerts/ErrorComponent";

const toast = useToast()
export default {
    name: 'ClientUpdate',

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Cliente')
        this.checkACL()
        this.loadClient()
    },

    components: {
        ErrorComponent,
        ButtonDelete,
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        Multiselect,
        ButtonSubmit,
        ButtonRoute,
        InputTextArea,
    },

    directives: {
        mask
    },

    data() {
        return {
            itensOne: {
                title: "Editar",
                icon: "ti-edit"
            },

            buttonSubmit: {
                icon: 'ti-device-floppy',
                nameButton: 'Salvar',
                typeButton: 'button'
            },

            buttonRoute: {
                icon: 'ti-arrow-back-up',
                route: '/client/search',
                name: 'Voltar'
            },

            buttonAdd: {
                icon: 'ti-circle-plus',
            },

            buttonAddHistoric: {
                icon: 'ti-circle-plus',
                nameButton: 'Adicionar',
            },

            buttonRouteAdd: {
                icon: 'ti-plus',
                route: '/client/create',
            },

            buttonAddRoute: false,

            formData: {
                cpf: '',
                cnpj: '',
                size: '',
                name: '',
                name_f: '',
                surname: '',
                legal_nature: '',
                share_capital: '',
                main_activity: '',
                date_open: '',
                birth_date: '',
                headquarters: '',
                rg: '',
                type: 2,
                status: 1,
                emails: [],
                phones: [],
                members: [],
                historics: [],
                secondary_activitys: [],
                cep: '',
                city: '',
                state: '',
                district: '',
                address: '',
                complement: '',
                country: '',
                company_status: ''
            },

            historic: {
                id: this.id,
                obs: ""
            },

            historic_modal: false,

            picked: 2,

            errors: [],
            showView: false,
            sendForm: false,
            showError: false,

        }
    },

    methods: {

        loadClient() {
            this.$store.dispatch('loadClient', this.id)
                .then((r) => {
                    this.formData = r
                    this.checkType()
                    this.nullInputArray()
                }).catch(() => {
                toast.error('Erro desconhecido', {timeout: 2000})
            })
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateClient', this.formData)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success("Cadastro atualizado com sucesso", {timeout: 2000});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollToTop()
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        submitHist() {
            this.$store.dispatch('storeClientHistoric', this.historic)
                .then(() => {
                    this.showError = false
                    this.sendForm = false
                    this.errors = []
                    this.loadClient()
                    this.historic.obs = ""
                    this.historic_modal = false
                    toast.success("Histórico adicionado com sucesso", {timeout: 2000});
                }).catch((r) => {
                this.showError = true;
                this.sendForm = false
                this.errors = r.response.data.errors;
                this.scrollToTop()
                toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
            })
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 3)
                .then((r) => {
                    if (r.data.indexOf('client-edit') !== -1) {
                        this.showView = true

                        if (r.data.indexOf('client-create') !== -1) {
                            this.buttonAddRoute = true
                        }

                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        scrollToTop() {
            window.scrollTo(0, 0);
        },

        searchAPiCNPJ() {
            let cnpj = this.formData.cnpj.replace(/\D/g, '');
            const dateCNPJ = {'cnpj': cnpj}
            this.$store.dispatch('searchApiCNPJ', dateCNPJ)
                .then((r) => {
                    this.completeInput(r.data)
                }).catch(() => {
            })
        },

        completeInput(data) {
            this.formData.name = data.company.name
            this.formData.name_f = data.alias
            this.formData.surname = this.formData.name_f
            this.formData.legal_nature = `${data.company.nature.id} - ${data.company.nature.text}`
            this.formData.share_capital = data.company.equity.toLocaleString('pt-br', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
            this.formData.main_activity = `${data.mainActivity.id} - ${data.mainActivity.text}`
            this.formData.date_open = this.dateFormatBR(data.founded)
            this.formData.headquarters = data.head ? 'Sim' : 'Não'
            this.formData.company_status = `${data.status.id} - ${data.status.text}`
            this.formData.cep = data.address.zip
            this.formData.city = data.address.city
            this.formData.stateView = ESTADOS[data.address.state]
            this.formData.state = data.address.state
            this.formData.district = data.address.district
            this.formData.address = `${data.address.street}, ${data.address.number}`
            this.formData.country = data.address.country.name
            this.formData.complement = data.address.details
            this.formData.size = `${data.company.size.id} -  ${data.company.size.acronym} - ${data.company.size.text}`
            if (data.company.members.length > 0) {
                this.formData.members = []
                data.company.members.forEach(element => {
                    this.formData.members.push({
                        name: element.person.name,
                        cpf_cnpj: element.person.taxId,
                        age: element.person.age,
                        qualification: `${element.role.id} - ${element.role.text}`
                    })
                });
            }
            if (data.sideActivities.length > 0) {
                this.formData.secondary_activitys = []
                data.sideActivities.forEach(element => {
                    this.formData.secondary_activitys.push({
                        code: `${element.id}`,
                        desc: `${element.text}`
                    })
                });
            }
        },

        checkType() {
            if (parseInt(this.formData.type) === 1) {
                this.picked = 1
            }
            if (parseInt(this.formData.type) === 2)
                this.picked = 2
        },

        nullInputArray() {
            if (this.formData.members.length === 0)
                this.addMember()
            if (this.formData.emails.length === 0)
                this.addEmail()
            if (this.formData.phones.length === 0)
                this.addPhones()
            if (this.formData.secondary_activitys.length === 0)
                this.addActivities()
        },

        addMember() {
            this.formData.members.push({
                name: '',
                cpf_cnpj: '',
                age: '',
                qualification: ''
            })
        },

        addEmail() {
            this.formData.emails.push({
                name: '',
                email: '',
                notification: '1'
            })
        },

        addPhones() {
            this.formData.phones.push({
                name: '',
                phone: '',
                notification: '1'
            })
        },

        addActivities() {
            this.formData.secondary_activitys.push({
                code: '',
                desc: '',
            })
        },

        membersRemove(index) {
            this.formData.members.splice(index, 1)
        },

        emailsRemove(index) {
            this.formData.emails.splice(index, 1)
        },

        phonesRemove(index) {
            this.formData.phones.splice(index, 1)
        },

        activitiesRemove(index) {
            this.formData.secondary_activitys.splice(index, 1)
        },

        dateFormatBR(inputDate) {
            let dateArray = inputDate.split("-");
            return `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;
        },

        searchApiCEP() {
            this.resetFormAddress()
            let cep = this.formData.cep.replace(/\D/g, '');
            const dateCEP = {'cep': cep}
            this.$store.dispatch('searchApiCEP', dateCEP)
                .then((r) => {
                    this.formData.city = r.data.localidade,
                        this.formData.state = r.data.uf,
                        this.formData.stateView = ESTADOS[r.data.uf],
                        this.formData.district = r.data.bairro,
                        this.formData.address = r.data.logradouro,
                        this.formData.complement = r.data.complemento
                }).catch(() => {
            })
        },

        resetFormAddress() {
            this.formData.city = ''
            this.formData.state = ''
            this.formData.stateView = ''
            this.formData.district = ''
            this.formData.address = ''
            this.formData.complement = ''
            this.formData.country = 'Brasil'
        },

        resetFormData() {
            this.formData = {
                cpf: '',
                cnpj: '',
                size: '',
                name: '',
                name_f: '',
                surname: '',
                legal_nature: '',
                share_capital: '',
                main_activity: '',
                date_open: '',
                birth_date: '',
                headquarters: '',
                rg: '',
                type: this.formData.type,
                status: 1,
                emails: [],
                phones: [],
                members: [],
                secondary_activitys: [],
                cep: '',
                city: '',
                state: '',
                district: '',
                address: '',
                complement: '',
                country: '',
                company_status: ''
            }
        },

    },

    computed: {
        me() {
            return this.$store.state.auth.me
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        tabMobile() {
            return this.picked !== 2;
        },

        emailsAdd() {
            return this.formData.emails;
        },

        membersAdd() {
            return this.formData.members;
        },

        phonesAdd() {
            return this.formData.phones;
        },

        activityAdd() {
            return this.formData.secondary_activitys;
        },
    }
}
</script>

<style scoped>
.buttonSubmit {
    color: white;
    border-color: #36c1bd;
    background-color: #36c1bd;
}

.tabs > input,
.tabs > span,
.effect-3 .line {
    width: calc(100% / 6);
}

#tab-2,
#tab-2 + span,
#tab-2:checked ~ .line {
    left: 16.6%;
}

#tab-3,
#tab-3 + span,
#tab-3:checked ~ .line {
    left: 33.2%;
}

#tab-4,
#tab-4 + span,
#tab-4:checked ~ .line {
    left: 49.8%;
}

#tab-5,
#tab-5 + span,
#tab-5:checked ~ .line {
    left: 66.4%;
}

#tab-6,
#tab-6 + span,
#tab-6:checked ~ .line {
    left: 83%;
}
</style>