import state from "@/store/modules/name-budget/state";
import actions from "@/store/modules/name-budget/actions";
import mutations from "@/store/modules/name-budget/mutations"
import getters from "@/store/modules/name-budget/getters";

export default {
    state,
    actions,
    mutations,
    getters
}