<template>
    <painel-component :itens="itensOne">
        <form v-if="showView">
            <row-component>
                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Status:</label>
                    <Multiselect v-model="formData.status" :options="selectStatusBudget" :searchable="true"
                                 :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Data Inicial:</label>
                    <input-component v-mask="'##/##/####'" v-model="formData.date_start" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Data Final:</label>
                    <input-component v-mask="'##/##/####'" v-model="formData.date_end" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Excel / PDF:</label>
                    <Multiselect v-model="formData.excel_pdf" :options="selectTypeReport" :searchable="true"
                                 :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <button-submit @click="submit" :items="buttonSubmit"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import Multiselect from "@vueform/multiselect";
import InputComponent from "@/components/form/InputComponent";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";

import {mask} from "vue-the-mask";

export default {
    name: "ReportBudget",
    components: {
        ButtonSubmit,
        InputComponent,
        Multiselect,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    directives: {
        mask
    },

    created() {
        this.$store.dispatch("updateNamePage", "Relatório Orçamento")
        this.checkACL()
    },

    data() {
        return {
            itensOne: {
                title: "Gerar",
                icon: "ti-file-download"
            },

            buttonSubmit: {
                icon: 'ti-download',
                typeButton: "button"
            },

            formData: {
                status: 1,
                date_start: this.initialDate(),
                date_end: this.dateCurrent(),
                excel_pdf: 2
            },

            showView: false
        }
    },

    methods: {
        submit() {
            const URL_BASE = process.env.NODE_ENV === 'production' ? 'https://system.benepiscinas.com' : 'http://bene.d7mmarketing.com.br';
            const RESOURCE = "/private/report/budget/x0YAAhA9kbU69fGCGcJH"
            const params = new URLSearchParams({
                status: this.formData.status,
                date_start: this.formData.date_start,
                date_end: this.formData.date_end,
                excel_pdf: this.formData.excel_pdf
            });

            const keysToRemove = ['status', 'date_start', 'date_end', 'excel_pdf'];
            keysToRemove.forEach(key => {
                if (this.formData[key] === null || this.formData[key] === "") {
                    params.delete(key);
                }
            });

            const FULL_URL = `${URL_BASE}${RESOURCE}?${params.toString()}`
            window.open(FULL_URL)
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 22)
                .then((r) => {
                    if (r.data.indexOf('report-client') !== -1) {
                        this.showView = true

                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        initialDate() {
            let currentTime = new Date();

            let day = new Date(currentTime.getFullYear(), currentTime.getMonth(), 1);
            let month = currentTime.getMonth() + 1;
            let year = currentTime.getFullYear();

            day = ("0" + day.getDate()).slice(-2);
            month = (month < 10 ? '0' : '') + month;

            return `${day}/${month}/${year}`
        },

        dateCurrent() {
            let currentTime = new Date();

            let day = currentTime.getDate();
            let month = currentTime.getMonth() + 1;
            let year = currentTime.getFullYear();

            day = (day < 10 ? '0' : '') + day;
            month = (month < 10 ? '0' : '') + month;

            return `${day}/${month}/${year}`
        },
    },

    computed: {
        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        selectStatusBudget() {
            return this.$store.state.var.selectStatusBudget
        },

        selectTypeReport() {
            return this.$store.state.var.selectTypeReport
        },
    }
}
</script>