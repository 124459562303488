<template>
    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>
        <button-submit @click="printPDF" class="float-end me-3 border-1" :items="{icon: 'ti ti-printer'}"/>

        <form v-if="showView">
            <row-component>
                <row-col-component
                        :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <input disabled v-model="formData.client_employee_provider_type" type="radio" name="docs"
                           id="cliente"
                           :value='parseInt("1")'>
                    <label for="cliente">Cliente</label>

                    <input disabled v-model="formData.client_employee_provider_type" type="radio" name="docs"
                           id="fornecedor"
                           :value='parseInt("3")'>
                    <label for="fornecedor">Fornecedor</label>

                    <input disabled v-model="formData.client_employee_provider_type" type="radio" name="docs"
                           id="funcionario"
                           :value='parseInt("2")'>
                    <label for="funcionario">Funcionário</label>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                    <label>Tipo:</label>
                    <Multiselect disabled v-model="formData.type" @select="titleSuggestion(formData.type)"
                                 :options="selectTypeReceiptList" :searchable="true"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component v-if="formData.client_employee_provider_type === 3"
                                   :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                    <label>Funcionário:</label>
                    <Multiselect disabled label="name" v-model="formData.client_employee_provider_id"
                                 :options="selectEmployeeList" :searchable="true"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component v-else-if="formData.client_employee_provider_type === 2"
                                   :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                    <label>Fornecedor:</label>
                    <Multiselect disabled label="name" v-model="formData.client_employee_provider_id"
                                 :options="selectProviderList" :searchable="true"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component v-else :class-row-col="'col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12'">
                    <label>Cliente:</label>
                    <Multiselect disabled label="name" v-model="formData.client_employee_provider_id"
                                 :options="selectClientList"
                                 :searchable="true"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <label>Título:</label>
                    <input-component disabled :type-input="'text'" v-model="formData.title"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <label>Data:</label>
                    <input-component disabled v-mask="'##/##/####'" :type-input="'text'" v-model="formData.date"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                    <label>Valor:</label>
                    <input-component disabled v-money="money" :type-input="'text'" v-model="formData.price"/>
                </row-col-component>
            </row-component>

            <row-component v-if="formData.client_employee_provider_type === 3">
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <label>Pago:</label>
                    <input-component disabled :type-input="'text'" v-model="formData.paid"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <label>Referente:</label>
                    <input-text-area disabled formData.paid :rows="3" v-model="formData.referent"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <button-route :items="buttonRoute" style="width: 100%;"/>
                </row-col-component>
            </row-component>
        </form>

    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from '@/components/row/RowColComponent';
import InputComponent from '@/components/form/InputComponent';
import Multiselect from '@vueform/multiselect';
import ButtonRoute from '@/components/buttons/ButtonRoute';
import buttonSubmit from "@/components/buttons/ButtonSubmit";
import InputTextArea from '@/components/form/InputTextArea';
import ErrorComponent from "@/components/alerts/ErrorComponent";
import {useToast} from "vue-toastification";
import {MONEY} from "@/configs/constants";
import {VMoney} from "v-money";
import {mask} from "vue-the-mask";

const toast = useToast()
export default {
    name: 'ReceiptShow',

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Recibo')
        this.$store.dispatch('loadClientMultiSelect')
        this.$store.dispatch('loadProviderMultiSelect')
        this.$store.dispatch('loadEmployeeMultiSelect')
        this.checkACL()
        this.loadReceipt()
    },

    components: {
        ErrorComponent,
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        Multiselect,
        ButtonRoute,
        buttonSubmit,
        InputTextArea
    },

    directives: {
        mask,
        money: VMoney
    },

    data() {
        return {
            itensOne: {
                title: "Editar",
                icon: "ti-edit"
            },

            buttonSubmit: {
                icon: 'ti-device-floppy',
                nameButton: 'Salvar',
                typeButton: 'button'
            },

            buttonRoute: {
                icon: 'ti-arrow-back-up',
                route: '/receipt/search',
                name: 'Voltar'
            },

            buttonRouteAdd: {
                icon: 'ti-plus',
                route: '/receipt/create',
            },

            buttonAdd: false,

            formData: {
                type: '',
                title: '',
                client_employee_provider_type: '',
                client_employee_provider_id: '',
                date: '',
                price: '',
                paid: '',
                referent: '',
                link_receipt_pdf: ''
            },

            money: MONEY,

            errors: [],
            showView: false,
            sendForm: false,
            showError: false,
        }
    },

    methods: {

        loadReceipt() {
            this.$store.dispatch('loadReceipt', this.id)
                .then((r) => {
                    this.formData = r
                }).catch(() => {
                toast.error('Erro desconhecido', {timeout: 2000})
            })
        },


        async checkACL() {
            this.$store.dispatch('ACLitens', 18)
                .then((r) => {
                    if (r.data.indexOf('receipt-search') !== -1) {
                        this.showView = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        printPDF() {
            window.open(this.formData.link_receipt_pdf, '_blank')
        },

    },

    computed: {
        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        selectTypeReceiptList() {
            return this.$store.state.var.selectTypeReceiptList
        },

        selectClientList() {
            return this.$store.state.var.selectClientList
        },

        selectProviderList() {
            return this.$store.state.var.selectProviderList
        },

        selectEmployeeList() {
            return this.$store.state.var.selectEmployeeList
        },

    }

}
</script>