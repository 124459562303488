<template>
    <loader-component/>
    <dashboard-view/>
</template>

<script>
import DashboardView from "@/views/DashboardView";
import LoaderComponent from "@/components/LoaderComponent.vue";

export default {
    name: "App",

    components: {
        LoaderComponent,
        DashboardView,
    },

    computed: {
        currentRouteName() {
            return this.$route.name == 'login';
        }
    },

}
</script>