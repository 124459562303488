<template>
  <painel-component :itens="itensOne">
    <error-component :errors="errors" v-if="showError"/>

    <form v-if="showView">
      <row-component>
        <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                           :class="{'formError' : errors.name}">
          <label>Nome:</label>
          <input-component :type-input="'text'" v-model="formData.name"/>
        </row-col-component>

        <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                           :class="{'formError' : errors.status}">
          <label>Status:</label>
          <Multiselect v-model="formData.status" :options="selectActiveInative" :searchable="true"
                       :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
        </row-col-component>
      </row-component>

      <row-component>
        <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                           :class="{'formError' : errors.obs}">
          <label>Observações:</label>
          <input-text-area :rows="4" v-model="formData.description"/>
        </row-col-component>
      </row-component>

      <row-component>
        <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
          <button-submit :disaabled="sendForm" @submit="submit" :items="buttonSubmit" style="width: 100%;"/>
        </row-col-component>

        <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
          <button-route :items="buttonRoute" style="width: 100%;"/>
        </row-col-component>
      </row-component>
    </form>

  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from '@/components/row/RowColComponent';
import InputComponent from '@/components/form/InputComponent';
import Multiselect from '@vueform/multiselect';
import ButtonSubmit from '@/components/buttons/ButtonSubmit';
import ButtonRoute from '@/components/buttons/ButtonRoute';
import InputTextArea from '@/components/form/InputTextArea';
import ErrorComponent from "@/components/alerts/ErrorComponent";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
  name: 'NameBudgetCreate',

  created() {
    this.$store.dispatch('updateNamePage', 'Nome orçamento')
    this.checkACL()
  },

  components: {
    ErrorComponent,
    PainelComponent,
    RowComponent,
    RowColComponent,
    InputComponent,
    Multiselect,
    ButtonSubmit,
    ButtonRoute,
    InputTextArea
  },

  data() {
    return {
      itensOne: {
        title: "Adicionar",
        icon: "ti-pencil-plus"
      },

      buttonSubmit: {
        icon: 'ti-circle-plus',
        nameButton: 'Adicionar',
        typeButton: 'button'
      },

      buttonRoute: {
        icon: 'ti-arrow-back-up',
        route: '/name-budget/search',
        name: 'Voltar'
      },

      formData: {
        name: '',
        status: '',
        description: ''
      },

      errors: [],
      showView: false,
      sendForm: false,
      showError: false,
    }
  },

  methods: {

    submit() {
      if (!this.sendForm) {
        this.sendForm = true;
        this.$store.dispatch('storeNameBudget', this.formData)
            .then((r) => {
              this.showError = false
              this.sendForm = false
              this.errors = []
              toast.success("Cadastro efetuado com sucesso", {timeout: 2000});
              this.$router.push({name: 'name-budget-edit', params: {id: r.data}})
            }).catch((r) => {
          this.showError = true;
          this.sendForm = false
          this.errors = r.response.data.errors;
          this.scrollToTop()
          toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
        })
      }
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },

    async checkACL() {
      this.$store.dispatch('ACLitens', 14)
          .then((r) => {
            if (r.data.indexOf('account-type-create') !== -1) {
              this.showView = true
            } else {
              this.$router.push({name: 'home'})
            }
          }).catch(() => {
        this.$router.push({name: 'home'})
      })
    },

  },

  computed: {
    selectActiveInative() {
      return this.$store.state.var.selectActiveInative
    },

    noResultsText() {
      return this.$store.state.var.noResultsText
    },

    noOptionsText() {
      return this.$store.state.var.noOptionsText
    }
  }

}
</script>